<template>
  <div class="scrollContainer">
    <Header />
    <div class="filterBlock">
      <div class="filter">
        <div class="filterType">{{ $t("位置区域") }}</div>
        <div class="filterItemList">
          <div
            class="labelItem"
            :class="{ selected: location == '' }"
            @click="clickLocation('')"
          >
            不限
          </div>
          <div
            class="labelItem"
            v-for="(labelItem, idx) in locationList"
            :key="idx"
            :class="{ selected: location == labelItem.value }"
            @click="clickLocation(labelItem.value)"
          >
            {{ labelItem.label }}
          </div>
        </div>
      </div>
      <div class="filter">
        <div class="filterType">{{ $t("高级筛选") }}</div>
        <div class="filterItemList">
          <el-menu
            :default-active="helperType.value"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect1"
            :menu-trigger="isMobile ? 'click' : 'hover'"
          >
            <el-submenu index="1">
              <template slot="title">{{ helperType.label }}</template>
              <el-menu-item
                v-for="item in helperTypeList"
                :key="item.value"
                :index="item.value"
                >{{ $t(item.label) }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
          <!-- <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2"
            menu-trigger="click"
          >
            <el-submenu index="2">
              <template slot="title">{{ period.label }}</template>
              <el-menu-item
                v-for="item in periodList"
                :key="item.value"
                :index="item.value"
                >{{ item.label }}</el-menu-item
              >
            </el-submenu>
          </el-menu> -->
        </div>
      </div>
    </div>
    <template v-if="helperList && helperList.length">
      <div class="itemList lifeHelper">
        <HelperItem v-for="item in helperList" :key="item.id" :item="item" />
      </div>
      <div class="pageLine">
        <el-pagination
          :page-size="10"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="pageNum"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </template>
    <template v-else>
      <div class="itemList empty lifeHelper">
        {{ loading ? $t("加载中...") : "没有查询到符合条件的生活助手" }}
      </div>
    </template>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HelperItem from "@/components/HelperItem.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      activeIndex: "1",
      keywords: "",
      houseType: 0,
      helperType: {
        label: "服务类型",
        value: "1",
      },
      helperTypeList: [
        {
          label: "不限",
          value: "1",
        },
        {
          label: "家政清洁",
          value: "2",
        },
        {
          label: "搬家运输",
          value: "3",
        },
        {
          label: "开锁维修",
          value: "4",
        },
      ],
      helperTypeMap: {
        1: "",
        2: "家政清洁",
        3: "搬家运输",
        4: "开锁维修",
      },
      loading: false,
      pageNum: 1,
      helperList: [],
      total: 0,
      keyword: "",
      locationList: [],
      location: "",
    };
  },
  components: {
    Header,
    Footer,
    HelperItem,
  },
  async created() {
    document.title = "生活助手 - 马上住 HorseOnLiving";
    this.getLocationList();
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.type) {
      this.helperType = this.helperTypeList.find(
        (item) => item.value == this.$route.query.type
      );
    }
    this.getHelperList();
  },
  methods: {
    clickLocation(value) {
      this.location = value;
      this.pageNum = 1;
      this.getHelperList();
    },
    async getLocationList() {
      const locationListRes = await request.get(
        "/webapi/house/getSearchParam",
        {
          params: {
            lang: this.$route.query.lan == 'tra' ? 1 : 0,
          }
        }
      );
      if (locationListRes.code == 200) {
        this.locationList = locationListRes.data.districtList;
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getHelperList();
      console.log(`当前页: ${val}`);
    },
    async getHelperList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const helperListRes = await request.get("/webapi/liveHelper/list", {
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
          type: this.helperTypeMap[this.helperType.value],
          title: this.keyword,
          locations: this.location,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        },
      });
      this.loading = false;
      if (helperListRes.code == 200) {
        this.helperList = helperListRes.data.records;
        this.total = helperListRes.data.total;
      }
    },
    handleSelect1(key, keyPath) {
      if (keyPath[0] == "1") {
        if (keyPath[1] != 1) {
          this.helperType = this.helperTypeList.find(
            (item) => item.value == keyPath[1]
          );
        } else
          this.helperType = {
            label: "服务类型",
            value: "1",
          };
      }
      this.pageNum = 1;
      this.getHelperList();
      console.log("key", key, "keyPath", keyPath);
    },
  },
};
</script>

<style lang="scss">
.lifeHelper.itemList {
  min-height: calc(100vh - 325px);
}
.isMobile {
  .lifeHelper.itemList {
    min-height: calc(100vh - 240px);
  }
}
</style>
