<template>
  <el-dialog
    class="horseOn housesVs"
    title="房源VS"
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
    center
  >
    <div class="vsTips">请勾选房源进行对比（最多3个）</div>
    <div class="likeList" v-if="!empty">
      <LikeRoomItem
        from="housesVs"
        v-for="item in roomList"
        :key="item.id"
        :item="item.roomType"
        :selectDisabled="selectList.length >= 3"
        @selectHandler="selectRoom"
        @cancleSelectHandler="cancelSelect"
      />
    </div>
    <template v-else>
      <div class="empty">暂无收藏</div>
    </template>
    <div class="confirmBtnLine">
      <div
        class="confirmBtn vs"
        :class="{ disabled: this.selectList.length < 2 }"
        @click="openVs()"
      >
        开始VS
      </div>
    </div>
  </el-dialog>
</template>

<script>
import LikeRoomItem from "@/components/LikeRoomItem.vue";
export default {
  components: {
    LikeRoomItem,
  },
  data() {
    return {
      contentVisible: false,
      empty: false,
      selectList: [],
    };
  },
  methods: {
    selectRoom(id) {
      this.selectList.push(id);
      console.log(this.selectList);
    },
    cancelSelect(id) {
      this.selectList = this.selectList.filter((item) => item !== id);
      console.log(this.selectList);
    },
    openVs() {
      if (this.selectList.length < 2) {
        return;
      }
      console.log(this.selectList);
      window.open(
        `/houses/vs?lan=${this.$i18n.locale}&id1=${
          this.selectList[0] ? this.selectList[0] : null
        }&id2=${this.selectList[1] ? this.selectList[1] : null}&id3=${
          this.selectList[2] ? this.selectList[2] : null
        }`
      );
      this.contentVisible = false;
    },
  },
  props: ["roomList"],
};
</script>

<style lang="scss">
.isMobile {
  .horseOn.housesVs {
    .el-dialog {
      width: 93%;
      height: 520px;
    }
    .el-dialog__header {
      padding-top: 26px;
    }
    .el-dialog__body {
      padding: 4px 12px;
    }
    .likeList {
    width: 100%;
    margin: 12px auto;
    height: 350px;
  }
  }
}
.horseOn.housesVs {
  .el-dialog {
    width: 1000px;
    height: 650px;
  }
  .el-dialog__header {
    padding-top: 42px;
  }
  .vsTips {
    text-align: center;
    color: #666;
  }
  .el-dialog__body {
    padding: 4px 60px;
  }
  .likeList {
    width: 830px;
    margin: 24px auto;
    overflow: auto;
    height: 430px;
  }
  .likeRoomItem:nth-child(5n) {
    margin-right: 14px;
  }
  .likeRoomItem:nth-child(4n) {
    margin-right: 0;
  }
  .confirmBtn.vs {
    padding: 10px 0;
    background: #2eaac1;
    border-radius: 4px;
    font-size: 16px;
    width: 152px;
    color: #fff;
  }
  .confirmBtn.vs.disabled {
    background: rgb(205, 205, 205);
    cursor: not-allowed;
  }
}
</style>