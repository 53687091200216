<template>
  <el-dialog
    class="horseOn confirm"
    title=""
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
    center
  >
    <div class="confirmTitle">注册成功，是否需要绑定邮箱</div>
    <div class="confirmBtnLine">
      <div class="confirmBtn no" @click="contentVisible=false">下次再说</div>
      <div class="confirmBtn yes" @click="toBind">去绑定</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      contentVisible: false,
    };
  },
  methods: {
    toBind() {
      window.location.href = `/personal?lan=${this.$i18n.locale}&tab=2`;
    }
  },
};
</script>