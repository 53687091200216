<template>
  <el-dialog
    class="horseOn bind"
    :title="title[bindType]"
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
    center
    :destroy-on-close="true"
    @close="closeDialog()"
  >
    <template v-if="bindType == 'email'">
      <el-form :rules="rules" ref="bindForm" :model="bindForm">
        <el-form-item label="" prop="email">
          <el-input placeholder="输入邮箱" v-model="bindForm.email"></el-input>
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-input placeholder="输入验证码" v-model="bindForm.code"></el-input>
          <el-button
            class="codeBtn"
            ref="codeBtn"
            :loading="sending"
            :disabled="sendDisabled"
            size="small"
            @click="onSendSms"
            >{{ sendButtonText }}</el-button
          >
        </el-form-item>
        <el-form-item label="" prop="password1">
          <el-input
            placeholder="输入密码"
            v-model="bindForm.password1"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password2">
          <el-input
            placeholder="请再次输入密码"
            v-model="bindForm.password2"
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="confirmBtnLine">
        <div class="confirmBtn no" @click="contentVisible = false">取消</div>
        <div class="confirmBtn yes" @click="clickBindEmail()">确认</div>
      </div>
    </template>
    <template v-if="bindType == 'wechat'">
      <div class="qrCode">
        <div class="wxLoginImg">
          <template v-if="qrCodeUrl">
            <img class="imgCut" :src="qrCodeUrl" alt="" />
            <div
              v-if="codeExpired"
              class="expired codeLoading"
              @click="clickWx()"
            >
              已过期，点击重新获取
            </div>
          </template>
          <div v-else class="codeLoading">正在获取二维码...</div>
        </div>
        <div>用微信扫码完成绑定</div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写邮箱"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("密码必须至少有6个字符"));
      } else {
        callback();
      }
    };
    const validatePasswordConfirm = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("密码必须至少有6个字符"));
      } else if (value !== this.bindForm.password1) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      contentVisible: false,
      rules: {
        email: [{ required: true, trigger: "blur", validator: validateEmail }],
        code: [
          {
            required: true,
            trigger: "blur",
            validator: validateCode,
          },
        ],
        password1: [
          {
            required: true,
            trigger: "blur",
            validator: validatePassword,
          },
        ],
        password2: [
          {
            required: true,
            trigger: "blur",
            validator: validatePasswordConfirm,
          },
        ],
      },
      bindForm: {
        email: "",
        password1: "",
        password2: "",
        code: "",
      },
      bindType: "",
      title: {
        email: "绑定邮箱",
        wechat: "绑定微信",
      },
      sending: false,
      loading: false,
      timer: 0,
      codeExpired: false,
      qrCodeTimer: null,
      qrCodeUrl: "",
      pollingTimer: null,
      sceneStr: null,
    };
  },
  computed: {
    sendDisabled() {
      if (this.timer === 0) {
        return false;
      } else {
        return true;
      }
    },
    sendButtonText() {
      if (this.timer === 0) {
        return "获取验证码";
      } else {
        return `${this.timer}s后重新发送`;
      }
    },
  },
  watch: {
    timer(num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num;
        }, 1000);
      }
    },
    bindType: {
      immediate: true,
      handler(val, oldVal) {
        // 从其他状态变成绑定扫码, 请求微信二维码
        if (oldVal != "wechat" && val == "wechat") {
          console.log("微信扫码");
          this.clickWx();
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.bindType = "";
      this.bindForm= {
        email: "",
        password1: "",
        password2: "",
        code: "",
      },
      this.stopPolling();
    },
    // 绑定邮箱
    async clickBindEmail() {
      const bindEmailRes = await request.post("/webapi/user/bindEmail", {
        code: this.bindForm.code,
        email: this.bindForm.email,
        password: this.bindForm.password1,
      });
      if (bindEmailRes.code == 200) {
        this.$message({
          message: "绑定成功",
          type: "success",
        });
        location.reload();
      }
    },
    async clickWx() {
      console.log("微信扫码");
      this.codeExpired = false;
      this.qrCodeTimer && clearTimeout(this.qrCodeTimer);
      this.qrCodeTimer = null;
      const getQrCodeRes = await request.get("/webapi/wx/getQrCode", {});
      if (getQrCodeRes.code == 200) {
        this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${getQrCodeRes.data.ticket}`;
        this.qrCodeTimer = setTimeout(() => {
          this.codeExpired = true;
          this.qrCodeTimer = null;
          this.stopPolling();
        }, 1000 * 60 * 30);
        this.sceneStr = getQrCodeRes.data.sceneStr;
        this.polling();
      }
    },
    // 开始轮询
    async polling() {
      // 清除之前的轮询，以防万一
      this.stopPolling();

      // 查询扫码状态
      const bindWxRes = await request.get("/webapi/wx/bindWx", {
        params: {
          wxKey: this.sceneStr,
        },
      });
      // 表示登录成功，不需要继续轮询
      if (bindWxRes.code == 50001) {
        this.$message({
          message: bindWxRes.msg,
          type: "error",
        });
        this.bindType = "";
        this.contentVisible = false;
        return;
      }
      if (bindWxRes.data) {
        // 在这里执行绑定成功的逻辑
        console.log("绑定成功");
        this.$message({
          message: "绑定成功",
          type: "success",
        });
        location.reload();
        // 这里return出去，不发起下一次轮询
        
        return;
      } else
        this.pollingTimer = setTimeout(() => {
          this.polling();
        }, 1000);
    },
    stopPolling() {
      this.pollingTimer && clearTimeout(this.pollingTimer);
      this.pollingTimer = null;
    },
    onSendSms() {
      this.$refs["bindForm"].validateField("email", (valid) => {
        if (valid && valid !== "") {
          return false;
        } else {
          this.sending = true;
          this.$store
            .dispatch("user/GetVerifyCode", this.bindForm.email)
            .then(() => {
              this.$message.success("验证码发送成功，请注意查收");
              this.timer = 60;
            })
            .catch(() => {
              this.sending = false;
            })
            .finally(() => (this.sending = false));
        }
      });
    },
  },
};
</script>

<style lang="scss">
.bind.horseOn {
  .qrCode {
    text-align: center;
    margin-top: 24px;
  }
  .el-dialog {
    height: 480px;
  }
  .el-dialog__header {
    padding-top: 30px;
  }
  .el-dialog__title {
    font-size: 20px;
  }
  .el-form {
    margin-bottom: 30px;
  }
  .el-form-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 32px;
    .codeBtn {
      position: absolute;
      right: 10px;
      top: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .el-form-item__label {
    line-height: 34px;
    color: #000;
  }
  .el-form-item__content {
    line-height: 34px;
  }
  .el-input__inner {
    border: none;
    padding: 0 2px;
    height: 34px;
    line-height: 34px;
  }
  input::placeholder {
    color: #979797;
  }
}
.el-dialog {
  border-radius: 4px;
}
</style>