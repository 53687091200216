<template>
  <div class="scrollContainer">
    <Header />
    <div class="personalBlock">
      <div class="leftBlock" v-if="!isMobile || tab == 0">
        <div class="userBlock">
          <div class="avatar">
            <img v-if="avatar" class="imgCut" :src="avatar" alt="" />
            <img class="imgCut" v-else src="@/assets/default.png" alt="" />
          </div>
          <div class="userInfo">
            <div class="userName">{{ nickname }}</div>
            <div class="userDet">
              <div class="gender" v-if="gender">
                <img v-if="gender == 1" src="@/assets/male.png" alt="" /><img
                  v-if="gender == 2"
                  src="@/assets/female.png"
                  alt=""
                />
              </div>
              <div class="birthday" v-if="birthday">
                <img src="@/assets/birthday.png" alt="" />{{ birthday }}
              </div>
            </div>
          </div>
          <div class="userEditBtn" @click="clickTab(5)">
            <img src="@/assets/edit.png" alt="" />{{ $t("编辑") }}
          </div>
        </div>
        <div class="sidebar">
          <div
            class="sidebarItem"
            @click="clickTab(1)"
            :class="{ selected: tab == 1 }"
          >
            我的收藏
          </div>
          <div
            class="sidebarItem"
            @click="clickTab(2)"
            :class="{ selected: tab == 2 }"
          >
            {{ $t("绑定与关联") }}
          </div>
          <div
            class="sidebarItem"
            @click="clickTab(3)"
            :class="{ selected: tab == 3 }"
          >
            {{ $t("历史浏览记录") }}
          </div>
          <div
            class="sidebarItem"
            @click="clickTab(4)"
            :class="{ selected: tab == 4 }"
          >
            {{ $t("系统消息") }}
          </div>
          <!-- <div class="sidebarItem" @click="clickContactCs()">
            {{ $t("联系客服") }}
            <div class="csQrCodeImg" v-if="!isMobile">
              <img src="@/assets/qrcode.png" alt="" />
            </div>
          </div> -->
          <div class="sidebarItem" @click="quitLogin">{{ $t("退出登录") }}</div>
          <div class="sidebarItem" @click="clickLogOff">
            {{ $t("注销账号") }}
          </div>
        </div>
      </div>
      <div class="rightBlock" v-if="tab != 0">
        <!-- 我的主页 -->
        <div v-if="tab == 1">
          <div class="likeTypeLine">
            <div
              class="likeType"
              @click="clickLikeType(0)"
              :class="{ selected: likeType == 0 }"
            >
              房源
            </div>
            <div class="devider"></div>
            <div
              class="likeType"
              @click="clickLikeType(1)"
              :class="{ selected: likeType == 1 }"
            >
              工作
            </div>
            <div class="devider"></div>
            <div
              class="likeType"
              @click="clickLikeType(2)"
              :class="{ selected: likeType == 2 }"
            >
              生活助手
            </div>
            <div class="vsBtn" @click="clickVs" v-if="likeType == 0">
              房源VS
            </div>
          </div>
          <template v-if="!empty">
            <div class="likeList" v-if="likeType == 0">
              <template v-if="roomList && roomList.length">
                <LikeRoomItem
                  from="personal"
                  v-for="item in roomList"
                  :key="item.id"
                  :item="item.roomType"
                />
              </template>
              <div class="null" v-else>
                {{ loading ? $t("加载中...") : $t("暂无收藏") }}
              </div>
            </div>
            <div class="likeList" v-if="likeType == 1">
              <template v-if="jobList.length">
                <div
                  class="likeJobItem"
                  v-for="item in jobList"
                  :key="item.id"
                  @click="openJobs(item.job.id)"
                >
                  <div class="jobTitle textOverflow">
                    {{ item.job.title }}
                  </div>
                  <div class="company">
                    <div class="companyImg">
                      <img
                        v-if="item.job.companyAvatar"
                        class="imgCut"
                        :src="item.job.companyAvatar"
                        alt=""
                      />
                      <img
                        class="imgCut"
                        v-else
                        src="@/assets/company.png"
                        alt=""
                      />
                    </div>
                    <div class="companyName">{{ item.job.companyName }}</div>
                    <div class="jobDate">{{ item.job.createDate }}</div>
                  </div>
                </div>
              </template>
              <div class="null" v-else>
                {{ loading ? $t("加载中...") : $t("暂无收藏") }}
              </div>
            </div>
            <div class="helperList" v-if="likeType == 2">
              <template v-if="helperList.length">
                <HelperItem
                  v-for="item in helperList"
                  :key="item.id"
                  :item="item.liveHelper"
                />
              </template>
              <div class="null" v-else>
                {{ loading ? $t("加载中...") : $t("暂无收藏") }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="empty">{{ $t("暂无收藏") }}</div>
          </template>
        </div>
        <!-- 绑定和关联 -->
        <div v-if="tab == 2">
          <div class="bindItem">
            <div class="bindLeft">
              <div class="bindTitle">{{ $t("绑定邮箱") }}</div>
              <div class="bindStatus">
                {{ email ? email : "未绑定" }}
              </div>
            </div>
            <div class="bindRight">
              <div v-if="!email" class="editBtn" @click="clickBind('email')">
                {{ $t("绑定") }}
              </div>
              <div v-else>{{ $t("已绑定") }}</div>
            </div>
          </div>
          <div class="bindItem">
            <div class="bindLeft">
              <div class="bindTitle">{{ $t("绑定微信") }}</div>
              <div class="bindStatus">
                {{ wxUnionid ? $t("已绑定") : $t("未绑定") }}
              </div>
            </div>
            <div class="bindRight">
              <div
                class="editBtn"
                v-if="!wxUnionid"
                @click="clickBind('wechat')"
              >
                {{ $t("绑定") }}
              </div>
              <div v-else>{{ $t("已绑定") }}</div>
            </div>
          </div>
        </div>
        <!-- 历史浏览记录 -->
        <div v-if="tab == 3">
          <template v-if="historyList && historyList.length">
            <div class="historyTips">仅保留近30天的历史浏览记录</div>
            <HistoryItem
              v-for="item in historyList"
              :key="item.id"
              :item="item"
            />
            <div class="pageLine">
              <el-pagination
                :page-size="10"
                :pager-count="5"
                layout="prev, pager, next"
                :total="historyTotal"
                :current-page="pageNum"
                @current-change="handleCurrentChange1"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </template>
          <div class="null" v-else>
            {{ loading ? $t("加载中...") : $t("暂无历史记录") }}
          </div>
        </div>
        <!-- 系统消息 -->
        <div v-if="tab == 4">
          <template v-if="messageList && messageList.length">
            <MessageItem
              v-for="item in messageList"
              :item="item"
              :key="item.id"
            />
            <div class="pageLine">
              <el-pagination
                :page-size="10"
                :pager-count="5"
                layout="prev, pager, next"
                :total="messageTotal"
                :current-page="pageNum"
                @current-change="handleCurrentChange2"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </template>
          <div class="null" v-else>
            {{ loading ? $t("加载中...") : $t("暂无系统消息") }}
          </div>
        </div>
        <!-- 编辑个人信息 -->
        <div v-if="tab == 5" class="editBlock">
          <div class="editTitle">{{ $t("账号信息设置") }}</div>
          <el-form :rules="rules" ref="ruleForm" :model="form">
            <el-form-item
              :label="$t('头像')"
              prop="imageUrl"
              style="margin-bottom: 0; border: none"
            >
              <el-upload
                class="avatar-uploader"
                action="https://horseonliving.com/webapi/common/upload/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :disabled="formDisabled"
                :class="{ disabled: formDisabled }"
              >
                <div v-if="form.imageUrl" class="avatar">
                  <img class="imgCut" :src="form.imageUrl" />
                </div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item :label="$t('昵称')" prop="nickname">
              <el-input
                v-model="form.nickname"
                :disabled="formDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('性别')" prop="genderLabel">
              <el-radio-group
                v-model="form.genderLabel"
                :disabled="formDisabled"
              >
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="生日" prop="birthday">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                placeholder="选择日期"
                :disabled="formDisabled"
                value-format="yyyy-MM-dd"
                default-value="2000-01-01"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="地址" prop="address">
              <el-input
                v-model="form.address"
                :disabled="formDisabled"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btnLine">
            <div
              class="settingBtn editBtn"
              v-show="formDisabled"
              @click="formDisabled = false"
            >
              {{ $t("编辑") }}
            </div>
            <div class="settingBtn" v-show="!formDisabled">
              <div class="editBtn" @click="clickCancelEdit()">取消</div>
              <div class="editBtn" @click="clickSubmitEdit()">保存</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <QuitDialog ref="quitDialog" />
    <LogOffDialog ref="logOffDialog" />
    <BindDialog ref="bindDialog" />
    <HousesVsDialog ref="housesVsDialog" :roomList="roomList" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MessageItem from "@/components/MessageItem.vue";
import QuitDialog from "@/components/QuitDialog.vue";
import BindDialog from "@/components/BindDialog.vue";
import LikeRoomItem from "@/components/LikeRoomItem.vue";
import HousesVsDialog from "@/components/HousesVsDialog.vue";
import LogOffDialog from "@/components/LogOffDialog.vue";
import HistoryItem from "@/components/HistoryItem.vue";
import { mapGetters } from "vuex";
import HelperItem from "@/components/HelperItem.vue";
import request from "@/utils/request";

// import request from "@/utils/request";
export default {
  data() {
    const calNickNameLength = (nickname) => {
      // eslint-disable-next-line
      const reg = /[^\x00-\xff]/;
      let len = 0;
      for (let i = 0; i < nickname.length; i++) {
        if (nickname[i].match(reg)) {
          len += 2;
        } else {
          len++;
        }
      }
      return len;
    };
    const validateNickname = (rule, value, callback) => {
      console.log("===validateNickname", value, typeof value);
      if (!value) {
        return callback(new Error("昵称不能为空"));
      }
      const len = calNickNameLength(value);
      console.log("len", len);
      if (len < 1 || len > 20) {
        return callback(new Error("请输入1-20个字符"));
      }
      callback();
    };
    return {
      loading: false,
      keywords: "",
      houseType: 0,
      tab: 1,
      likeType: 0,
      empty: false,
      messageEmpty: false,
      historyEmpty: false,
      rules: {
        imageUrl: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        nickname: [
          { validator: validateNickname, required: true, trigger: "blur" },
        ],
        genderLabel: [
          { required: true, message: "请选择你的性别", trigger: "blur" },
        ],
      },
      form: {
        imageUrl: "",
        nickname: "",
        genderLabel: "",
        address: "",
        birthday: "",
      },
      formDisabled: true,
      genderMap: {
        1: "男",
        2: "女",
      },
      genderEditMap: {
        男: 1,
        女: 2,
      },
      roomList: [],
      jobList: [],
      helperList: [],
      pageNum: 1,
      historyList: [],
      messageList: [],
      historyTotal: 0,
      messageTotal: 0,
    };
  },
  components: {
    Header,
    Footer,
    MessageItem,
    QuitDialog,
    BindDialog,
    LikeRoomItem,
    HousesVsDialog,
    LogOffDialog,
    HistoryItem,
    HelperItem,
  },
  async created() {
    document.title = "个人主页 - 马上住 HorseOnLiving";
    if (!this.isLogin) {
      window.location.href = `/?lan=${this.$i18n.locale}`;
      return;
    }
    this.webInit();
    if (this.$route.query.tab && this.$route.query.tab != 0) {
      this.tab = this.$route.query.tab;
      if (this.tab == 3) {
        this.getHistoryList();
      }
      if (this.tab == 4) {
        this.getMessageList();
      }
    } else if (this.isMobile) {
      this.tab = 0;
    }
    this.getCollectList();
  },
  computed: {
    ...mapGetters([
      "isLogin",
      "avatar",
      "id",
      "gender",
      "address",
      "birthday",
      "nickname",
      "email",
      "wxUnionid",
    ]),
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 跳转回首页
        if (oldVal && !val) {
          window.location.href = `/?lan=${this.$i18n.locale}`;
        }
      },
    },
    id: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下个人信息
        if (!oldVal && val) {
          this.form = {
            imageUrl: this.avatar,
            nickname: this.nickname,
            address: this.address,
            genderLabel: this.genderMap[this.gender],
            birthday: this.birthday,
          };
        }
      },
    },
  },
  methods: {
    openJobs(id) {
      window.open(`/jobs/detail?lan=${this.$i18n.locale}&id=${id}`);
    },
    // 点击联系客服（仅移动端）
    clickContactCs() {
      if (this.isMobile) {
        window.open("https://work.weixin.qq.com/kfid/kfc74ccd69410ce7eee");
      }
    },
    // 初始化收消息
    async webInit() {
      const webInitRes = await request.get("/webapi/index/init", {});
      if (webInitRes.code == 200) {
        console.log("初始化成功");
      }
    },
    // 系统消息翻页
     handleCurrentChange2(val) {
      this.pageNum = val;
      this.getMessageList();
      console.log(`当前页: ${val}`);
    },
    // 获取系统消息列表
    async getMessageList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const messageListRes = await request.get("/webapi/userMessage/list", {
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
        },
      });
      this.loading = false;
      if (messageListRes.code == 200) {
        this.messageList = messageListRes.data.records;
        this.messageTotal = messageListRes.data.total;
      }
    },
    // 历史记录翻页
    handleCurrentChange1(val) {
      this.pageNum = val;
      this.getHistoryList();
      console.log(`当前页: ${val}`);
    },
    // 获取历史记录列表
    async getHistoryList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const historyListRes = await request.get("/webapi/userViewLog/list", {
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        },
      });
      this.loading = false;
      if (historyListRes.code == 200) {
        this.historyList = historyListRes.data.records;
        this.historyTotal = historyListRes.data.total;
      }
    },
    // 获取收藏列表
    async getCollectList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      // 类型：2=房型，3=工作，4=生活助手
      const collectListRes = await request.get("/webapi/userCollect/list", {
        params: {
          ht: this.likeType + 2,
          pageNum: this.pageNum,
          pageSize: 10,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        },
      });
      this.loading = false;
      if (collectListRes.code == 200) {
        if (this.likeType == 0) {
          this.roomList = collectListRes.data;
        } else if (this.likeType == 1) {
          this.jobList = collectListRes.data;
        } else if (this.likeType == 2) {
          this.helperList = collectListRes.data;
        }
      }
    },
    async handleLogOut() {
      await this.$store.dispatch("user/logout");
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      location.reload();
    },
    clickVs() {
      if (this.roomList.length >= 2) {
        this.$refs["housesVsDialog"].contentVisible = true;
      }
      else return this.$message({
          message: '请收藏两个或以上房源后再进行VS',
        });
    },
    clickBind(type) {
      this.$refs["bindDialog"].bindType = type;
      this.$refs["bindDialog"].contentVisible = true;
    },
    quitLogin() {
      this.$refs["quitDialog"].contentVisible = true;
    },
    clickLogOff() {
      this.$refs["logOffDialog"].contentVisible = true;
    },
    handleAvatarSuccess(res, file) {
      console.log("===: ", res, file);
      this.form.imageUrl = res.data.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!isJPG && !isPNG) {
        this.$message.error("请上传 JPG 或 PNG 格式的图片");
      }
      if (!isLt4M) {
        this.$message.error("上传的图片大小不能超过 4MB 哦");
      }
      return (isJPG || isPNG) && isLt4M;
    },
    clickTab(tab) {
      window.location.href = `/personal?lan=${this.$i18n.locale}&tab=${tab}`;
    },
    clickLikeType(type) {
      this.likeType = type;
      this.getCollectList();
    },
    clickCancelEdit() {
      this.$refs.ruleForm.resetFields();
      this.formDisabled = true;
      this.form = {
        imageUrl: this.avatar,
        nickname: this.nickname,
        address: this.address,
        genderLabel: this.genderMap[this.gender],
        birthday: this.birthday,
      };
    },
    async clickSubmitEdit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          const editRes = await request.post("/webapi/user/update", {
            address: this.form.address,
            avatar: this.form.imageUrl,
            birthday: this.form.birthday,
            gender: this.genderEditMap[this.form.genderLabel],
            nickname: this.form.nickname,
          });
          if (editRes.code == 200) {
            this.$store.dispatch("user/GetInfo");
            this.$message({
              message: "修改成功",
              type: "success",
            });
            return (this.formDisabled = true);
          }
          console.log("校验通过");
        } else {
          console.log("校验不通过！error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.personalBlock {
  margin: 85px auto 30px auto;
  width: 95%;
  max-width: 1200px;
  min-height: calc(100vh - 155px);
  font-size: 14px;
  display: flex;
  .null {
    height: calc(100vh - 300px);
    width: 100%;
  }
  .leftBlock {
    width: 150px;
    margin-right: 18px;
    .userBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(255, 192, 62, 0.1);
      border: 1px solid #ffc03e;
      border-radius: 4px;
      margin-bottom: 24px;
      padding: 18px 8px;
      box-sizing: border-box;
      .avatar {
        border: 2px solid #ffac00;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 4px;
        flex-shrink: 0;
      }
      .userInfo {
        display: flex;
        flex-direction: column;
      }
      .userName {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        width: 136px;
        margin-bottom: 6px;
        min-height: 28px;
      }
      .userDet {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        .gender {
          width: 20px;
          height: 16px;

          margin-right: 6px;
          img {
            width: 20px;
          }
        }
        .birthday {
          padding: 2px 6px;
          color: #666;
          background: rgba(153, 153, 153, 0.1);
          border-radius: 31px;
          display: flex;
          align-items: center;
          font-size: 12px;
          img {
            width: 12px;
            height: 12px;
            margin-right: 2px;
          }
        }
      }
      .userEditBtn {
        padding: 4px 10px;
        background: rgba(255, 192, 62, 0.2);
        border-radius: 32px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
      }
    }
  }
  .rightBlock {
    width: 1032px;
    .empty {
      text-align: center;
      margin-top: 200px;
    }
    .likeTypeLine {
      display: flex;
      height: 55px;
      align-items: center;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 16px;
      margin-bottom: 17px;
      .likeType {
        padding: 10px 24px;
        margin: 0 24px;
        cursor: pointer;
      }
      .likeType.selected {
        font-weight: 700;
      }
      .devider {
        width: 1px;
        height: 14px;
        background: #999;
      }
    }
  }
  .sidebarItem {
    cursor: pointer;
    margin-bottom: 16px;
    padding: 6px 12px;
    font-size: 16px;
    border-radius: 35px;
    display: inline-block;
    position: relative;
    .csQrCodeImg {
      display: none;
      position: absolute;
      background: #fff;
      z-index: 1;
      left: 80px;
      top: 0;
      img {
        width: 200px;
      }
    }
  }
  .sidebarItem:hover {
    .csQrCodeImg {
      display: block;
    }
  }
  .sidebarItem.selected {
    background: #ffc03e;
  }
}
.vsBtn {
  background: #2eaac1;
  border-radius: 4px;
  padding: 6px 20px;
  color: #fff;
  font-weight: 500;
  margin: 0 12px 0 auto;
  cursor: pointer;
}
.likeList {
  display: flex;
  flex-wrap: wrap;
}

.likeJobItem {
  width: 330px;
  height: 130px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin: 0 14px 18px 0;
  box-sizing: border-box;
  padding: 22px 17px 15px 17px;
  cursor: pointer;
  .jobTitle.textOverflow {
    -webkit-line-clamp: 2;
  }
  .jobTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 21px;
    height: 48px;
  }
  .company {
    color: #8592a6;
  }
}
.likeJobItem:nth-child(3n) {
  margin-right: 0;
}
.bindItem {
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
  align-items: center;
  .bindLeft {
    .bindTitle {
      margin-bottom: 9px;
    }
    .bindStatus {
      color: #666;
    }
  }
  .bindRight {
    margin-left: auto;
    .editBtn {
      padding: 4px 15px;
      border: 1px solid #000000;
      border-radius: 22px;
      cursor: pointer;
    }
  }
}
.historyDate {
  font-size: 14px;
  margin-bottom: 8px;
}
.historyTips {
  margin-bottom: 16px;
}
.historyItem {
  display: flex;
  margin-bottom: 18px;
  font-size: 14px;
  align-items: center;
  .historyType {
    padding: 0px 5px;
    background: #cccccc;
    border-radius: 2px;
    margin-right: 4px;
    cursor: pointer;
  }
  .historyTitle {
    cursor: pointer;
  }
  .historyTitle.textOverflow {
    -webkit-line-clamp: 1;
  }
  .deleteBtn {
    margin-left: auto;
    cursor: pointer;
    img {
      width: 24px;
    }
  }
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ffac00;
}
.editBlock {
  padding: 0 32px;
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #ffac00;
  }
  .btnLine {
    display: flex;
    .editBtn {
      border: 1px solid #000000;
      border-radius: 21px;
      padding: 4px 15px;
      cursor: pointer;
      margin-left: 20px;
    }
    .settingBtn {
      margin-left: auto;
      display: flex;
    }
  }
  .editTitle {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .el-form-item__label {
    text-align: left;
    line-height: 30px;
    color: #000;
  }
  .el-input__inner {
    line-height: 30px;
    height: 30px;
    border-radius: 0;
    border: none;
    padding: 0;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 30px;
  }
  .el-form-item__content {
    line-height: 30px;
    color: #666;
  }
  .el-input__icon {
    line-height: 30px;
  }
  .el-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .el-input.is-disabled .el-input__inner {
    border: none;
    background: transparent;
  }
  .el-form-item__error {
    right: 0;
    top: 10px;
    left: auto;
  }
}
.avatar-uploader .el-upload {
  border: none;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.disabled.avatar-uploader .el-upload {
  cursor: not-allowed;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #2eaac1;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #666;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #000;
  background: #000;
}
.el-radio__inner::after {
  background: #ffc03e;
}
.el-radio__inner:hover {
  border-color: #000;
}
.el-date-picker__header-label:hover {
  color: #2eaac1;
}

.el-date-table td.today span {
  color: #2eaac1;
}
.el-date-table td.current:not(.disabled) span {
  background-color: #2eaac1;
}
.el-date-table td:hover {
  color: #2eaac1 !important;
}
.el-year-table td.today .cell {
  color: #2eaac1;
}
.el-picker-panel__icon-btn:hover {
  color: #2eaac1;
}
.el-month-table td .cell:hover {
  color: #2eaac1;
}
.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
  color: #2eaac1;
}
.el-month-table td.today .cell {
  color: #2eaac1;
}
.el-month-table td.current:not(.disabled) .cell {
  color: #2eaac1;
}
</style>

<style lang="scss">
// 移动端样式
.isMobile {
  .likeJobItem {
    width: 100%;
    height: 103px;
    padding: 12px 18px;
    margin-right: 0;
    .jobTitle {
      font-size: 15px;
      height: 45px;
      margin-bottom: 12px;
    }
  }
  .personalBlock {
    margin-top: 60px;
    min-height: calc(100vh - 188px);
    .leftBlock {
      width: 95%;
      margin: 0 auto;
      .userBlock {
        height: 85px;
        flex-direction: row;
        .avatar {
          width: 65px;
          height: 65px;
          margin: 0 9px 0 0;
        }

        .userName {
          height: 24px;
          text-align: left;
          margin-bottom: 2px;
        }
        .userDet {
          justify-content: flex-start;
          margin-bottom: 0;
        }
        .userEditBtn {
          margin-left: auto;
        }
      }
    }
    .sidebarItem {
      display: block;
    }
    .rightBlock {
      width: 95%;
      margin: 0 auto;
      .likeTypeLine {
        font-size: 14px;
        height: 40px;
        .likeType {
          padding: 4px 8px;
          margin: 0 6px;
        }
      }
    }
  }
  .vsBtn {
    padding: 4px 12px;
  }
  .editBlock {
    padding: 0 10px;
    .avatar {
      width: 60px;
      height: 60px;
    }
    .el-form-item {
      padding-bottom: 8px;
    }
  }
}
</style>