<template>
  <div class="privacy">
    <h1>HorseOn Living 官网及小程序隐私条款</h1>
    <div class="text">
      <p>
        HorseOn Living
        深知个人信息对您的重要性，会尽力保护您的个人信息安全。我们致力于维护您对我们的信任，恪守以下原则保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、必要性原则、确保安全原则、公开透明原则等。同时，我们承诺依法采取相应的安全保护措施来保护您的个人信息。
      </p>
      <p>
        《HorseOn
        Living官网及小程序隐私政策》（以下简称“隐私政策”）将帮助您了解以下内容：
      </p>
      <p>
        一、我们如何收集和使用您的个人信息<br />
        二、我们如何使用Cookie和同类技术<br />
        三、我们如何共享、转让和公开披露您的个人信息<br />
        四、我们如何存储和保护您的个人信息<br />
        五、我们如何使您拥有管理个人信息的权利<br />
        六、我们如何处理未成年人信息<br />
        七、本政策的适用以及更新<br />
        八、如何联系我们<br />
      </p>
      <h2>一、我们如何收集和使用您的个人信息</h2>
      <p>
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括<b>姓名、出生日期、身份证件号码、个人生物识别信息、通信通讯联系方式、住址、账户信息、财产状况、行踪轨迹</b>等。其中一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括<b>生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹</b>等信息，以及不满十四周岁未成年人的个人信息。
      </p>
      <p>
        我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用HorseOn
        Living服务。而这些个人信息有助于我们实现这一目标。
      </p>
      <h3>（一）我们将通过以下途径收集和获得您的个人信息：</h3>
      <h4>1、您提供的个人信息。</h4>
      <p>
        （1）当您在注册HorseOn Living服务的账号或使用HorseOn
        Living服务时，向我们提供的个人信息，包括您的手机号码及邮箱，并且您可以自愿选择填写您的昵称、头像、性别、年龄、出生日期、学历、学校、身份证号码、住址等基本信息，以便帮助您注册使用HorseOn
        Living及其服务。如果您拒绝提供这些信息，您可能无法开通账号或无法正常使用HorseOn
        Living的服务。
      </p>
      <p>
        （2）您通过HorseOn
        Living服务向其他方提供的共享个人信息，以及您使用HorseOn
        Living服务时所储存的个人信息。
      </p>
      <h4>
        2、其他方分享的您的个人信息。亦即其他方使用HorseOn
        Living服务时所提供有关您的共享个人信息。
      </h4>
      <h4>
        3、我们获取的您的个人信息。您在使用HorseOn
        Living服务时，我们收集、汇总、记录的个人信息。
      </h4>
      <h3>（二）我们会出于以下目的，收集和使用您以下类型的个人信息：</h3>
      <h4>1、帮助您完成注册、登录及注销</h4>
      <p>
        为便于我们为您提供完整的HorseOn
        Living服务，您需要提供基本注册或登录个人信息，包括使用手机号码或邮箱注册登录或提供第三方账号登录HorseOn
        Living官网或小程序。如果您仅需使用浏览和搜索等基本服务，您不需要注册成为HorseOn
        Living用户及提供上述个人信息。
      </p>
      <h4>2、客服与售后</h4>
      <p>
        依照相关法律法规规定及监管要求，或当您与我们联系时，我们的客服或售后可能会需要<b>您提供真实身份信息（姓名、身份证（个人敏感信息））和账号信息以核验您的身份，以便帮助您解决问题，或记录相关问题的处理方案及结果。</b>
      </p>
      <p>
        <b>请注意，您的身份信息属于个人敏感信息，请您谨慎提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</b>
      </p>
      <h4>3、维护基础功能的正常运行</h4>
      <p>
        在您使用我们服务过程中，为识别账号异常状态、了解功能适配性，向您提供浏览、搜索、注册/登录、查看和编辑个人资料、预订、支付的服务，维护基础功能的正常运行，<b>我们可能会自动收集、储存关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：</b>
      </p>
      <p><b>
        （1）日志信息：当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。日志信息包括您的登录账号、搜索查询内容、粘贴板信息、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、崩溃记录、停留时长、刷新记录、发布记录、关注、订阅、收藏及分享。</b>
      </p>
      <p><b>
        （2）设备信息：我们可能会根据您在网页或小程序使用中授予的具体权限，接收并记录您所使用的设备相关信息（包括设备标识符（IMEI/
        MAC/OPEN
        UDID）、Serial、SIM卡IMSI识别码、设备机型、设备序列号、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、进程及软件列表、Android
        ID、IDFA、UUID、软硬件特征信息）、设备所在位置相关信息（包括IP
        地址、GPS位置以及能够提供相关个人信息的WLAN接入点、蓝牙和基站传感器信息）、运行进程信息。</b>
      </p>
      <p><b>
        同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用我们的服务。</b>
      </p>
      <p>
        请注意，单独的设备信息、日志信息是无法识别特定自然人身份的个人信息。如果我们将这类非个人信息与其他个人信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
      </p>
      <h4>4、向您提供服务</h4>
      <p>
        （1）个人信息的分享与发布：您在使用HorseOn
        Living服务时、上传和/或发布个人信息以及进行相关行为时（例如：您在HorseOn
        Living官网或小程序内发布内容或评论留言、提交服务评价），我们将收集您上传、发布或形成的个人信息，并有权展示您的昵称、头像和发布内容。
      </p>
      <p>
        （2）服务交付：当您在我们的页面中选择立即预订具体商品或服务时，我们会通过系统为您生成该服务的专属优惠码。为便于向您交付您在HorseOn
        Living官网或小程序内预订的商品或服务，您可能需要提供预订者的<b>个人身份信息、姓名、联系电话、邮箱信息（以上信息中可能会涉及您的个人敏感信息）</b>。如果您拒绝提供此类个人信息，我们将无法完成相关交付服务。如您通过HorseOn
        Living服务为其他人预订商品或服务，您需要提供该实际预订人的前述个人信息。<b>向我们提供该实际预订人的前述个人信息之前，您需确保您已经取得其授权同意。</b>
      </p>
      <p>
        （3）客服服务：当您联系我们的客服或使用其他用户响应功能时（包括提出咨询客服、个人信息保护投诉或建议、其他客户投诉和需求），<b>我们可能需要您提供必要的个人信息以匹配并核验您的用户身份，以便保障您的账号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息（以上信息中可能会涉及您的个人敏感信息）</b>，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
      </p>
      <h4>5、我们通过间接方式收集到的您的个人信息：</h4>
      <p>
        基于您选择使用的HorseOn
        Living服务，我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。例如，当您使用第三方平台的帐号登录HorseOn
        Living服务时，我们将根据您的授权获取该第三方帐号下的相关信息（包括：用户名、昵称、头像，具体以您的授权内容为准），<b>并在您同意本政策后将您的第三方帐号与您的HorseOn
        Living帐号绑定，使您可以通过第三方帐号直接登录并使用本服务。</b>我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。
      </p>
      <h4>6、向您提供商品或服务的信息展示和推送</h4>
      <p>（1）为您展示和推送商品或服务</p>
      <p>
        基于您向我们提供的信息、我们可能收集的信息及我们通过间接方式收集到的您的信息（包括<b>您的浏览及搜索记录、设备信息、订单信息、手机号码、您参与HorseOn
        Living线上或线下活动填写问卷调查上的个人信息），我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，用于提取您的浏览、搜索偏好、行为习惯相关特征，以便向您提供更契合您需求的页面展示和推荐个性化内容。</b>
      </p>
      <p><b>
        我们也可能基于特征标签通过电子邮件、短信、电话或其他方式向您发送营销信息，提供或推广我们或第三方的如下商品和服务：</b>
      </p>
      <p>
        我们及我们关联方的商品和服务，包括：在线求职服务、语言翻译服务、语音识别和语音合成类开发平台服务、智能硬件服务、云应用服务、电子商务、即时通讯服务、网上媒体服务、互动娱乐服务、社交网络服务、支付服务、应用软件和服务、数据管理软件和服务、网上广告服务及其他社交媒体、娱乐、资讯及通讯软件和服务；及第三方商品和服务，包括：互联网服务、物流服务、娱乐、广告服务、积分和奖励计划，以及我们认为可能与您相关的其他商品和服务。
      </p>
      <p><b>
        （2）向您发出通知。对于您在使用服务过程中提供的您的联系方式（例如：手机号码），我们可能在运营中向您发出与服务有关的通知，用于用户消息告知、身份验证、安全验证、用户使用体验调研等用途。此外，我们也可能通过短信、电话的方式，向您发送您可能感兴趣的服务、功能或活动。</b>
      </p>
      <p><b>
        如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：根据短信退订指引要求我们停止发送推广短信，或在移动端设备中进行设置，不再接收我们推送的消息；但我们依法律规定或服务协议约定发送消息的情形除外。</b>
      </p>
      <h4>7、为您提供安全保障</h4>
      <p><b>
        为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或HorseOn
        Living服务相关协议规则的情况，我们可能会收集、使用或整合您的账户信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的个人信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</b>
      </p>
      <h4>8、迭代升级</h4>
      <p><b>
        我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，我们可能将通过HorseOn
        Living服务所收集的个人信息，来诊断系统问题，优化产品体验</b>：在您使用HorseOn
        Living服务时所收集的您的个人信息，可能在我们的其他服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能让您参与有关HorseOn
        Living服务的调查，帮助我们改善现有服务或设计新服务；同时，我们可能将您的个人信息用于软件更新。<b>如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会依法通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。在此过程中，您可通过本政策第八条【如何联系我们】约定的联系方式联络我们。</b>
      </p>
      <h4>9、其他</h4>
      <p><b>
        您了解并同意，在收集您的个人信息后，我们可能通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用，而无需另行获得您的同意。</b>
      </p>
      <p><b>
        请您注意，如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会另行事先请您同意（确认同意的方式：勾选、弹窗、站内信、邮件、短信方式）。一旦您同意，该等额外用途将视为本政策的一部分，该等额外个人信息也将适用本政策。</b>
      </p>
      <p><b>
        如果我们要将您的个人信息用于本政策中未载明的其他用途或额外收集未提及的其他个人信息，我们会依法通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。在此过程中，您可通过本政策第八条【如何联系我们】约定的联系方式联络我们。</b>
      </p>
      <h4>10、征得授权同意的例外</h4>
      <p><b>
        根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</b>
      </p>
      <p>
        （1）与个人信息控制者履行法律法规规定的义务相关的； <br />
        （2）与国家安全、国防安全直接相关的； <br />
        （3）与公共安全、公共卫生、重大公共利益直接相关的； <br />
        （4）与刑事侦查、起诉、审判和判决执行等直接相关的； <br />
        （5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
        <br />
        （6） 所涉及的个人信息是个人信息主体自行向社会公众公开的； <br />
        （7）根据个人信息主体要求签订和履行合同所必需的； <br />
        （8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        <br />
        （9）维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
        <br />
      </p>
      <h4>
        11、您理解并同意，HorseOn
        Living服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及个人信息的收集和使用，<b>您可以点击<a
          href="/privacy/attachment"
          >《HorseOn Living应用权限申请与使用情况说明》</a
        >查看、了解HorseOn
        Living服务获取的您的权限名称、类型、获取场景及目的信息。</b>
      </h4>
      <p>
        当您需要关闭该功能时，大多数移动设备都会支持您的这项需求，具体方法请参考或联系您移动设备的服务商或生产商。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响您使用已授权其他权限的功能，以及此前基于您的授权所进行的个人信息收集及使用。
      </p>
      <h4>12、有关个人敏感信息的提示</h4>
      <p>
        个人敏感信息，是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。<b>以上由您提供或我们收集您的个人信息中，可能包含您的个人敏感信息，包括银行账号、交易和消费记录、虚拟财产信息、系统账号、邮箱地址及其有关的密码、电话号码、网页浏览记录、位置信息。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本政策所述的目的和方式来处理。</b>
      </p>
      <h2>二、我们如何使用COOKIES或同类技术</h2>
      <h3>（一）Cookies</h3>
      <p>
        我们或我们的第三方合作伙伴可能通过COOKIES获取和使用您的个人信息，并将该等个人信息储存为日志信息。
      </p>
      <p>
        通过使用COOKIES，我们向您提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让您可以受益。比如，为使得HorseOn
        Living的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录HorseOn
        Living的服务时能更加方便快捷。
      </p>
      <p>
        COOKIES能帮助我们确定您连接的页面和内容，您在HorseOn
        Living特定服务上花费的时间和您所选择的HorseOn Living服务。
      </p>
      <p>
        COOKIES使得我们能更好、更快地为您服务，并且使您在HorseOn
        Living服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。
      </p>
      <p>
        我们和第三方合作伙伴可能通过COOKIES收集和使用您的个人信息，并将该等个人信息储存。
      </p>
      <p>我们使用自己的COOKIES，可能用于以下用途：</p>
      <p>
        （1）记住您的身份。COOKIES有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他个人信息；
        <br />
        （2）分析您使用我们服务的情况。我们可利用COOKIES来了解您使用HorseOn
        Living服务进行什么活动、或哪些服务最受欢迎； <br />
        （3）广告优化。COOKIES有助于我们根据您的个人信息，向您提供与您相关的广告而非进行普遍的广告投放。
      </p>
      <p>
        我们为上述目的使用COOKIES的同时，可能将通过COOKIES收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用HorseOn
        Living服务并用于广告服务。
      </p>
      <p>
        HorseOn
        Living服务上可能会有广告商和其他合作方放置的COOKIES。这些COOKIES可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES收集和使用该等个人信息不受本政策约束，而是受到其自身的个人信息保护声明约束，我们不对第三方的COOKIES承担责任。
      </p>
      <p>
        您可以通过浏览器或用户选择机制拒绝或管理COOKIES。但请您注意，如果您停用COOKIES，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。
      </p>
      <h3>（二）网站信标和像素标签</h3>
      <p>
        除Cookies
        外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
      </p>
      <h2>三、我们可能共享、转让或披露的个人信息</h2>
      <h3>（一）共享</h3>
      <p>
        您同意我们依据本政策向我们的关联方或相关法人团体、与我们合作的一些商业合作伙伴（合称“信息接收方”）共享您的个人信息。我们将积极帮助您了解信息接收方对个人信息的存储、使用等情况，以及您享有的访问、更正、删除、撤回同意、注销账户等权利。除以下情形外，未经您同意，我们不会与我们及我们的信息接收方之外的任何第三方共享您的个人信息：
      </p>
      <p><b>
        1、为实现我们的服务/功能所必须进行的共享。我们可能向信息接收方共享您的个人信息，以实现您需要的核心功能或提供您需要的服务，包括（1）向房源提供者或服务商提供对应的订单信息；（2）向提供支付服务的第三方支付机构共享对应的交易订单信息和支付金额信息；（3）其他与我们的服务/功能相关的供应商、服务提供商和业务合作伙伴所必须进行的共享。</b>
      </p>
      <p><b>
        2、与我们的部分合作伙伴的必要共享。</b>为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议或本政策中的义务和行使我们的权利或遵守法律规定等目的，我们可能需要向部分合作伙伴共享您的信息，包括：（1）向提供数据服务（包括数据统计、数据分析等）的合作伙伴共享<b>日志信息、设备信息；</b>（2）向提供产品分析服务（包括应用崩溃分析、网络性能分析、域名解析优化）的合作伙伴共享您的<b>产品使用情况（如崩溃、闪退）的统计性数据；</b>（3）其他与我们的部分合作伙伴的必要共享。
      </p>
      <p><b>
        3、与我们的关联方的必要共享。</b>为便于我们基于统一的帐号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和帐号安全等，您的个人信息可能会在我们和我们的关联方之间进行必要共享，包括：（1）为了帮助您节省时间、简化操作流程，您在通过微信注册后无需重复注册即可登录部分服务，您的帐号相关信息会在我们和我们的关联方的产品内部进行必要共享；（2）为了使您在我们的及我们关联方产品与/或服务有一致性和个性化的浏览、广告等服务体验，您知悉并同意，您的帐号信息、设备信息、相关浏览数据等反映您使用习惯和爱好的信息可能会与我们的关联方共享。
      </p>
      <p><b>4、实现本政策第一条【我们如何收集和使用您的个人信息】部分所述目的；</b></p>
      <p><b>
        5、履行我们在本政策或我们与您达成的其他协议中的义务和行使我们的权利；</b>
      </p>
      <p><b>
        6、向委托我们进行推广的信息接收方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</b>
      </p>
      <p>
        7、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他HorseOn
        Living用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换个人信息。不过，这并不包括违反本政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
      </p>
      <p>8、应您合法需求，协助处理您与他人的纠纷或争议；</p>
      <p>9、应您的监护人合法要求而提供您的个人信息；</p>
      <p>
        10、根据与您签署的服务协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      </p>
      <p>11、基于学术研究而提供；</p>
      <p>12、基于符合法律法规的社会公共利益而提供。</p>
      <p>
        我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息。对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。
      </p>
      <h3>（二）转让</h3>
      <p><b>
        1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移。</b>我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
      </p>
      <p>2、在获得您的明确同意后，我们会向其他方转让您的个人信息。</p>
      <h3>（三）披露</h3>
      <p>
        我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人信息：
      </p>
      <p>1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
      <p><b>
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</b>
      </p>
      <h4>（四）共享、转让、披露个人信息时事先征得授权同意的例外</h4>
      <p>
        以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
      </p>
      <p>
        1、与个人信息控制者履行法律法规规定的义务相关的；<br />
        2、与国家安全、国防安全有关的；<br />
        3、与公共安全、公共卫生、重大公共利益有关的；<br />
        4、与刑事侦查、起诉、审判和判决执行等司法或行政执法有关的；<br />
        5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />
        6、您自行向社会公众公开的个人信息；<br />
        7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p>
        根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </p>
      <h2>四、我们如何存储和保护您的个人信息安全</h2>
      <h3>
        （一）除法律法规或监管部门另有规定外，我们仅在本政策所述目的所必需期间且最短时限内存储您的个人信息。我们判断前述期限的标准包括：
      </h3>
      <p>
        1、完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；<br />
        2、保证平台为您提供服务的安全和质量；<br />
        3、您是否同意更长的留存期间；<br />
        4、是否存在保留期限的其他特别约定。<br />
        5、法律法规或监管部门规定的期限。<br />
      </p>
      <p>
        在您的个人信息超出保留期间后，HorseOn
        Living会根据适用法律的要求删除您的个人信息，或使其匿名化处理（如您为未成年人，在您的个人信息超出保留期间后，HorseOn
        Living会按照相关法律法规要求对您的个人信息进行相应处理）。如因特殊情形无法按照相关要求删除的，平台将对该部分事项向您进行说明。
      </p>
      <p>
        如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在本政策所述目的所必须期间、法律法规及监管要求的时限届满后，或终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。
      </p>
      <h3>
        （二）我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。以下情形除外：
      </h3>
      <p>
        1、法律法规有明确规定； <br />
        2、获得您的授权同意； <br />
        3、您使用的产品、服务涉及跨境，我们需要向境外提供您的个人信息的。 <br />
        针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。<br />
      </p>
      <h3>
        （三）我们非常重视个人信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：
      </h3>
      <h4>1、数据安全技术措施</h4>
      <p>
        我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的个人信息，采取加密技术对您的个人信息进行加密保存，并通过隔离技术进行隔离。
        在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用多种数据脱敏技术增强个人信息在使用中的安全性。采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
      </p>
      <h4>2、为保护个人信息采取的其他安全措施</h4>
      <p>
        （1）我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。<br />
        （2）我们通过个人信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
        <br />
        （3）我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识和安全意识。
        <br /><b>
        （4）我们仅允许有必要知晓这些个人信息的我们及我们关联方的员工、合作伙伴访问您的个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。</b><br />
      </p>
      <h4>
        3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
      </h4>
      <h4>
        4、我们将尽力确保或担保您发送给我们的任何个人信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致个人信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </h4>
      <h3>（四）安全事件处置</h3>
      <p>
        在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、短信、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </p>
      <p><b>
        请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证个人信息百分之百的安全。您需要了解，您接入HorseOn
        Living服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</b>
      </p>
      <h2>五、如何管理您的个人信息</h2>
      <p>
        我们鼓励您更新和修改您的个人信息以使其更准确有效，<b>也请您理解，您更正、删除、撤回授权或停止使用HorseOn
        Living服务的决定，并不影响我们此前基于您的授权而开展的个人信息处理。</b>
      </p>
      <p>您可以通过以下方式来管理您的个人信息：</p>
      <h3>（一）访问您的个人信息</h3>
      <p>
        账户个人信息：如果您希望访问您的账户中的个人基本个人信息（包括头像、昵称、简介、性别、年龄、学历、学校），您可以登录网页或小程序账号通过“我的主页”进行访问。
      </p>
      <h3>（二）更正您的个人信息</h3>
      <p>
        如果您希望更正您的账户中的个人基本个人信息（包括头像、昵称、邮箱、地址、手机号），您可以登录移动客户端账号通过“HorseOn
        Living网页或小程序-个人主页-编辑资料”进行修改。
      </p>
      <h3>（三）删除您的个人信息或您的账户</h3>
      <p>
        您可以通过“访问、更正您的个人信息”中列明的方式自主删除您的部分个人信息或通过本政策第八条【如何联系我们】约定的联系方式联络我们删除您的账户，一般情况下，我们将在十五天内予以回复。在部分个人信息删除或删账户时，我们可能会要求您进行身份验证，以保障个人信息安全。
      </p>
      <p>
        在以下情形中，您可以向我们提出删除个人信息的请求：<br />
        1、如果我们处理个人信息的行为违反法律法规； <br />
        2、如果我们收集、使用您的个人信息，却未征得您的授权同意； <br />
        3、如果我们处理个人信息的行为严重违反了与您的约定； <br />
        4、如果我们不再为您提供HorseOn Living服务。<br />
        若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。<br />
      </p>
      <h3>（四）改变您授权同意的范围</h3>
      <p>
        您总是可以选择是否披露个人信息。有些个人信息是使用HorseOn
        Living服务所必需的，但大多数其他个人信息的提供是由您决定的。您可以通过“访问、更正您的个人信息”中列明的方式”删除个人信息、关闭设备功能或注销账号等方式改变您授权我们继续收集个人信息的范围，或<b>通过本政策第八条【如何联系我们】约定的联系方式联络我们撤回您的授权，一般情况下，我们将在十五天内予以回复。<br />
        如您希望撤回授权，我们将无法继续为您提供撤回授权所对应的服务，也不再处理您相应的个人信息，将会为您注销账号。但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。 <br /></b>
      </p>
      <h3>（五）注销您的账号</h3>
      <p><b>
        如果您不再使用HorseOn
        Living服务，在符合服务协议约定条件及国家相关法律法规规定的情况下，您可以通过登录HorseOn
        Living官网或小程序，在“设置-绑定和关联-注销账号”所示的流程或本政策第八条【如何联系我们】约定的联系方式联络我们注销您的账号，一般情况下，我们将在十五个工作日内为您注销账号，届时我们将停止为您提供HorseOn
        Living服务。当您的帐号注销或被删除后，与该帐号相关的HorseOn
        Living服务项下的全部服务资料和数据将被删除或匿名化处理，但法律法规另有规定的除外。</b>
      </p>
      <h3>（六）公开与分享</h3>
      <p>
        我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息。<b>如您在HorseOn
        Living服务中其他用户可见的公开区域内上传或发布的个人信息中、您对其他人上传或发布的信息作出的回应中公开您的个人信息，该等个人信息可能会被他人收集并加以使用，只要您不删除您所公开或共享的个人信息，有关个人信息可能一直留存在公众领域；即使您删除共享个人信息，有关个人信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将个人信息通过上述渠道公开或共享，由此造成您的个人信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的个人信息。当您发现他人不正当地收集或使用您的个人信息时，您可以通过本政策第八条【如何联系我们】约定的联系方式联络我们。</b>
      </p>
      <h3>（七）获取个人信息副本</h3>
      <p>
        您可以通过邮箱info@horseon.hk 联系我们并且告知我们您想要获取您的个人信息副本，一般情况下，我们将在收到您的问题之日起十五天内予以回复。
      </p>
      <h3>（八）响应您的上述请求</h3>
      <p>
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br />
        <b>在以下情形中，我们将无法响应您的请求：</b>
      </p>
      <p>
        1、与我们履行法律法规规定的义务相关的； <br />
        2、与国家安全、国防安全直接相关的； <br />
        3、与公共安全、公共卫生、重大公共利益直接相关的； <br />
        4、与刑事侦查、起诉、审判和执行判决等直接相关的； <br />
        5、我们有充分证据表明您存在主观恶意或滥用权利的； <br />
        6、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />
        7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的； <br />
        8、涉及商业秘密的。<br />
      </p>
      <h2>六、我们如何处理未成年人信息</h2>
      <p><b>
        1、如您为未满18周岁的未成年人，请务必让您的监护人仔细阅读本政策，并在征得您的监护人同意的前提下使用我们的服务或向我们提供个人信息。我们将根据国家相关法律法规的规定保护未成年人的相关个人信息。<br />
        特别地，若您是14周岁以下的儿童，请注意您的个人信息都属于敏感信息。对于经监护人同意而收集您的信息的情况，我们只会在受到法律允许、监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。</b>
      </p>
      <p>
        2、如您为未成年人的监护人，当您对您所监护的未成年人的信息处理存在疑问，或发现相关未成年人个人信息为未成年人自行填写，需要进行修改或删除处理时，<b>请通过本政策第八条【如何联系我们】约定的联系方式联系我们。</b>
      </p>
      <h2>七、本政策的适用以及更新</h2>
      <p><b>
        为了给您提供更好的服务，我们可能会根据HorseOn
        Living服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分。我们会在本页面上公布对本政策所作的任何变更。对于重大变更，我们还会提供更为显著的通知（包括网站公告、推送通知、弹窗提示或其他方式），本政策所指的重大变更包括但不限于：</b>
        <br />
        1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        <br />
        2、我们在所有权结构方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        <br />
        3、个人信息共享、转让或公开披露的主要对象发生重大变化； <br />
        4、您参与个人信息处理方面的权利及其行使方式发生重大变化； <br />
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生重大变化；
        <br />
        6、其他重要的或可能严重影响您的个人权益的情况发生。 <br />
        我们的任何修改都会将您的满意度置于首位。我们鼓励您在每次使用HorseOn
        Living服务时都查阅我们的隐私政策。<br />
      </p>
      <h2>八、如何联系我们</h2>
      <p>
        如您有关于个人信息安全的投诉和举报，或您对本隐私政策有任何疑问、意见或建议，可以通过以下方式与我们联系：邮箱：info@horseon.hk，或写信至以下地址【香港-鰂鱼涌-英皇道734号-友邦香港大楼-3楼-302室-马上有限公司】反映。受理您的问题后，我们会及时、妥善处理。一般情况下，我们将在收到您的问题、意见或建议之日起十五天内予以回复。
      </p>
      <p>
        请您在点击“同意”之前仔细阅读本隐私政策，确保对其内容特别是字体加粗内容的含义及相应法律后果已全部知晓并充分理解。您点击“同意”即视为您接受本隐私政策，我们将按照相关法律法规及本政策来合法使用和保护您的个人信息。
      </p>
    </div>
  </div>
</template>

<script>
export default {
    async created() {
    document.title = "HorseOn Living 官网及小程序隐私条款";
  },
}
</script>

<style lang="scss">
.privacy {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 20px 50px 20px;
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .text {
    line-height: 1.8;
  }
}
</style>
