<template>
  <div class="privacy">
    <div class="text">
      <h1>HorseOn Living应用权限申请与使用情况说明</h1>

      <p>
        为保证HorseOn
        Living相关功能的实现与应用安全稳定的运营，我们可能会在您使用该该业务功能时向您申请下列与个人信息相关的系统权限。如果您不授权，将会导致我们无法提供该业务功能。我们将按照<a
          href="/privacy"
          >《HorseOn Living官网及小程序隐私条款》</a
        >的规定，采取严格的保密及安全措施，以保障您的个人信息安全。以下是我们可能申请、使用的相关操作系统权限，该内容可能会随着HorseOn
        Living官网及小程序的版本升级而有所变化，我们将及时这些变动进行调整，以确保您及时获悉这些情况。HorseOn
        Living官网及小程序应用权限列表具体如下：
      </p>
      <table border="1">
        <tr>
          <th width="20%">权限描述</th>
          <th width="40%">使用场景/目的</th>
          <th width="40%">使用平台（Android/IOS）</th>
        </tr>
        <tr>
          <th>摄像头权限</th>
          <td>使您可以使用摄像头进行扫码、拍摄，用于实现登录、使用“拍照”功能支付、图片评论及上传头像</td>
          <td>Android/iOS（注：在Android端，因上传头像需压缩、裁剪头像图片，您需要同时开启拍照权限与相册权限以实现上传头像）</td>
        </tr>
        <tr>
          <th>相册权限</th>
          <td>使您可以实现您设备中的照片、图片或视频的取用与上传、发表评论/分享、使用相册中的图片上传头像</td>
          <td>Android/iOS</td>
        </tr>
        <tr>
          <th>电话/设备权限</th>
          <td>使您可以实现一键登录、安全保障等功能</td>
          <td>Android</td>
        </tr>
        <tr>
          <th>存储权限</th>
          <td>使您可以实现裁剪头像图片、崩溃日志查询功能</td>
          <td>Android/iOS</td>
        </tr>
        <tr>
          <th>视频缓存文件访问权限</th>
          <td>当您观看平台内提供的视频时，有可能会生成视频缓存文件，我们会申请访问该视频缓存文件，是为了使您更为流畅的使用体验</td>
          <td>Android/iOS</td>
        </tr>
        <tr>
          <th>剪切板内容权限</th>
          <td>使您可以使用剪切板复制粘贴内容参与发布信息/搜索信息</td>
          <td>Android/iOS</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
    async created() {
    document.title = "HorseOn Living应用权限申请与使用情况说明";
  },
}
</script>

<style lang="scss">
table {
    border-collapse: collapse;
    th {
        padding: 8px 12px;
    }
    td {
        padding: 8px 12px;
    }
}
</style>