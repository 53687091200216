<template>
  <div class="scrollContainer">
    <Header />
    <div class="vsTitle">房源VS</div>
    <div class="content vsPage">
      <div class="vsTable">
        <div class="room tableLine">
          <div class="tableLeft large">房源</div>
          <div class="tableItem">
            <template v-if="roomData1">
              <div class="roomCover">
                <img
                  v-if="roomData1.imageList && roomData1.imageList.length"
                  class="imgCut"
                  :src="roomData1.imageList[0]"
                  alt=""
                />
              </div>
              <div class="roomTitle">
                {{ roomData1.houseTitle }}&nbsp; | &nbsp;{{ roomData1.title }}
              </div>
            </template>
            <div v-else>-</div>
            <!-- <div class="changeBtn">更换房源</div> -->
          </div>
          <div class="tableItem">
            <template v-if="roomData2">
              <div class="roomCover">
                <img
                  v-if="roomData2.imageList && roomData2.imageList.length"
                  class="imgCut"
                  :src="roomData2.imageList[0]"
                  alt=""
                />
              </div>
              <div class="roomTitle">
                {{ roomData2.houseTitle }}&nbsp; | &nbsp;{{ roomData2.title }}
              </div>
            </template>
            <div v-else>-</div>
            <!-- <div class="changeBtn">选择房源</div> -->
          </div>
          <div class="tableItem">
            <template v-if="roomData3">
              <div class="roomCover">
                <img
                  v-if="roomData3.imageList && roomData3.imageList.length"
                  class="imgCut"
                  :src="roomData3.imageList[0]"
                  alt=""
                />
              </div>
              <div class="roomTitle">
                {{ roomData3.houseTitle }} | {{ roomData3.title }}
              </div>
            </template>
            <!-- <div class="changeBtn">选择房源</div> -->
            <div v-else>-</div>
          </div>
        </div>
        <div class="tableLine">
          <div class="tableLeft">{{ $t("价格") }}</div>
          <div class="tableItem">
            <template v-if="roomData1">
              HKD {{ roomData1.price }} / {{ roomData1.payTimeUnit }}
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData2">
              HKD {{ roomData2.price }} / {{ roomData2.payTimeUnit }}
            </template>
            <div v-else>-</div>
          </div>

          <div class="tableItem">
            <template v-if="roomData3 && roomData3 != {}">
              HKD {{ roomData3.price }} / {{ roomData3.payTimeUnit }}
            </template>
            <div v-else>-</div>
          </div>
        </div>
        <div class="tableLine">
          <div class="tableLeft">{{ $t("面积") }}</div>
          <div class="tableItem">
            <template v-if="roomData1">
              {{ roomData1.area }}
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData2">
              {{ roomData2.area }}
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData3">
              {{ roomData3.area }}
            </template>
            <div v-else>-</div>
          </div>
        </div>
        <div class="tableLine">
          <div class="tableLeft">{{ $t("地理区域") }}</div>
          <div class="tableItem">
            <template v-if="roomData1">
              {{ roomData1.houseRegion }} {{ roomData1.houseDistrict }}
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData2">
              {{ roomData2.houseRegion }} {{ roomData2.houseDistrict }}
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData3">
              {{ roomData3.houseRegion }} {{ roomData3.houseDistrict }}
            </template>
            <div v-else>-</div>
          </div>
        </div>
        <div class="tableLine">
          <div class="tableLeft">{{ $t("详细地址") }}</div>
          <div class="tableItem">
            <template v-if="roomData1">
              {{ roomData1.houseAddress }}
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData2">
              {{ roomData2.houseAddress }}
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData3">
              {{ roomData3.houseAddress }}
            </template>
            <div v-else>-</div>
          </div>
        </div>
        <div class="tableLine large tableHead">{{ $t("房内基础设施") }}</div>
        <div class="tableLine" v-for="item in baseFacility" :key="item.value">
          <div class="tableLeft">{{ $t(item.label) }}</div>
          <div class="tableItem">
            <template v-if="roomData1">
              <img
                v-if="roomData1.baseFacility[item.value] == 1"
                class="trueIcon"
                src="@/assets/checked.png"
                alt=""
              />
              <img
                :class="{
                  nullFacility: roomData1.baseFacility[item.value] == 0,
                }"
                v-else
                class="trueIcon"
                src="@/assets/unchecked.png"
                alt=""
              />
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData2">
              <img
                v-if="roomData2.baseFacility[item.value] == 1"
                class="trueIcon"
                src="@/assets/checked.png"
                alt=""
              />
              <img
                :class="{
                  nullFacility: roomData2.baseFacility[item.value] == 0,
                }"
                v-else
                class="trueIcon"
                src="@/assets/unchecked.png"
                alt=""
              />
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData3">
              <img
                v-if="roomData3.baseFacility[item.value] == 1"
                class="trueIcon"
                src="@/assets/checked.png"
                alt=""
              />
              <img
                :class="{
                  nullFacility: roomData3.baseFacility[item.value] == 0,
                }"
                v-else
                class="trueIcon"
                src="@/assets/unchecked.png"
                alt=""
              />
            </template>
            <div v-else>-</div>
          </div>
        </div>
        <div class="tableLine large tableHead">{{ $t("楼宇设施/服务") }}</div>
        <div
          class="tableLine"
          v-for="item in serviceFacility"
          :key="item.value"
        >
          <div class="tableLeft">{{ $t(item.label) }}</div>
          <div class="tableItem">
            <template v-if="roomData1">
              <img
                v-if="roomData1.serviceFacility[item.value] == 1"
                class="trueIcon"
                src="@/assets/checked.png"
                alt=""
              />
              <img
                :class="{
                  nullFacility: roomData1.serviceFacility[item.value] == 0,
                }"
                v-else
                class="trueIcon"
                src="@/assets/unchecked.png"
                alt=""
              />
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData2">
              <img
                v-if="roomData2.serviceFacility[item.value] == 1"
                class="trueIcon"
                src="@/assets/checked.png"
                alt=""
              />
              <img
                :class="{
                  nullFacility: roomData2.serviceFacility[item.value] == 0,
                }"
                v-else
                class="trueIcon"
                src="@/assets/unchecked.png"
                alt=""
              />
            </template>
            <div v-else>-</div>
          </div>
          <div class="tableItem">
            <template v-if="roomData3">
              <img
                v-if="roomData3.serviceFacility[item.value] == 1"
                class="trueIcon"
                src="@/assets/checked.png"
                alt=""
              />
              <img
                :class="{
                  nullFacility: roomData3.serviceFacility[item.value] == 0,
                }"
                v-else
                class="trueIcon"
                src="@/assets/unchecked.png"
                alt=""
              />
            </template>
            <div v-else>-</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      keywords: "",
      houseType: 0,
      baseFacility: [
        {
          label: "WIFI",
          value: "wifi",
        },
        {
          label: "冰箱",
          value: "fridge",
        },
        {
          label: "冷气",
          value: "cooling",
        },
        {
          label: "暖气",
          value: "heating",
        },
        {
          label: "洗衣机",
          value: "washer",
        },
        {
          label: "衣柜",
          value: "wardrobe",
        },
        {
          label: "书桌",
          value: "desk",
        },
        {
          label: "沙发",
          value: "sofa",
        },
        {
          label: "电吹风",
          value: "hairDryer",
        },
        {
          label: "电视",
          value: "television",
        },
        {
          label: "智能门锁",
          value: "intelligentLock",
        },
        {
          label: "独立卫浴",
          value: "bathroom",
        },
        {
          label: "独立厨房",
          value: "kitchen",
        },
        {
          label: "热水壶",
          value: "kettle",
        },
        {
          label: "电磁炉",
          value: "inductionCooker",
        },
      ],
      serviceFacility: [
        {
          label: "共享厨房",
          value: "sharedKitchen",
        },
        {
          label: "共享自习室",
          value: "sharedStudyRoom",
        },
        {
          label: "对讲门禁",
          value: "intercom",
        },
        {
          label: "保安",
          value: "securityGuard",
        },
        {
          label: "健身房",
          value: "gym",
        },
        {
          label: "清洁服务",
          value: "cleanService",
        },
      ],
      roomData1: null,
      roomData2: null,
      roomData3: null,
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    document.title = "房源VS - 马上住 HorseOnLiving";
    if (this.$route.query.id1 && this.$route.query.id1 != "null") {
      console.log(this.$route.query.id1);
      this.getRoomInfo(this.$route.query.id1, 1);
    }
    if (this.$route.query.id2 && this.$route.query.id2 != "null") {
      this.getRoomInfo(this.$route.query.id2, 2);
    }
    if (this.$route.query.id3 && this.$route.query.id3 != "null") {
      this.getRoomInfo(this.$route.query.id3, 3);
    }
  },
  methods: {
    async getRoomInfo(id, index) {
      const roomInfoRes = await request.get("/webapi/roomType/detail", {
        params: {
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
          roomTypeId: id,
        },
      });
      if (roomInfoRes.code == 200) {
        if (index == 1) {
          this.roomData1 = roomInfoRes.data;
          return;
        } else if (index == 2) {
          this.roomData2 = roomInfoRes.data;
          return;
        } else if (index == 3) {
          this.roomData3 = roomInfoRes.data;
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.isMobile {
  .vsTitle {
    font-size: 24px;
  }
}
.vsPage.content {
    margin-top: 30px;
  }
.vsTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  margin-top: 80px;
}
.vsTable {
  margin: 0 auto;
  max-width: 1134px;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  width: 1134px;
  div {
    box-sizing: border-box;
  }
  .tableLine {
    display: flex;
    min-height: 45px;
  }
  .large {
    font-size: 20px;
  }
  .tableLeft {
    width: 172px;
    border-left: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-left: 27px;
    flex-shrink: 0;
  }
  .tableItem {
    border-left: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    width: 321px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    flex-shrink: 0;
    .trueIcon {
      width: 20px;
      height: 20px;
    }
  }

  .room {
    .tableLeft {
      height: 337px;
    }
    .roomCover {
      border-radius: 4px;
      overflow: hidden;
      width: 260px;
      height: 209px;
      margin-bottom: 12px;
    }
    .roomTitle {
      font-weight: 700;
      font-size: 16px;
    }
    .changeBtn {
      padding: 4px 15px;
      border: 1px solid #000000;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .tableHead {
    border-left: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    padding-left: 27px;
    align-items: center;
    font-weight: 500;
    width: 1134px;
  }
}
.isMobile {
  .vsTable {
    width: 636px;
    .tableLeft {
      width: 96px;
      padding-left: 12px;
    }
    .tableItem {
      width: 180px;
    }
    .room {
      .roomCover {
        width: 156px;
        height: 125px;
      }
      .tableLeft {
        height: 220px;
      }
    }
    .tableHead {
      width: 636px;
      padding-left: 12px;
    }
  }
}
</style>