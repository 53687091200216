import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Demo from '../views/Demo.vue';
import Houses from '../views/Houses.vue';
import Jobs from '../views/Jobs.vue';
import LifeHelper from '../views/LifeHelper.vue';
import Login from '../views/Login.vue';
import HousesDet from '../views/HousesDet.vue';
import JobsDet from '../views/JobsDet.vue';
import RoomDet from '../views/RoomDet.vue';
import Personal from '../views/Personal.vue';
import VsPage from '../views/VsPage.vue';
import Privacy from '../views/Privacy.vue';
import Attachment from '../views/Attachment.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
  },
  {
    path: '/houses',
    name: 'Houses',
    component: Houses,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
  },
  {
    path: '/lifehelper',
    name: 'LifeHelper',
    component: LifeHelper,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/houses/detail',
    name: 'HousesDet',
    component: HousesDet,
  }, 
  {
    path: '/jobs/detail',
    name: 'JobsDet',
    component: JobsDet,
  },
  {
    path: '/houses/room/detail',
    name: 'RoomDet',
    component: RoomDet,
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,
  },
  {
    path: '/houses/vs',
    name: 'VsPage',
    component: VsPage,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/privacy/attachment',
    name: 'Attachment',
    component: Attachment,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
