<template>
  <div class="helperItem" v-if="item">
    <div class="helperTitleLine">
      <div class="helperLabel">{{ item.type }}</div>
      <div class="helperTitle">{{ item.title }}</div>
      <div class="likeBtn" @click="clickCollect()">
        <img v-if="!item.isCollected" src="@/assets/like.png" alt="" />
        <img v-else src="@/assets/like_yellow.png" alt="" />
      </div>
    </div>
    <div
      class="helperDes"
      :class="{ textOverflowInitial: isOverflow && isFold }"
      ref="desc"
    >
      {{ item.description }}
    </div>
    <div v-if="isOverflow" class="moreBtn" @click="clickFold">
      {{ isFold ? "查看更多" : "收起" }}
    </div>
    <div class="company">
      <div class="companyImg">
        <img
          v-if="item.companyAvatar"
          class="imgCut"
          :src="item.companyAvatar"
          alt=""
        />
        <img v-else class="imgCut" src="@/assets/company.png" alt="" />
      </div>
      <div class="companyName">{{ item.companyName }}</div>
    </div>
    <div class="helperContact">
      <img src="@/assets/tele_black.png" alt="" />{{ item.phone }}
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      isOverflow: false,
      targetHeight: 64, // 3行对应的高度
      isFold: true,
      isCollect: false,
    };
  },
  props: ["item"],
  mounted() {
    this.$nextTick(() => {
      // console.log("height: ", this.$refs.desc.clientHeight);
      const height = this.$refs.desc.clientHeight;
      this.isOverflow = height > this.targetHeight;
    });
  },
  methods: {
    async clickFold() {
      console.log('点了',this.isFold)
      // 调接口用于浏览记录
      if (this.isFold) {
        const detailRes = await request.get("/webapi/liveHelper/detail", {
        params: {
          liveHelperId: this.item.id,
        },
      });
      if (detailRes.code == 200) {
        console.log('浏览了该生活助手')
      }
      }
      this.isFold = !this.isFold;
    },
    async clickCollect() {
      // if (!isLogin) {
      //   return this.$message({
      //     message: "当前未登录，请登录后再操作",
      //   });
      // }
      const collectRes = await request.get("/webapi/userCollect/collect", {
        params: {
          ht: 4,
          htdId: this.item.id,
        },
      });
      if (collectRes.code == 200) {
        this.item.isCollected = !this.item.isCollected;
      }
    },
  }
};
</script>

<style lang="scss">
.likeBtn {
  margin-left: auto;
  cursor: pointer;
  width: 24px;
  height: 24px;
  img {
    width: 24px;
    transition: transform 0.15s;
  }
  img:hover {
    transform: scale(1.2);
  }
}
.helperItem {
  width: 100%;
  max-width: 1032px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin: 0 auto 20px auto;
  background: #fff;
  box-sizing: border-box;
  padding: 17px 32px 18px 30px;
  line-height: 1.5;
  position: relative;
  .helperTitleLine {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .helperLabel {
      font-size: 13px;
      padding: 4px 6px 3px 6px;
      background: rgba(255, 192, 62, 0.2);
      border-radius: 2px;
      margin-right: 6px;
      flex-shrink: 0;
    }
    .helperTitle {
      font-weight: 700;
      font-size: 20px;
    }
  }
  .helperDes {
    font-size: 14px;
    line-height: 150%;
    white-space: pre-line;
    margin-bottom: 6px;
    min-height: 48px;
  }
  .moreBtn {
    font-size: 14px;
    color: #0071c2;
    cursor: pointer;
    margin-bottom: 16px;
  }
  .helperContact {
    position: absolute;
    right: 0;
    bottom: 14px;
    padding: 6px 10px 6px 16px;
    background: #ffc03e;
    border-radius: 22px 0 0 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}
// .textOverflowInitial {
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   height: auto;
// }
.textOverflowInitial {
  overflow: hidden;
  height: 63px;
}
</style>

<style lang="scss">
.isMobile {
  .helperItem {
    width: 95%;
    padding: 13px;
    margin-bottom: 12px;
    .helperDes {
      margin-bottom: 6px;
    }
    .helperTitleLine {
      margin-bottom: 4px;
      .helperLabel {
        font-size: 12px;
        padding: 2px 4px;
      }
      .helperTitle {
        font-size: 15px;
      }
    }
    .moreBtn {
      margin-bottom: 10px;
    }
    .helperContact {
      font-size: 12px;
      padding: 5px 7px 4px 9px;
      bottom: 12px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>