<template>
  <div class="jobItem" @click="openJobs(item.id)" v-if="item">
    <div class="jobTitleLine">
      <div class="houseType">{{ item.workForm }}</div>
      <div class="jobTitle textOverflow">
        {{ item.title }}
      </div>
      <div class="likeBtn" @click.stop="clickCollect">
        <img v-if="!item.isCollected" src="@/assets/like.png" alt="" />
        <img v-else src="@/assets/like_yellow.png" alt="" />
      </div>
    </div>
    <div class="jobDes textOverflow">
      {{ item.description }}
    </div>
    <div class="company">
      <div class="companyImg">
        <img
          v-if="item.companyAvatar"
          class="imgCut"
          :src="item.companyAvatar"
          alt=""
        />
        <img v-else class="imgCut" src="@/assets/company.png" alt="" />
      </div>
      <div class="companyName">{{ item.companyName }}</div>
      <div class="jobDate">{{ item.createDate }}</div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
    };
  },
  methods: {
    openJobs(id) {
      window.open(`/jobs/detail?lan=${this.$i18n.locale}&id=${id}`);
    },
    async clickCollect() {
      const collectRes = await request.get("/webapi/userCollect/collect", {
        params: {
          ht: 3,
          htdId: this.item.id,
        },
      });
      if (collectRes.code == 200) {
        this.item.isCollected = !this.item.isCollected;
      }
    },
  },
  props: ["item"],
};
</script>

<style lang="scss">
.jobItem:hover {
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
}
.jobItem {
  width: 100%;
  max-width: 1032px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin: 0 auto 20px auto;
  cursor: pointer;
  background: #fff;
  box-sizing: border-box;
  padding: 17px 32px 14px 30px;
  line-height: 1.5;
  .jobTitleLine {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .jobTitle {
      font-weight: 700;
      font-size: 20px;
      width: 90%;
    }
    .jobTitle.textOverflow {
      -webkit-line-clamp: 1;
    }
  }
  .jobDes {
    margin-bottom: 10px;
  }
}
</style>


<style lang="scss">
// 移动端样式
.isMobile {
  .el-menu-item,
  .el-submenu__title {
    padding: 0 4px;
  }
  .el-submenu__title {
    font-size: 13px;
  }
  .jobItem {
    width: 95%;
    padding: 13px 13px 10px 13px;
    margin-bottom: 12px;
    .jobTitleLine {
      margin-bottom: 5px;
      .jobTitle {
        font-size: 15px;
        height: 24px;
      }
    }
    .jobDes {
      font-size: 14px;
      height: 42px;
    }
    .jobDes.textOverflow {
      -webkit-line-clamp: 2;
    }
  }
  .el-menu--popup {
    min-width: 100px;
  }
}
</style>