<template>
  <el-dialog
    class="horseOn rateDialog"
    :title="title"
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
  >
    <div class="averageScore">
      <el-rate
        v-model="score"
        disabled
        text-color="#2eaac1"
        score-template="{value}"
        :colors="colors"
        disabled-void-color="#dcdcdc"
      >
      </el-rate>
      <div class="totalRate">
        {{ scoreStr }}
        （{{ commentNum ? commentNum : "暂无评价" }}）
      </div>
    </div>
    <div class="rateItem">
      <div class="rateType">清洁</div>
      <div class="rateStar">
        <el-rate
          v-model="cleanScore"
          :colors="colors"
          void-color="#2eaac1"
        ></el-rate>
      </div>
    </div>
    <div class="rateItem">
      <div class="rateType">沟通</div>
      <div class="rateStar">
        <el-rate
          v-model="communicationScore"
          :colors="colors"
          void-color="#2eaac1"
        ></el-rate>
      </div>
    </div>
    <div class="rateItem">
      <div class="rateType">地理位置</div>
      <div class="rateStar">
        <el-rate
          v-model="locationScore"
          :colors="colors"
          void-color="#2eaac1"
        ></el-rate>
      </div>
    </div>
    <div class="rateItem">
      <div class="rateType">描述准确度</div>
      <div class="rateStar">
        <el-rate
          v-model="descriptionScore"
          :colors="colors"
          void-color="#2eaac1"
        ></el-rate>
      </div>
    </div>
    <div class="commentArea">
      <el-input
        style="height: 130px"
        v-model="commentText"
        clearable
        type="textarea"
        spellcheck="false"
        resize="none"
      />
    </div>
    <div class="submitBtn" @click="clickSubmit">发布</div>
  </el-dialog>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      contentVisible: false,
      cleanScore: null,
      communicationScore: null,
      locationScore: null,
      descriptionScore: null,
      colors: ["#2eaac1", "#2eaac1", "#2eaac1"],
      commentText: "",
      loading: false,
    };
  },
  methods: {
    async clickSubmit() {
      if (this.loading) {
        return;
      }
      if (
        !this.cleanScore ||
        !this.communicationScore ||
        !this.descriptionScore ||
        !this.locationScore ||
        !this.commentText
      ) {
        return this.$message({
          message: "请填写各项评分及文字评价",
        });
      }
      this.loading = true;
      const submitRes = await request.post("/webapi/comment/submit", {
        cleanScore: this.cleanScore,
        communicationScore: this.communicationScore,
        content: this.commentText,
        descriptionScore: this.descriptionScore,
        houseId: this.id,
        locationScore: this.locationScore,
      });
      this.loading = false;
      if (submitRes.code == 200) {
        this.contentVisible = false;
        // location.reload();
      }
    },
  },
  props: ["title", "scoreStr", "commentNum", "id", "score"],
};
</script>

<style lang="scss">
.averageScore {
  margin-bottom: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  .totalRate {
    color: #2eaac1;
  }
  .el-rate__icon {
    font-size: 26px;
  }
}
.horseOn.rateDialog {
  .el-dialog__header {
    padding-top: 37px;
  }
  .el-dialog__body {
    padding: 6px 35px;
  }

  .rateItem {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .rateType {
      width: 80px;
    }
    .rateStar {
      margin-left: auto;
    }
  }
  .el-rate {
    height: auto;
  }
  .el-rate__icon {
    font-size: 22px;
  }
  .commentArea {
    margin: 10px 0 20px;
    .el-textarea__inner:focus {
      border-color: #2eaac1;
    }
    textarea {
      height: 120px !important;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
    }
  }
}
</style>