<template>
  <el-dialog
    class="horseOn"
    :title="`${$t('预订')} ${houseTitle}  |  ${roomTitle}`"
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
  >
    <template v-if="!submited">
      <div class="inputTitle">{{$t('输入个人信息')}}</div>
      <el-form :rules="rules" ref="ruleForm" :model="form">
        <el-form-item :label="$t('姓名')" prop="name">
          <el-input v-model="form.name" :disabled="formDisabled"></el-input>
        </el-form-item>
        <el-form-item :label="$t('电话')" prop="phone">
          <el-input
            v-model="form.phone"
            :disabled="formDisabled"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="submitBtn" @click="clickSubmit">提交</div>
      </span>
    </template>
    <template v-else>
      <div class="inputTitle">{{$t('预订优惠码')}}</div>
      <div class="code">
        {{ promoCode }}
        <div class="copyBtn" @click="copy(promoCode)">{{$t('复制')}}</div>
      </div>
      <div class="codeTips">
        <img v-if="promoCodeRule" src="@/assets/tips.png" alt="" />
        {{ promoCodeRule }}
      </div>
      <div class="hotelLink" v-if="bookUrl">
        <span class="bookUrl" @click="openUrl(bookUrl)">{{$t('点击此处')}}</span>{{$t('跳转至酒店官网进行预定')}}
      </div>
      <div class="submitBtn" @click="clickSubmit">{{$t('确定')}}</div>
    </template>
  </el-dialog>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      contentVisible: false,
      form: {
        name: "",
        phone: "",
      },
      rules: {
        name: [{ required: true, message: "请输入你的姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入你的电话", trigger: "blur" }],
      },
      formDisabled: false,
      submited: false,
    };
  },
  props: [
    "houseTitle",
    "roomTitle",
    "houseId",
    "roomId",
    "promoCodeRule",
    "promoCode",
    "bookUrl",
  ],
  computed: {
    ...mapGetters(["name", "phone", "isLogin"]),
  },
  watch: {
    name: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下用户信息
        if (!oldVal && val) {
          this.form.name = this.name;
          this.form.phone = this.phone;
        }
      },
    },
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
    async clickSubmit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          const submitRes = await request.get("/webapi/roomType/book", {
            params: {
              phone: this.form.phone,
              roomTypeId: this.roomId,
              name: this.form.name,
            },
          });
          if (submitRes.code == 200) {
            this.$message.success("提交成功，已获取优惠码");
            this.submited = true;
          }
        } else {
          console.log("校验不通过！error submit!!");
          return;
        }
      });
    },
    copy (text) {
      // text是复制文本
      // 创建input元素
      const el = document.createElement('input')
      // 给input元素赋值需要复制的文本
      el.setAttribute('value', text)
      // 将input元素插入页面
      document.body.appendChild(el)
      // 选中input元素的文本
      el.select()
      // 复制内容到剪贴板
      document.execCommand('copy')
      // 删除input元素
      document.body.removeChild(el)
      this.$message('复制成功')
    },
  }
};
</script>

<style lang="scss">
.bookUrl {
  text-decoration: underline;
  cursor: pointer;
  color: #2eaac1;
}
.horseOn {
  .el-dialog {
    width: 437px;
    height: 507px;
  }
  .el-dialog__header {
    padding: 60px 35px 0;
  }
  .el-dialog__title {
    font-weight: 700;
    font-size: 22px;
    color: #000;
    line-height: 1.3;
  }
  .el-dialog__body {
    padding: 26px 35px;
    color: #000;
    display: flex;
    flex-direction: column;
  }
  .el-dialog__footer {
    text-align: center;
    padding-bottom: 60px;
  }
  .submitBtn {
    padding: 10px 50px;
    background-color: #2eaac1;
    border-color: #2eaac1;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: inline-block;
    margin: 0 auto;
    border-radius: 4px;
    cursor: pointer;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #2eaac1;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #2eaac1;
  }
  .inputTitle {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .code {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 356px;
    height: 40px;
    margin-bottom: 10px;
    .copyBtn {
      position: absolute;
      right: 13px;
      top: 9px;
      padding: 2px 10px;
      background: #d9d9d9;
      border-radius: 32px;
      cursor: pointer;
    }
  }
  .codeTips {
    min-height: 90px;
    margin-bottom: 20px;
    color: #666;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    img {
      width: 16px;
      margin-right: 5px;
    }
  }
  .hotelLink {
    text-align: center;
    margin-bottom: 40px;
  }
}
</style>

<style lang="scss">
.isMobile {
  .horseOn {
    .el-dialog {
      width: 330px;
      height: 460px;
    }
    .el-dialog__header {
      padding: 50px 20px 0 20px;
    }
    .el-dialog__title {
      font-size: 18px;
    }
    .el-dialog__body {
      padding: 26px 20px;
    }
    .code {
      width: auto;
    }
  }
}
</style>