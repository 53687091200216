<template>
  <el-dialog
    class="horseOn confirm"
    title=""
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
    center
  >
    <div class="confirmTitle">确认要注销账号吗？<br />注销后不能恢复，账号内所有数据都将被删除</div>
    <div class="confirmBtnLine">
      <div class="confirmBtn no" @click="contentVisible=false">取消</div>
      <div class="confirmBtn yes" @click="handleLogOff">确认</div>
    </div>
  </el-dialog>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      contentVisible: false,
    };
  },
  methods: {
    async handleLogOff() {
      const indexInfoRes = await request.get("/webapi/user/logoff", {});
      if (indexInfoRes.code == 200) {
        await this.$store.dispatch('user/LogOut')
        window.location.href = `/?lan=${this.$i18n.locale}`;
      }
    }
  },
};
</script>
