<template>
  <div class="scrollContainer">
    <Header />
    <div class="filterBlock">
      <div class="filter">
        <div class="filterType">{{ $t("高级筛选") }}</div>
        <div class="filterItemList">
          <el-menu
            :default-active="jobKind.value"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect1"
            :menu-trigger="isMobile ? 'click' : 'hover'"
          >
            <el-submenu index="1">
              <template slot="title">{{ jobKind.label }}</template>
              <el-menu-item
                v-for="item in jobKindList"
                :key="item.value"
                :index="item.value"
                >{{ $t(item.label) }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
          <el-menu
            default-active=""
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2"
            :menu-trigger="isMobile ? 'click' : 'hover'"
            :unique-opened="true"
          >
            <el-submenu index="2" :popper-append-to-body="true">
              <template slot="title">{{ jobType.label }}</template>
              <el-menu-item index="">不限</el-menu-item>
              <el-submenu
                v-for="item in jobTypeList"
                :key="item.value"
                :index="item.value"
                :unique-opened="true"
                :popper-append-to-body="true"
                popper-class="submenu-job-list"
              >
                <template slot="title">{{ item.label }}</template>
                <el-menu-item
                  v-for="childItem in item.childList"
                  :key="childItem.value"
                  :index="childItem.value"
                  >{{ childItem.label }}</el-menu-item
                >
              </el-submenu>
            </el-submenu>
          </el-menu>
          <el-menu
            :default-active="salaryRange.value"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect3"
            :menu-trigger="isMobile ? 'click' : 'hover'"
          >
            <el-submenu index="3">
              <template slot="title">{{ salaryRange.label }}</template>
              <el-menu-item
                v-for="item in salaryRangeList"
                :key="item.value"
                :index="item.value"
                >{{ item.label }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
    <template v-if="jobList && jobList.length">
      <div class="itemList lifeHelper">
        <JobItem v-for="item in jobList" :key="item.id" :item="item" />
      </div>
      <div class="pageLine">
        <el-pagination
          :page-size="10"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="pageNum"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </template>
    <template v-else>
      <div class="itemList empty lifeHelper">
        {{ loading ? $t("加载中...") : "没有查询到符合条件的工作" }}
      </div>
    </template>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import JobItem from "@/components/JobItem.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      keywords: "",
      houseType: 0,
      activeIndex: "1",
      jobKind: {
        label: "求职类型",
        value: "1",
      },
      salaryRange: {
        label: "月薪区间",
        value: "1",
        startSalary: null,
        endSalary: null,
      },
      jobKindList: [
        {
          label: "不限",
          value: "1",
        },
        {
          label: "全职",
          value: "2",
        },
        {
          label: "兼职",
          value: "3",
        },
        {
          label: "实习",
          value: "4",
        },
      ],
      salaryRangeList: [
        {
          label: "不限",
          value: "1",
          startSalary: null,
          endSalary: null,
        },
        {
          label: "10k以内",
          value: "2",
          startSalary: 0,
          endSalary: 10000,
        },
        {
          label: "10k-20k",
          value: "3",
          startSalary: 10001,
          endSalary: 20000,
        },
        {
          label: "20k-50k",
          value: "4",
          startSalary: 20001,
          endSalary: 50000,
        },
        {
          label: "50k-100k",
          value: "5",
          startSalary: 50001,
          endSalary: 100000,
        },
        {
          label: "100k以上",
          value: "6",
          startSalary: 100001,
          endSalary: null,
        },
      ],
      jobType: {
        label: "职位类型",
        value: "",
      },
      jobTypeList: [],
      jobKindMap: {
        1: "",
        2: "全职",
        3: "兼职",
        4: "实习",
      },
      jobList: [],
      pageNum: 1,
      total: 0,
      loading: false,
      keyword: "",
    };
  },
  components: {
    Header,
    Footer,
    JobItem,
  },
  async created() {
    document.title = "找工作 - 马上住 HorseOnLiving";
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    this.getJobTypeList();
    this.getJobList();
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;

      this.getJobList();
      console.log(`当前页: ${val}`);
    },
    async getJobTypeList() {
      const jobTypeRes = await request.get("/webapi/job/getSearchParam", {
        params: {
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        }
      });
      if (jobTypeRes.code == 200) {
        this.jobTypeList = jobTypeRes.data.positionList;
      }
    },
    async getJobList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      console.log(this.jobKindMap, this.jobKind.value);
      const jobListRes = await request.get("/webapi/job/list", {
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
          workForm: this.jobKindMap[this.jobKind.value],
          startSalary: this.salaryRange.startSalary,
          endSalary: this.salaryRange.endSalary,
          title: this.keyword,
          position: this.jobType.value,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        },
      });
      this.loading = false;
      if (jobListRes.code == 200) {
        this.jobList = jobListRes.data.records;
        this.total = jobListRes.data.total;
      }
    },
    handleSelect1(key, keyPath) {
      if (keyPath[0] == "1") {
        if (keyPath[1] != 1) {
          // this.jobKind = this.jobKindList[key - 1];
          this.jobKind = this.jobKindList.find(
            (item) => item.value == keyPath[1]
          );
        } else
          this.jobKind = {
            label: "求职类型",
            value: "1",
          };
      }
      this.pageNum = 1;
      this.getJobList();
      console.log("key", key, "keyPath", keyPath);
    },
    handleSelect2(key, keyPath) {
      console.log("key", key, "keyPath", keyPath);
      if (keyPath[0] == "2") {
        if (keyPath[1] != "") {
          // this.jobType = this.jobTypeList[key - 1];
          this.jobType = this.jobTypeList
            .find((item) => item.value == keyPath[1])
            .childList.find((childItem) => childItem.value == keyPath[2]);
        } else
          this.jobType = {
            label: "职位类型",
            value: "",
          };
      }
      this.pageNum = 1;
      this.getJobList();
      
    },
    handleSelect3(key, keyPath) {
      if (keyPath[0] == "3") {
        if (keyPath[1] != 1) {
          // this.salaryRange = this.salaryRangeList[key - 1];
          this.salaryRange = this.salaryRangeList.find(
            (item) => item.value == keyPath[1]
          );
        } else
          this.salaryRange = {
            label: "月薪区间",
            value: "1",
            startSalary: null,
            endSalary: null,
          };
      }
      this.pageNum = 1;
      this.getJobList();
      console.log("key", key, "keyPath", keyPath);
    },
  },
};
</script>


<style lang="scss">
// 第三方组件样式
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 24px;
  line-height: 1.5;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #333;
  border: none !important;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #000;
  padding: 0 16px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #2eaac1;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
  color: #2eaac1;
}
.el-menu--popup {
  min-width: 160px;
  max-height: 330px;
  overflow: auto;
}
.el-submenu .el-menu-item {
  min-width: 160px;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border: none;
}

@media screen and (max-width: 500px) {
  .submenu-job-list {
    left: 0!important;
    width: 45vw;
  }
}
</style>

