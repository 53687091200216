<template>
  <el-dialog
    class="horseOn"
    :title="`预订 ${title}`"
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
  >
    <div class="inputTitle">联系方式</div>
    <div class="contactItem" v-if="phones"><img src="@/assets/tele.png" alt="" />{{phones}}</div>
    <div class="contactItem" v-if="emails"><img src="@/assets/email.png" alt="" />{{emails}}</div>
    <div class="personalCode">
      <img v-if="wxQrCode" :src="wxQrCode" alt="" />
      <div v-if="wxQrCode">微信二维码</div>
    </div>
    <div class="submitBtn" @click="contentVisible = false">确定</div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      contentVisible: false,
    };
  },
  props:["title","phones","emails","wxQrCode"],
  methods: {},
};
</script>

<style lang="scss">
.contactItem {
  color: #666;
  line-height: 1.5;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  img {
    width: 20px;
      margin-right: 8px;
  }
}
.horseOn {
  .personalCode {
    text-align: center;
    color: #666;
    line-height: 1.5;
    font-size: 12px;
    margin-bottom: 26px;
    min-height: 130px;
    img {
      width: 120px;
      height: 120px;
      background: #d9d9d9;
      margin: 10px;
    }
  }
}
</style>