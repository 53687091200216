<template>
  <div class="scrollContainer">
    <Header />
    <div class="banner">
      <el-carousel>
        <el-carousel-item v-for="item in 1" :key="item">
          <img class="imgCut" src="@/assets/bannerdemo.png" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="searchBar">
        <div class="searchType">
          <el-dropdown
            @command="handleCommand"
            trigger="click"
            :hide-timeout="0"
          >
            <span class="el-dropdown-link">
              {{ searchTypeMap[searchType]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Houses">房源</el-dropdown-item>
              <el-dropdown-item command="Jobs">工作</el-dropdown-item>
              <el-dropdown-item command="LifeHelper">生活助手</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <input
          type="text"
          placeholder="搜索..."
          v-model="keywords"
          @keyup.enter="clickSearch(searchType, keywords)"
          ref="searchBar"
        />
        <img
          @click="resetInput"
          class="searchIcon closeIcon"
          src="@/assets/close.png"
          alt=""
        />
        <img
          @click="clickSearch(searchType, keywords)"
          class="searchIcon"
          src="@/assets/search.png"
          alt=""
        />
      </div>
    </div>
    <div>
      <div class="hotBlock">
        <div class="newsLine">
          <div class="toAll" @click="toHouses(houseType)"><img src="@/assets/right.png" alt="" /></div>
          <div class="newsTypeItem" @click="toHouses(2)">
            <img class="imgCut" src="@/assets/house1.png" alt="" />
            <div class="newsTypeText">{{ $t("长租酒店") }}</div>
          </div>
          <div class="newsTypeItem" @click="toHouses(3)">
            <img class="imgCut" src="@/assets/house2.png" alt="" />
            <div class="newsTypeText">{{ $t("品质公寓") }}</div>
          </div>
          <div class="newsTypeItem" @click="toHouses(4)">
            <img class="imgCut" src="@/assets/house3.png" alt="" />
            <div class="newsTypeText">{{ $t("个人房源") }}</div>
          </div>
        </div>
      </div>
      <!-- 房源推荐 -->
      <div class="hotBlock" v-if="hotHousesList && hotHousesList.length">
        <div class="titleLine">
          <div class="blockTitle">{{ $t("热门房源推荐") }}</div>
          <div class="blockType">
            <div
              class="blockTypeItem"
              :class="{ selected: houseType === item.value }"
              v-for="(item, index) in houseTypeList"
              :key="index"
              @click="switchType('house', item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="hotHouse">
          <div class="toAll" @click="toHouses(houseType)">
            <img src="@/assets/right.png" alt="" />
          </div>
          <div
            class="hotHouseItem"
            v-for="item in hotHousesList"
            :key="item.id"
            @click="openHouses(item.id)"
          >
            <div class="houseCover">
              <img v-if="item.imageList" class="imgCut" :src="item.imageList[0]" alt="" />
            </div>
            <div class="houseName">{{ item.title }}</div>
            <div class="desLine" v-if="item.region && item.district">{{item.region}} · {{item.district}}</div>
            <!-- <div class="labelLine">
              <div
                class="labelItem"
                v-for="(label, idx) in item.tagList"
                :key="idx"
              >
                {{ label }}
              </div>
            </div> -->
            <div class="labelLine">
              <template v-if="item.locationList">
                <div
                  class="labelItem"
                  v-for="location in item.locationList"
                  :key="location"
                >
                  {{ location }}
                </div>
              </template>
              <template v-if="item.tagList">
                <div
                  class="labelItem"
                  v-for="label in item.tagList"
                  :key="label"
                >
                  {{ label }}
                </div>
              </template>
            </div>
            <div class="housePrice">
              HKD <span class="priceNum">{{ item.price }}</span> 起/{{
                item.payTimeUnit
              }}
            </div>
            <div class="houseScore">
              <div class="scoreNum">
                {{ item.score ? item.score : $t("暂无评分") }}
              </div>
              <div v-if="item.score">
                {{ item.commentNum }}{{ $t("条住客点评") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 工作机会 -->
      <div class="hotBlock" v-if="hotJobsList && hotJobsList.length">
        <div class="titleLine">
          <div class="blockTitle">{{ $t("最新工作机会") }}</div>
        </div>
        <div class="hotJob">
          <div class="toAll" @click="toJobs">
            <img src="@/assets/right.png" alt="" />
          </div>
          <div class="hotJobItem" v-for="item in hotJobsList" :key="item.id" @click="openJobs(item.id)">
            <div class="jobName textOverflow">
              {{ item.title }}
            </div>
            <div class="company">
              <div class="companyImg">
                <img
                  v-if="item.companyAvatar"
                  class="imgCut"
                  :src="item.companyAvatar"
                  alt=""
                />
                <img v-else class="imgCut" src="@/assets/company.png" alt="" />
              </div>
              <div class="companyName">{{item.companyName}}</div>
              <div class="jobDate">{{item.createDate}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 生活助手 -->
      <div class="hotBlock">
        <div class="titleLine">
          <div class="blockTitle">生活助手</div>
        </div>
        <div class="hotHelper">
          <div class="toAll" @click="toHelper(0)">
            <img src="@/assets/right.png" alt="" />
          </div>
          <div class="hotHelperItem" @click="toHelper(1)">
            <img class="imgCut" src="@/assets/helper1.png" alt="" />
            <div class="helperText" style="color: #0080ff">
              {{ $t("家政清洁") }}
            </div>
          </div>
          <div class="hotHelperItem" @click="toHelper(2)">
            <img class="imgCut" src="@/assets/helper2.png" alt="" />
            <div class="helperText" style="color: #ff7400">
              {{ $t("搬家运输") }}
            </div>
          </div>
          <div class="hotHelperItem" @click="toHelper(3)">
            <img class="imgCut" src="@/assets/helper3.png" alt="" />
            <div class="helperText" style="color: #00b96a">
              {{ $t("开锁维修") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      searchType: "Houses",
      searchTypeMap: {
        Houses: "房源",
        Jobs: "工作",
        LifeHelper: "生活助手",
      },
      keywords: "",
      houseType: 1,
      houseTypeList: [
        {
          value: 1,
          label: "全部",
        },
        {
          value: 2,
          label: "酒店",
        },
        {
          value: 3,
          label: "公寓",
        },
        {
          value: 4,
          label: "个人",
        },
      ],
      houseTypeMap: {
        1: "",
        2: "酒店",
        3: "公寓",
        4: "个人房源",
      },
      hotHousesList: [],
      hotJobsList: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    document.title = "首页 - 马上住 HorseOnLiving";
    this.getHousesList();
    this.getJobList();
  },
  methods: {
    resetInput() {
      this.keywords = "";
      this.$nextTick(() => {
        this.$refs.searchBar.focus();
      });
    },
    // 房源列表
    async getHousesList(type) {
      const housesListRes = await request.get("/webapi/house/list", {
        params: {
          pageNum: 1,
          pageSize: 12,
          type: type,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
          isTop: true,
        },
      });
      if (housesListRes.code == 200) {
        this.hotHousesList = housesListRes.data.records;
      }
    },
    // 工作列表
    async getJobList() {
      const jobListRes = await request.get("/webapi/job/list", {
        params: {
          pageNum: 1,
          pageSize: 3,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        },
      });
      if (jobListRes.code == 200) {
        this.hotJobsList = jobListRes.data.records;
      }
    },
    openHouses(id) {
      window.open(`/houses/detail?lan=${this.$i18n.locale}&id=${id}`);
    },
    openJobs(id) {
      window.open(`/jobs/detail?lan=${this.$i18n.locale}&id=${id}`);
    },
    handleCommand(command) {
      this.searchType = command;
    },
    switchType(block, value) {
      if (block == "house") {
        this.houseType = value;
        this.getHousesList(this.houseTypeMap[this.houseType]);
      }
    },
    toHelper(type) {
      if (type) {
        window.location.href = `/lifehelper/?lan=${this.$i18n.locale}&type=${
          type + 1
        }`;
      } else window.location.href = `/lifehelper/?lan=${this.$i18n.locale}`;
    },
    toJobs() {
      window.location.href = `/jobs?lan=${this.$i18n.locale}`;
    },
    toHouses(tab) {
      console.log(tab);
      if (tab == 1) {
        window.location.href = `/houses?lan=${this.$i18n.locale}`;
        return;
      } else
        window.location.href = `/houses?lan=${this.$i18n.locale}&type=${tab}`;
    },
    openUrl(url) {
      window.open(url);
    },
    clickSearch(searchType, keywords) {
      if (searchType == "Houses") {
        if (keywords) {
          window.open(`/houses?lan=${this.$i18n.locale}&keyword=${keywords}`);
        } else window.open(`/houses?lan=${this.$i18n.locale}`);
      }
      if (searchType == "Jobs") {
        if (keywords) {
          window.open(`/jobs?lan=${this.$i18n.locale}&keyword=${keywords}`);
        } else window.open(`/jobs?lan=${this.$i18n.locale}`);
      }
      if (searchType == "LifeHelper") {
        if (keywords) {
          window.open(`/lifehelper?lan=${this.$i18n.locale}&keyword=${keywords}`);
        } else window.open(`/lifehelper?lan=${this.$i18n.locale}`);
      }
    },
  },
};
</script>

<style lang="scss">
.el-message {
  min-width: 300px;
}
// banner样式
.el-carousel__container {
  height: 333px;
}
.scrollContainer {
  overflow: auto;
  min-height: 100vh;
  min-width: 1250px;
}
.imgCut {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hotBlock {
  margin: 0 auto 80px auto;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1200px;
}
.titleLine {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .blockTitle {
    font-weight: 500;
    font-size: 40px;
    width: 100%;
  }
  .blockType {
    margin-left: auto;
    display: flex;
    flex-shrink: 0;
    .blockTypeItem {
      font-size: 14px;
      padding: 4px 12px;
      border-radius: 26px;
      box-sizing: border-box;
      margin-right: 12px;
      cursor: pointer;
    }
    .blockTypeItem.selected {
      background: #ffc03e;
    }
  }
}

.hotHouse {
  position: relative;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
  min-width: 1195px;
  .hotHouseItem:nth-child(5n) {
    margin-right: 0;
  }

  .hotHouseItem {
    width: 285px;
    cursor: pointer;
    margin-right: 18px;
    margin-bottom: 18px;
    .labelLine {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 27px;
      margin-bottom: 0;
      color: #616c88;
      .labelItem {
        display: inline;
        margin-right: 6px;
        padding: 6px;
      }
    }
    .houseCover {
      width: 286px;
      height: 230px;
      border-radius: 2px;
      overflow: hidden;
      margin-bottom: 14px;
      background: #fcedcd;
    }
    .houseName {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 4px;
    }

    .housePrice {
      font-size: 14px;
      margin-bottom: 4px;
      .priceNum {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}
.desLine {
  color: #666666;
  font-size: 14px;
}
.houseScore {
  display: flex;
  font-size: 13px;
  color: #8592a6;
  align-items: center;
  .scoreNum {
    background: #2eaac1;
    border-radius: 6px 0px 6px 6px;
    color: #fff;
    font-size: 14px;
    padding: 1px 6px;
    margin-right: 5px;
  }
}
.hotJob {
  display: flex;
  position: relative;
  .hotJobItem:last-child {
    margin-right: 0;
  }
  .hotJobItem {
    width: 380px;
    height: 130px;
    border: 1px solid #cccccc;
    padding: 21px 19px;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    margin-right: 30px;
    .jobName.textOverflow {
      -webkit-line-clamp: 2;
    }
    .jobName {
      font-weight: 700;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 20px;
      height: 48px;
    }
  }
}
.company {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666666;
  .companyImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 7px;
  }
  .jobDate {
    margin-left: auto;
  }
}
.hotHelper {
  display: flex;
  position: relative;
  .hotHelperItem {
    width: 380px;
    height: 147px;
    box-sizing: border-box;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 32px;
    position: relative;
    cursor: pointer;
    .helperText {
      position: absolute;
      left: 58px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .hotHelperItem:last-child {
    margin-right: 0;
  }
}
.textOverflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}
.toAll {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 10;
  cursor: pointer;
  img {
    width: 60px;
  }
}
.searchBar {
  width: 84%;
  max-width: 800px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 14px 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #666666;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 99;
  display: flex;
  background: #fff;
  .searchIcon {
    position: absolute;
    right: 16px;
    top: 12px;
    width: 28px;
    padding: 4px;
    cursor: pointer;
  }
  .searchIcon.closeIcon {
    right: 56px;
    width: 20px;
    top: 16px;
  }
  .searchType {
    padding: 4px 0;
    border-right: 1px solid #999999;
    margin-right: 20px;
    flex-shrink: 0;
    font-size: 16px;
    width: 130px;
    text-align: center;
    .el-button,
    .el-dropdown {
      font-size: 16px;
    }
  }
  input {
    border: none;
    padding: 2px 30px 0 6px;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    background: transparent;
  }
}
.newsLine {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .newsTypeItem {
    width: 380px;
    height: 298px;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    .newsTypeText {
      position: absolute;
      top: 34px;
      left: 34px;
      font-size: 40px;
      font-weight: 500;
      color: #fff;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
    }
  }
  .newsTypeItem:last-child {
    margin-right: 0;
  }
}
.banner {
  position: relative;
  margin: 60px 0 110px 0;
}

input:focus-visible {
  outline: none;
}
.el-dropdown {
  cursor: pointer;
}
</style>

<style lang="scss">
// 移动端样式
.isMobile {
  .hotHouse {
    min-width: initial;
  }
  .hotHouse .hotHouseItem .labelLine {
    margin-bottom: 3px;
  }
  .scrollContainer {
    min-width: 100%;
  }
  .banner {
    margin: 44px 0 50px 0;
    .el-carousel__container {
      height: 184px;
    }
  }
  .searchBar {
    padding: 8px 0;
    .searchIcon {
      top: 6px;
      right: 6px;
      width: 22px;
    }
    .searchIcon.closeIcon {
      width: 18px;
      right: 36px;
      top: 7px;
    }
    .searchType {
      width: 84px;
      padding: 2px 0;
      margin-right: 12px;
      .el-dropdown {
        font-size: 13px;
      }
    }
    input {
      font-size: 13px;
    }
  }
  .toAll {
    display: none;
  }
  .newsLine {
    overflow: auto;
    justify-content: flex-start;
    .newsTypeItem {
      width: 160px;
      height: 125px;
      margin: 0 16px 0 auto;
      border-radius: 0;
      flex-shrink: 0;
      .newsTypeText {
        font-size: 18px;
      }
    }
  }
  .hotBlock {
    margin: 0 auto 48px auto;
  }
  .titleLine {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 18px;
    .blockTitle {
      font-size: 24px;
    }
    .blockType {
      margin: 12px 0 0 0;
      .blockTypeItem {
        font-size: 13px;
      }
    }
  }
  .hotHouse {
    flex-wrap: wrap;
    width: 354px;
    margin: 0 auto;
    line-height: 1.2;

    .hotHouseItem {
      width: 166px;
      margin: 0 10px 12px 0;
      .houseCover {
        width: 166px;
        height: 134px;
        margin-bottom: 8px;
      }
      .desLine {
        font-size: 12px;
        margin-bottom: 4px;
      }
      .housePrice {
        font-size: 12px;
        .priceNum {
          font-size: 14px;
        }
      }
    }
    .hotHouseItem:nth-child(2n + 1) {
      margin-right: 0;
    }
  }
  .houseScore {
    font-size: 12px;
    .scoreNum {
      font-size: 12px;
      line-height: 1.5;
    }
  }
  .hotJob {
    width: 100%;
    overflow: auto;
    .hotJobItem {
      padding: 13px;
      width: 277px;
      height: 103px;
      margin-right: 12px;
      flex-shrink: 0;
      .jobName {
        font-size: 15px;
        margin-bottom: 12px;
        height: 45px;
      }
    }
    .hotJobItem:last-child {
      margin-right: 0;
    }
  }
  .company {
    font-size: 12px;
    .companyImg {
      width: 20px;
      height: 20px;
    }
  }
  .hotHelper {
    overflow: auto;
    .hotHelperItem {
      flex-shrink: 0;
      width: 277px;
      height: 107px;
      font-size: 24px;
      margin-right: 12px;
      .helperText {
        left: 42px;
      }
    }
    .hotHelperItem:last-child {
      margin-right: 0;
    }
  }
}
</style>

