<template>
  <div class="houseItem roomItem" @click="openRoom(item.id)">
    <div class="houseCover">
      <img
        v-if="item.imageList"
        class="imgCut"
        :src="item.imageList[0]"
        alt=""
      />
    </div>
    <div class="houseInfo">
      <div class="houseInfoInner">
        <div class="houseInfoInnerLeft">
          <div class="houseTitleLine">
          <div class="houseTitle">{{ item.title }}</div>
          <div class="likeBtn" @click.stop="clickCollect">
            <img v-if="!item.isCollected" src="@/assets/like.png" alt="" />
            <img v-else src="@/assets/like_yellow.png" alt="" />
          </div>
          </div>
          <div class="desLine">
            {{ item.area }} · {{ item.roomNumStr }}
          </div>
          <div class="labelLine" v-if="item.tagList">
            <div
              class="labelItem"
              v-for="(label, idx) in item.tagList"
              :key="idx"
            >
              {{ label }}
            </div>
          </div>
        </div>
      </div>
      <div class="price">
        HKD <span class="priceNum">{{ item.price }}</span> /{{
          item.payTimeUnit
        }}
      </div>
      <div class="checkBtn">查看详情</div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
    };
  },
  methods: {
    openRoom(id) {
      window.open(`/houses/room/detail?lan=${this.$i18n.locale}&id=${id}`);
    },
    async clickCollect() {
      const collectRes = await request.get("/webapi/userCollect/collect", {
        params: {
          ht: 2,
          htdId: this.item.id,
        },
      });
      if (collectRes.code == 200) {
        this.item.isCollected = !this.item.isCollected;
      }
    },
  },
  props: ["item"],
};
</script>
