<template>
  <div class="scrollContainer">
    <Header />
    <div
      class="content"
      :class="{ personalHouse: houseInfo.type == $t('个人房源') }"
      v-if="houseInfo && houseInfo.enabled && !houseInfo.deleted"
    >
      <div class="houseHead">
        <div class="houseHeadLeft">
          <div class="titleLine">
            <div class="houseTitle">
              <span class="houseType">{{ houseInfo.type }}</span>
              <span
                >{{ houseInfo.title }}
                <span
                  v-if="
                    houseInfo.type == $t('个人房源') &&
                    houseInfo.roomTypeList &&
                    houseInfo.roomTypeList.length
                  "
                  >| {{ houseInfo.roomTypeList[0].title }}
                </span></span
              >
              <span
                class="likeBtn"
                v-if="
                  houseInfo.type == $t('个人房源') &&
                  houseInfo.roomTypeList &&
                  houseInfo.roomTypeList.length
                "
                @click.stop="clickCollect"
              >
                <img
                  v-if="!houseInfo.roomTypeList[0].isCollected"
                  src="@/assets/like.png"
                  alt=""
                />
                <img v-else src="@/assets/like_yellow.png" alt="" />
              </span>
            </div>
          </div>
          <div class="desLine" v-if="houseInfo">
            <span v-if="houseInfo.region">{{ houseInfo.region }}</span>
            <span v-if="houseInfo.district"> · {{ houseInfo.district }}</span>
            <template
              v-if="
                houseInfo.roomTypeList &&
                houseInfo.roomTypeList.length &&
                houseInfo.type == $t('个人房源')
              "
            >
              <span v-if="houseInfo.roomTypeList[0].area">
                · {{ houseInfo.roomTypeList[0].area }}
              </span>
              <span v-if="houseInfo.roomTypeList[0].roomNumStr">
                · {{ houseInfo.roomTypeList[0].roomNumStr }}</span
              >
            </template>
          </div>
          <div class="labelLine">
            <template v-if="houseInfo.locationList">
              <div
                class="labelItem"
                v-for="location in houseInfo.locationList"
                :key="location"
              >
                {{ location }}
              </div>
            </template>
            <template v-if="houseInfo.tagList">
              <div
                class="labelItem"
                v-for="label in houseInfo.tagList"
                :key="label"
              >
                {{ label }}
              </div>
            </template>
          </div>
          <div class="houseDet">
            <div v-if="houseInfo.address">
              <img src="@/assets/location.png" alt="" />{{ houseInfo.address }}
            </div>
            <div v-if="houseInfo.phones">
              <img src="@/assets/tele.png" alt="" />{{ houseInfo.phones }}
            </div>
            <div v-if="houseInfo.emails">
              <img src="@/assets/email.png" alt="" />{{ houseInfo.emails }}
            </div>
          </div>
        </div>
        <div class="houseHeadRight">
          <div class="houseScore">
            <div class="commentBtn" @click="clickComment">
              <img src="@/assets/comment_blue.png" alt="" />{{ $t("写评价") }}
            </div>
            <div v-if="commentData.commentNum">
              · {{ commentData.commentNum }}{{ $t("条住客点评") }}
            </div>
            <div class="scoreNum big">
              {{ houseInfo.score ? houseInfo.score : $t("暂无评分") }}
            </div>
          </div>
          <div class="checkNum">
            <img src="@/assets/view.png" alt="" />被查看了{{
              houseInfo.clickNum
            }}次
          </div>
          <div class="flex">
            <div class="price" v-if="houseInfo.type == $t('个人房源')">
              HKD <span class="priceNum">{{ houseInfo.price }}</span> /{{
                houseInfo.payTimeUnit
              }}
            </div>
            <div class="price" v-else>
              HKD <span class="priceNum">{{ houseInfo.price }}</span> 起/{{
                houseInfo.payTimeUnit
              }}
            </div>
            <div
              v-if="houseInfo.type == $t('个人房源')"
              class="checkBtn"
              style="margin-left: 17px"
              @click="clickBook"
            >
              {{ $t("立即预订") }}
            </div>
          </div>
        </div>
      </div>
      <div class="housePicBlock" id="housePicBlock">
        <template
          v-if="
            houseInfo.type == $t('个人房源') &&
            houseInfo.roomTypeList &&
            houseInfo.roomTypeList[0]
          "
        >
          <div class="picLeft">
            <div class="picItem">
              <img
                v-if="
                  houseInfo.roomTypeList[0].imageList &&
                  houseInfo.roomTypeList[0].imageList.length
                "
                class="imgCut"
                :src="houseInfo.roomTypeList[0].imageList[0]"
                alt=""
              />
            </div>
          </div>
          <div class="picRight" v-show="!isMobile">
            <div class="picItem" v-for="item in 4" :key="item">
              <img
                v-if="
                  houseInfo.roomTypeList[0].imageList &&
                  houseInfo.roomTypeList[0].imageList[item]
                "
                class="imgCut"
                :src="houseInfo.roomTypeList[0].imageList[item]"
                alt=""
              />
              <div v-else class="nullPic">
                <img src="@/assets/logo.png" alt="">
              </div>
            </div>
          </div>
          <div
            v-show="false"
            v-if="
              houseInfo.roomTypeList[0].imageList &&
              houseInfo.roomTypeList[0].imageList.length > 5
            "
          >
            <img
              v-for="(
                imgItem, idx
              ) in houseInfo.roomTypeList[0].imageList.slice(
                5,
                houseInfo.roomTypeList[0].imageList.length
              )"
              :key="idx"
              class="imgCut"
              :src="imgItem"
              alt=""
            />
          </div>
          <div
            class="allPicBtn"
            @click="viewImgs"
            v-if="
              houseInfo.roomTypeList[0].imageList &&
              houseInfo.roomTypeList[0].imageList.length > 5
            "
          >
            {{ $t("查看更多图片") }}
          </div>
        </template>
        <template v-else>
          <div class="picLeft">
            <div class="picItem">
              <img
                v-if="houseInfo.imageList && houseInfo.imageList.length"
                class="imgCut"
                :src="houseInfo.imageList[0]"
                alt=""
              />
            </div>
          </div>
          <div class="picRight" v-show="!isMobile">
            <div class="picItem" v-for="item in 4" :key="item">
              <img
                v-if="houseInfo.imageList && houseInfo.imageList[item]"
                class="imgCut"
                :src="houseInfo.imageList[item]"
                alt=""
              />
              <div v-else class="nullPic">
                <img src="@/assets/logo.png" alt="">
              </div>
            </div>
          </div>
          <div
            v-show="false"
            v-if="houseInfo.imageList && houseInfo.imageList.length > 5"
          >
            <img
              v-for="(imgItem, idx) in houseInfo.imageList.slice(
                5,
                houseInfo.imageList.length
              )"
              :key="idx"
              class="imgCut"
              :src="imgItem"
              alt=""
            />
          </div>
          <div
            class="allPicBtn"
            @click="viewImgs"
            v-if="houseInfo.imageList && houseInfo.imageList.length > 5"
          >
            {{ $t("查看更多图片") }}
          </div>
        </template>
      </div>
      <div class="houseDesBlock">
        <div class="houseDesTitle">房源描述</div>
        <div class="houseDes">
          {{ houseInfo.description }}
        </div>
      </div>
      <div class="houseDesBlock">
        <div
          class="houseDesTitle"
          v-if="
            houseInfo.type == $t('个人房源') &&
            houseInfo.roomTypeList &&
            houseInfo.roomTypeList.length
          "
        >
          客房描述
        </div>
        <div class="houseDes">
          {{ houseInfo.roomTypeList[0].description }}
        </div>
      </div>
      <div class="houseDesBlock" v-if="houseInfo.type != $t('个人房源')">
        <div class="houseDesTitle">{{ $t("客房类型") }}</div>
        <div class="itemList">
          <RoomItem
            v-for="item in houseInfo.roomTypeList"
            :key="item.id"
            :item="item"
          />
        </div>
      </div>
      <template
        v-if="
          houseInfo.type == $t('个人房源') &&
          houseInfo.roomTypeList &&
          houseInfo.roomTypeList.length
        "
      >
        <div class="houseDesBlock">
          <div class="houseDesTitle">{{ $t("客房设施") }}</div>
          <div class="secondTitle">{{ $t("房内基础设施") }}</div>
          <div class="facilityList">
            <div
              class="facilityItem"
              :class="{
                nullFacility:
                  houseInfo.roomTypeList[0].baseFacility[item.value] == 0,
              }"
              v-for="(item, idx) in sortedBaseFacility"
              :key="idx"
            >
              <img
                v-if="houseInfo.roomTypeList[0].baseFacility[item.value] != 0"
                src="@/assets/checked.png"
                alt=""
              />
              <img v-else src="@/assets/unchecked.png" alt="" />
              {{ $t(item.label) }}
            </div>
          </div>
          <div class="secondTitle">{{ $t("楼宇设施/服务") }}</div>
          <div class="facilityList">
            <div
              class="facilityItem"
              :class="{
                nullFacility:
                  houseInfo.roomTypeList[0].serviceFacility[item.value] == 0,
              }"
              v-for="(item, idx) in sortedServiceFacility"
              :key="idx"
            >
              <img
                v-if="
                  houseInfo.roomTypeList[0].serviceFacility[item.value] != 0
                "
                src="@/assets/checked.png"
                alt=""
              />
              <img v-else src="@/assets/unchecked.png" alt="" />{{
                $t(item.label)
              }}
            </div>
          </div>
        </div>
        <div class="houseDesBlock">
          <div class="houseDesTitle">其他描述</div>
          <div class="houseDes">
            {{
              houseInfo.roomTypeList[0].otherDescription
                ? houseInfo.roomTypeList[0].otherDescription
                : "暂无"
            }}
          </div>
        </div>
        <div class="houseDesBlock">
          <div class="houseDesTitle">{{ $t("预订须知") }}</div>
          <div class="houseDes">
            {{
              houseInfo.roomTypeList[0].bookInstruction
                ? houseInfo.roomTypeList[0].bookInstruction
                : $t("暂无")
            }}
          </div>
        </div>
      </template>

      <div class="houseDesBlock">
        <div class="houseDesTitle">
          {{ $t("评价") }}
        </div>
        <template v-if="commentData.commentNum">
          <div class="scoreLine">
            <div>
              <div class="commentTitleLine">
                <div class="averageScore">
                  <el-rate
                    v-model="houseInfo.score"
                    disabled
                    text-color="#2eaac1"
                    score-template="{value}"
                    :colors="colors"
                    disabled-void-color="#dcdcdc"
                  >
                  </el-rate>
                </div>
                <div v-if="commentData.commentNum">
                  {{ houseInfo.houseScore }} · {{ commentData.commentNum
                  }}{{ $t("条住客点评") }}
                </div>
              </div>
              <div class="commentBtn" @click="clickComment">
                <img src="@/assets/comment_white.png" alt="" />{{
                  $t("写评价")
                }}
              </div>
            </div>
            <div class="scoreType" v-if="commentData.commentNum">
              <div class="scoreTypeItem">
                <div class="typeName">{{ $t("清洁") }}</div>
                <div class="typeProgress">
                  <div
                    class="scoreProgress"
                    :style="{
                      width: (commentData.cleanScoreAvg / 5) * 100 + '%',
                    }"
                  ></div>
                </div>
                <div class="typeNum">{{ commentData.cleanScoreAvg }}</div>
              </div>
              <div class="scoreTypeItem">
                <div class="typeName">地理位置</div>
                <div class="typeProgress">
                  <div
                    class="scoreProgress"
                    :style="{
                      width: (commentData.locationScoreAvg / 5) * 100 + '%',
                    }"
                  ></div>
                </div>
                <div class="typeNum">{{ commentData.locationScoreAvg }}</div>
              </div>
              <div class="scoreTypeItem">
                <div class="typeName">{{ $t("沟通") }}</div>
                <div class="typeProgress">
                  <div
                    class="scoreProgress"
                    :style="{
                      width:
                        (commentData.communicationScoreAvg / 5) * 100 + '%',
                    }"
                  ></div>
                </div>
                <div class="typeNum">
                  {{ commentData.communicationScoreAvg }}
                </div>
              </div>
              <div class="scoreTypeItem">
                <div class="typeName">描述准确度</div>
                <div class="typeProgress">
                  <div
                    class="scoreProgress"
                    :style="{
                      width: (commentData.descriptionScoreAvg / 5) * 100 + '%',
                    }"
                  ></div>
                </div>
                <div class="typeNum">{{ commentData.descriptionScoreAvg }}</div>
              </div>
            </div>
          </div>
          <div class="commentList">
            <div
              class="commentItem"
              v-for="item in commentData.commentList"
              :key="item.id"
            >
              <div class="commentUser">
                <div class="avatar">
                  <img
                    v-if="item.user.avatar"
                    class="imgCut"
                    :src="item.user.avatar"
                    alt=""
                  />
                  <img
                    v-else
                    class="imgCut"
                    src="@/assets/company.png"
                    alt=""
                  />
                </div>
                <div class="userRight">
                  <div class="userName">
                    {{ item.user.nickname ? item.user.nickname : "已注销" }}
                  </div>
                  <div class="commentTime">{{ item.createDate }}</div>
                </div>
              </div>
              <div class="commentDet textOverflow">
                {{ item.content }}
              </div>
            </div>
          </div>
          <div class="pageLine">
            <el-pagination
              :page-size="10"
              :pager-count="5"
              layout="prev, pager, next"
              :total="commentData.commentNum"
              :current-page="pageNum"
              @current-change="handleCurrentChange"
              :hide-on-single-page="true"
            >
            </el-pagination>
          </div>
        </template>
        <template v-else>
          <div>{{ $t("暂无评价") }}</div>
        </template>
      </div>
      <BookHotelDialog ref="bookHotelDialog" />
      <BookPersonalDialog
        :title="`${houseInfo.title} | ${houseInfo.roomTypeList[0].title} `"
        :phones="houseInfo.phones"
        :emails="houseInfo.emails"
        :wxQrCode="houseInfo.wxQrCode"
        ref="bookPersonalDialog"
      />
      <RateDialog
        ref="rateDialog"
        :title="houseInfo.title"
        :scoreStr="houseInfo.houseScore"
        :score="+houseInfo.houseScore"
        :commentNum="commentData.commentNum"
        :id="houseInfo.id"
      />
    </div>
    <div class="null" v-else>
      {{ loading ? $t("加载中...") : $t("该房源已下架，看看其他房源吧") }}
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Viewer from "viewerjs";
import BookHotelDialog from "@/components/BookHotelDialog.vue";
import BookPersonalDialog from "@/components/BookPersonalDialog.vue";
import RateDialog from "@/components/RateDialog.vue";
import RoomItem from "@/components/RoomItem.vue";
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      des: "香港港丽酒店与著名的购物和娱乐中心太古广场直接相连。除了时尚的客房，酒店还提供屡获殊荣的餐饮选择、室外温水游泳池和24小时健身中心。\n宽敞现代的客房俯瞰维多利亚港和太平山顶的美景。每间客房都配备了平板电视、DVD播放机以及迷你吧。优雅的大理石浴室设有浴缸和吹风机。\n香港港丽酒店距离香港中央商务区的金钟地铁站有5分钟步行路程，距离海洋公园仅有4分钟地铁路程（南港岛线），距离港岛南部仅有10分钟地铁路程，距离旺角著名的女人街有10分钟的地铁车程，距离赤鱲角国际机场有30分钟的车程。\n屡获殊荣的意宁谷餐厅供应地道的意大利菜肴，金叶庭餐厅供应粤式特色菜。其他餐饮选择包括Garden\nCafe咖啡厅提供的国际自助餐和Pacific Bar酒吧提供的葡萄酒。\n\n室外温水游泳池享有轻松的氛围，供客人在繁忙的一天后放松休息。香港港丽酒店还提供桑拿浴室、蒸汽浴室和按摩服务。",
      keywords: "",
      currentPage: 1,
      viewer: null,
      value: 3.7,
      colors: ["#2eaac1", "#2eaac1", "#2eaac1"],
      isCollect: false,
      houseInfo: null,
      commentData: {},
      pageNum: 1,
      houseId: null,
      loading: false,
      baseFacility: [
        {
          label: "WIFI",
          value: "wifi",
        },
        {
          label: "冰箱",
          value: "fridge",
        },
        {
          label: "冷气",
          value: "cooling",
        },
        {
          label: "暖气",
          value: "heating",
        },
        {
          label: "洗衣机",
          value: "washer",
        },
        {
          label: "衣柜",
          value: "wardrobe",
        },
        {
          label: "书桌",
          value: "desk",
        },
        {
          label: "沙发",
          value: "sofa",
        },
        {
          label: "电吹风",
          value: "hairDryer",
        },
        {
          label: "电视",
          value: "television",
        },
        {
          label: "智能门锁",
          value: "intelligentLock",
        },
        {
          label: "独立卫浴",
          value: "bathroom",
        },
        {
          label: "独立厨房",
          value: "kitchen",
        },
        {
          label: "热水壶",
          value: "kettle",
        },
        {
          label: "电磁炉",
          value: "inductionCooker",
        },
      ],
      serviceFacility: [
        {
          label: "共享厨房",
          value: "sharedKitchen",
        },
        {
          label: "共享自习室",
          value: "sharedStudyRoom",
        },
        {
          label: "对讲门禁",
          value: "intercom",
        },
        {
          label: "保安",
          value: "securityGuard",
        },
        {
          label: "健身房",
          value: "gym",
        },
        {
          label: "清洁服务",
          value: "cleanService",
        },
      ],
    };
  },
  components: {
    Header,
    Footer,
    BookHotelDialog,
    BookPersonalDialog,
    RateDialog,
    RoomItem,
  },
  computed: {
    ...mapGetters(["isLogin"]),
    sortedBaseFacility() {
      return this.baseFacility.slice().sort((a, b) => {
        return (
          this.houseInfo.roomTypeList[0].baseFacility[b.value] -
          this.houseInfo.roomTypeList[0].baseFacility[a.value]
        );
      });
    },
    sortedServiceFacility() {
      return this.serviceFacility.slice().sort((a, b) => {
        return (
          this.houseInfo.roomTypeList[0].serviceFacility[b.value] -
          this.houseInfo.roomTypeList[0].serviceFacility[a.value]
        );
      });
    },
  },
  async created() {
    document.title = "房源详情 - 马上住 HorseOnLiving";
    if (this.$route.query.id) {
      this.houseId = this.$route.query.id;
      this.getInfo(this.houseId);
    } else window.location.href = `/?lan=${this.$i18n.locale}`;
  },
  beforeDestroy() {
    if (this.viewer && this.viewer.destroy) {
      this.viewer.destroy();
    }
  },
  methods: {
    async clickCollect() {
      const collectRes = await request.get("/webapi/userCollect/collect", {
        params: {
          ht: 2,
          htdId: this.houseInfo.roomTypeList[0].id,
        },
      });
      if (collectRes.code == 200) {
        this.houseInfo.roomTypeList[0].isCollected =
          !this.houseInfo.roomTypeList[0].isCollected;
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getCommentList(this.houseId);
      console.log(`当前页: ${val}`);
    },
    async getCommentList(id) {
      const commentListRes = await request.get("/webapi/comment/list", {
        params: {
          houseId: id,
          pageSize: 10,
          pageNum: this.pageNum,
        },
      });
      if (commentListRes.code == 200) {
        this.commentData = commentListRes.data;
      }
    },
    async getInfo(id) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const infoRes = await request.get("/webapi/house/detail", {
        params: {
          houseId: id,
          lang: this.$route.query.lan == "tra" ? 1 : 0,
        },
      });
      if (infoRes.code == 200) {
        this.loading = false;
        this.houseInfo = infoRes.data;
        this.$nextTick(() => {
          this.viewer = new Viewer(document.getElementById("housePicBlock"), {
            toolbar: {
              prev: {
                show: 1,
                size: "large",
              },
              next: {
                show: 1,
                size: "large",
              },
            },
            navbar: true,
            title: false,
            movable: false,
            minZoomRatio: 0.3,
            maxZoomRatio: 3,
            viewed(res) {
              console.log("viewed: ", res);
            },
          });
        });
        this.getCommentList(id);
      }
      this.loading = false;
    },
    clickBook() {
      if (!this.isLogin) {
        return this.$message({
          message: this.$t("当前未登录"),
        });
      }
      if (this.houseInfo.type !== this.$t("个人房源")) {
        this.$refs["bookHotelDialog"].contentVisible = true;
      } else this.$refs["bookPersonalDialog"].contentVisible = true;
    },
    clickComment() {
      if (!this.isLogin) {
        return this.$message({
          message: this.$t("当前未登录"),
        });
      }
      this.$refs["rateDialog"].contentVisible = true;
    },
    viewImgs() {
      this.viewer.view(5);
    },
  },
};
</script>

<style lang="scss">
.null {
  height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.scoreNum.big {
  padding: 3px 10px;
  font-size: 15px;
  font-weight: 500;
}
.content {
  margin: 85px auto 80px auto;
  width: 95%;
  max-width: 1200px;
  min-height: calc(100vh - 204px);
  font-size: 14px;
}
.houseType {
  background: #ece1c1;
  border-radius: 4px 0px;
  padding: 4px 8px;
  color: #616c88;
  font-size: 13px;
  margin-right: 9px;
  flex-shrink: 0;
  vertical-align: middle;
  font-weight: 400;
}
.houseHead {
  display: flex;
  .houseHeadLeft {
    display: flex;
    flex-direction: column;
    width: 72%;
    .desLine {
      margin-bottom: 6px;
    }
    .titleLine {
      margin-bottom: 8px;
    }

    .houseTitle {
      font-weight: 700;
      font-size: 24px;
      margin-right: 7px;
    }
  }
  .houseDet {
    height: 78px;
  }
  .houseHeadRight {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    text-align: right;
    .houseScore {
      justify-content: right;
      margin: 10px 0;
      .commentBtn {
        color: #0071c2;
        font-size: 13px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: auto;
        img {
          width: 16px;
        }
      }
      .scoreNum {
        margin-left: 8px;
      }
    }
    .checkNum {
      border: 0.6px solid #8592a6;
      border-radius: 2px;
      color: #8592a6;
      font-size: 12px;
      margin: 0 0 50px auto;
      padding: 4px 6px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        margin-right: 2px;
      }
    }
    .price {
      margin: 0 0 0 auto;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    margin: auto 0 40px 0;
  }
}
.housePicBlock {
  display: flex;
  height: 382px;
  margin-bottom: 60px;
  position: relative;
  .allPicBtn {
    position: absolute;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 2px;
    padding: 7px 25px;
    right: 18px;
    bottom: 18px;
    cursor: pointer;
  }
  .picItem {
    margin: 5px;
    overflow: hidden;
    background: rgba(255, 192, 62, 0.3);
    img {
      cursor: zoom-in;
    }
  }
  .picLeft {
    width: 50%;
    .picItem {
      height: calc(100% - 10px);
      border-radius: 4px 0 0 4px;
      margin-left: 0;
    }
  }
  .picRight {
    display: flex;
    width: calc(50% - 4px);
    flex-wrap: wrap;
    .picItem {
      width: calc(50% - 8px);
      height: 181px;
      position: relative;
    }
    .picItem:nth-child(2) {
      border-radius: 0 4px 0 0;
      margin-right: 0;
    }
    .picItem:nth-child(4) {
      border-radius: 0 0 4px 0;
      margin-right: 0;
    }
  }
}
.houseDesBlock {
  margin-bottom: 60px;
  .commentBtn {
    padding: 6px 12px;
    font-size: 13px;
    background: #2eaac1;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .houseDesTitle {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .itemList {
    min-height: unset;
    .houseItem.roomItem {
      height: auto;
      .labelLine {
        margin-bottom: 0;
      }
      .houseInfo {
        .houseInfoInner {
          margin-bottom: 44px;
          min-height: 90px;
          flex-direction: column;
        }
      }
    }
  }
  .houseDes {
    line-height: 1.6;
    white-space: pre-line;
  }
  .houseTitleLine {
    width: 100%;
    display: flex;
    align-items: center;
    .likeBtn {
      margin-left: auto;
      img {
        width: 24px;
        transition: transform 0.15s;
      }
      img:hover {
        transform: scale(1.3);
      }
    }
  }
}

.commentTitleLine {
  display: flex;
  margin-right: 150px;
  width: 380px;
  font-weight: 700;
  font-size: 20px;
  color: #2eaac1;
  margin-bottom: 0px;
  .averageScore {
    margin: 0 10px 16px 0;
    .el-rate__icon {
      font-size: 28px;
    }
    .el-rate__text {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
.scoreLine {
  display: flex;
  margin-bottom: 20px;
  padding-left: 16px;
  height: 96px;
}
.scoreType {
  display: flex;
  flex-wrap: wrap;
  width: 700px;
  flex-shrink: 0;
  .scoreTypeItem {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 27px;
    .typeProgress {
      width: 138px;
      height: 4px;
      border-radius: 21px;
      background: #000;
      margin-right: 10px;
      position: relative;
      .scoreProgress {
        position: absolute;
        height: 4px;
        border-radius: 21px;
        left: 0;
        background: #2eaac1;
      }
    }
    .typeName {
      width: 115px;
    }
    .typeNum {
      font-weight: 500;
      min-width: 24px;
    }
  }
  .scoreTypeItem:nth-child(2n + 1) {
    margin-right: 76px;
  }
}
.commentList {
  padding: 0 40px 0 20px;
  margin-bottom: 50px;
  .commentItem {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    .commentUser {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        overflow: hidden;
        margin-right: 14px;
      }
      .userRight {
        .userName {
          font-size: 16px;
          font-weight: 500;
        }
        .commentTime {
          color: #666;
          font-weight: 300;
        }
      }
    }
    .commentDet {
      color: #666;
      white-space: pre-line;
    }
  }
}
.pageLine {
  display: flex;
  margin: 0 auto 90px auto;
  justify-content: center;
}
.secondTitle {
  font-weight: 500;
  padding: 12px 0 20px 0;
}
.facilityList {
  display: flex;
  padding: 0 10px;
  flex-wrap: wrap;
  .facilityItem {
    margin-bottom: 20px;
    width: 280px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }
}
.nullFacility {
  opacity: 0.2;
}
.houseDet {
  font-size: 14px;
  color: #666666;
  margin-bottom: 44px;
  div {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      margin-right: 8px;
    }
  }
}
.houseHeadLeft {
  .likeBtn {
    margin-left: 4px;
    vertical-align: middle;
  }
}
</style>

<style lang="scss">
// 第三方组件样式******

// 查看图片组件
.viewer-toolbar > ul > .viewer-large {
  margin: 0px 6px;
}
// element组件
.el-select .el-input.is-focus .el-input__inner,
.el-pagination__sizes .el-input .el-input__inner:hover,
.el-select .el-input__inner:focus {
  border-color: #2eaac1;
}
.el-pager li.active,
.el-pager li:hover,
.el-pagination button:hover,
.el-select-dropdown__item.selected {
  color: #2eaac1;
}
</style>

<style lang="scss">
// 移动端样式
.isMobile {
  .null {
    height: calc(100vh - 98px);
  }
  .content {
    margin-top: 60px;
    min-height: calc(100vh - 188px);
    overflow: auto;
  }
  .houseHead {
    flex-direction: column;
    .houseHeadLeft {
      width: 100%;
      .houseTitle {
        font-size: 18px;
      }
      .titleLine {
        flex-direction: row;
        align-items: center;
      }
      .houseType {
        flex-shrink: 0;
        padding: 2px 6px;
      }
    }
    .houseHeadRight {
      margin: 0;
      .houseScore {
        justify-content: left;
        flex-direction: row-reverse;
        margin: 4px 0 8px 0;
        .scoreNum {
          margin-left: 0;
        }
      }
      .checkNum {
        margin: 0 auto 6px 0;
      }
    }
    .houseDet {
      height: auto;
    }
    .flex {
      margin-bottom: 16px;
    }
  }
  .houseDet {
    font-size: 13px;
    margin-bottom: 0px;
    div {
      margin-bottom: 6px;
    }
  }
  .labelLine {
    margin-bottom: 6px;
  }
  .housePicBlock {
    height: 218px;
    margin-bottom: 36px;
    .picLeft {
      width: 100%;
    }
  }
  .houseDesBlock {
    margin-bottom: 36px;
    .houseDesTitle {
      font-size: 16px;
      margin-bottom: 12px;
    }
    .commentBtn {
      margin-bottom: 12px;
      padding: 4px 12px;
    }
    .itemList .houseItem.roomItem .houseInfo .houseInfoInner {
      margin-bottom: 0;
      min-height: 70px;
    }
  }
  .scoreType {
    width: 100%;
    .scoreTypeItem {
      margin: 0 0 16px 0;
      font-size: 13px;
      .typeName {
        width: 80px;
      }
      .typeProgress {
        width: 90px;
      }
    }
  }
  .scoreLine {
    flex-direction: column;
    height: auto;
  }
  .commentTitleLine {
    width: 100%;
    font-size: 16px;
    .averageScore {
      margin: 0 4px 10px 0;
      .el-rate__icon {
        font-size: 20px;
      }
      .el-rate__text {
        font-size: 16px;
      }
    }
  }
  .commentList {
    padding: 0 10px;
    margin-bottom: 16px;
    .commentItem {
      margin-bottom: 12px;
      padding-bottom: 10px;
      .commentUser {
        margin-bottom: 8px;
        .avatar {
          width: 30px;
          height: 30px;
        }
        .userRight {
          .userName {
            font-size: 14px;
          }
          .commentTime {
            font-size: 12px;
          }
        }
      }
    }
  }
  .pageLine {
    margin-bottom: 20px;
  }
}
</style>