import Cookies from "js-cookie";



const TokenKey = "Authorization";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  // Cookies.set(TokenKey, token);
  Cookies.set(TokenKey, token, {
    expires: 30 // 30天
  });
  return
}

export function removeToken() {
  Cookies.remove(TokenKey, {
  });
  return
}

