<template>
  <div class="scrollContainer" style="min-height: 100vh">
    <Header />
    <div class="loginPage">
      <div v-show="status == 'login'">
        <div class="loginTitle">{{ $t("登录或创建账号") }}</div>
        <div class="loginTips">
          {{
            $t(
              "若你已注册，可以通过邮箱密码的方式登录；若通过微信扫码方式登录，未注册用户将自动注册并登录"
            )
          }}
        </div>
        <el-form :rules="rules" ref="loginForm" :model="loginForm">
          <el-form-item :label="$t('电子邮箱')" prop="email1">
            <el-input
              v-model="loginForm.email1"
              autocomplete="off"
              ref="loginEmail"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('密码')" prop="password1">
            <el-input
              v-model="loginForm.password1"
              type="password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="loginBtn" @click="handleLogin">{{ $t("登录") }}</div>
        <div class="loginBtn registerBtn" @click="toRegister">
          {{ $t("还没账号？点击注册") }}
        </div>
        <el-checkbox v-model="checked"
          >我已阅读并同意<a href="/privacy">{{ $t("隐私条款") }}</a></el-checkbox
        >
        <div class="separator">
          <div class="separatorText">{{ $t("其他登录方式") }}</div>
        </div>
        <div class="loginType">
          <img
            class="imgCut"
            src="@/assets/btn_wechat@2x.png"
            alt=""
            @click="clickWx()"
          />
        </div>
      </div>
      <!-- 微信登录 -->
      <template v-if="status == 'wxLogin'">
        <div class="loginTitle" style="text-align: center; margin-bottom: 40px">
          {{ $t("请使用微信扫码登录") }}
        </div>
        <div class="wxLoginImg">
          <template v-if="qrCodeUrl">
            <img class="imgCut" :src="qrCodeUrl" alt="" />
            <div
              v-if="codeExpired"
              class="expired codeLoading"
              @click="clickWx()"
            >
              {{ $t("已过期，点击重新获取") }}
            </div>
          </template>
          <div v-else class="codeLoading">{{ $t("正在获取二维码") }}...</div>
        </div>
        <div class="returnLogin" @click="toLogin">{{ $t("返回邮箱登录") }}</div>
      </template>
      <div v-show="status == 'register'">
        <div class="loginTitle">{{ $t("注册账号") }}</div>
        <div class="loginTips">
          {{ $t("请填写你的邮箱地址，并点击获取验证码") }}
        </div>
        <el-form
          :rules="registerRules"
          ref="registerForm"
          :model="registerForm"
        >
          <el-form-item :label="$t('电子邮箱')" prop="email2">
            <el-input
              v-model="registerForm.email2"
              ref="registerEmail"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('输入验证码')" prop="code">
            <el-input v-model="registerForm.code"> </el-input>
            <el-button
              class="codeBtn"
              ref="codeBtn"
              :loading="sending"
              :disabled="sendDisabled"
              size="small"
              @click="onSendSms"
              >{{ sendButtonText }}</el-button
            >
          </el-form-item>
          <el-form-item :label="$t('请设置密码')" prop="password2">
            <el-input
              v-model="registerForm.password2"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('请再次输入密码')" prop="password3">
            <el-input
              v-model="registerForm.password3"
              type="password"
            ></el-input>
          </el-form-item>
          <el-checkbox v-model="checked"
            >我已阅读并同意<a href="">{{ $t("隐私条款") }}</a></el-checkbox
          >
        </el-form>
        <div class="loginBtn" @click="handleRegister">{{ $t("注册") }}</div>
        <div class="returnLogin" @click="toLogin">{{ $t("返回邮箱登录") }}</div>
      </div>
      <template v-if="status == 'info'">
        <div class="loginTitle">{{ $t("填写个人信息") }}</div>
        <el-form :rules="infoRules" ref="infoForm" :model="infoForm">
          <el-form-item
            :label="$t('头像')"
            prop="imageUrl"
            style="margin-bottom: 0; border: none"
          >
            <el-upload
              class="avatar-uploader"
              action="https://horseonliving.com/webapi/common/upload/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div class="avatar">
                <img
                  v-if="infoForm.imageUrl"
                  class="imgCut"
                  :src="infoForm.imageUrl"
                />
                <img v-else class="imgCut" src="@/assets/default.png" />
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('昵称')" prop="nickname">
            <el-input v-model="infoForm.nickname"></el-input>
          </el-form-item>
          <el-form-item :label="$t('性别')" prop="genderLabel">
            <el-radio-group v-model="infoForm.genderLabel">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="生日" prop="birthday">
            <el-date-picker
              v-model="infoForm.birthday"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              default-value="2000-01-01"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="infoForm.address"></el-input>
          </el-form-item>
        </el-form>
        <div class="loginBtn" @click="submitInfo">完成</div>
      </template>
    </div>
    <BindConfirmDialog ref="bindConfirmDialog" />
    <Footer
      style="position: fixed; bottom: 0; width: 100%; min-width: 1250px"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BindConfirmDialog from "@/components/BindConfirmDialog.vue";
import request from "@/utils/request";
import { mapGetters } from "vuex";
// import dayjs from "dayjs";
export default {
  data() {
    const calNickNameLength = (nickname) => {
      // eslint-disable-next-line
      const reg = /[^\x00-\xff]/;
      let len = 0;
      for (let i = 0; i < nickname.length; i++) {
        if (nickname[i].match(reg)) {
          len += 2;
        } else {
          len++;
        }
      }
      return len;
    };
    const validateNickname = (rule, value, callback) => {
      console.log("===validateNickname", value, typeof value);
      if (!value) {
        return callback(new Error("昵称不能为空"));
      }
      const len = calNickNameLength(value);
      console.log("len", len);
      if (len < 1 || len > 20) {
        return callback(new Error("请输入1-20个字符"));
      }
      callback();
    };
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写邮箱"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("密码必须至少有6个字符"));
      } else {
        callback();
      }
    };
    const validatePasswordConfirm = (rule, value, callback) => {
      if (value.length < 6 || value === "") {
        callback(new Error("密码必须至少有6个字符"));
      } else if (value !== this.registerForm.password2) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      status: "login",
      keywords: "",
      houseType: 0,
      loginForm: {
        email1: "",
        password1: "",
      },
      registerForm: {
        email2: "",
        code: "",
        password2: "",
        password3: "",
      },
      rules: {
        email1: [{ required: true, validator: validateEmail, trigger: "blur" }],
        password1: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
      },
      registerRules: {
        email2: [{ required: true, trigger: "blur", validator: validateEmail }],
        code: [
          {
            required: true,
            trigger: "blur",
            validator: validateCode,
          },
        ],
        password2: [
          {
            required: true,
            trigger: "blur",
            validator: validatePassword,
          },
        ],
        password3: [
          {
            required: true,
            trigger: "blur",
            validator: validatePasswordConfirm,
          },
        ],
      },
      infoRules: {
        imageUrl: [
          { required: true, message: "请上传你的头像", trigger: "blur" },
        ],
        nickname: [
          { validator: validateNickname, required: true, trigger: "blur" },
        ],
        genderLabel: [
          { required: true, message: "请选择你的性别", trigger: "blur" },
        ],
      },
      infoForm: {
        imageUrl: "",
        nickname: "",
        genderLabel: "",
        address: "",
        birthday: "",
      },
      formDisabled: false,
      checked: true,
      genderMap: {
        0: "男",
        1: "女",
      },
      genderEditMap: {
        男: 1,
        女: 2,
      },
      timer: 0,
      sending: false,
      loading: false,
      codeExpired: false,
      qrCodeTimer: null,
      qrCodeUrl: "",
      pollingTimer: null,
      sceneStr: null,
      isNew: false,
    };
  },
  components: {
    Header,
    Footer,
    BindConfirmDialog,
  },
  async created() {
    document.title = "注册登录 - 马上住 HorseOnLiving";
    if (this.isLogin == true) {
      window.location.href = `/?lan=${this.$i18n.locale}`;
    }
  },
  computed: {
    sendButtonText() {
      if (this.timer === 0) {
        return "获取验证码";
      } else {
        return `${this.timer}s后重新发送`;
      }
    },
    sendDisabled() {
      if (this.timer === 0) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters(["isLogin", "avatar", "id"]),
  },
  watch: {
    timer(num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num;
        }, 1000);
      }
    },
    // isLogin: {
    //   immediate: true,
    //   handler(val, oldVal) {
    //     // 从false变成true, 跳转回首页
    //     if (!oldVal && val) {
    //       window.location.href = "/";
    //     }
    //   },
    // },
  },
  methods: {
    // 开始轮询
    async polling() {
      // 清除之前的轮询，以防万一
      this.stopPolling();

      // 查询扫码状态
      const qrCodeLoginRes = await request.get("/webapi/wx/qrCodeLogin", {
        params: {
          wxKey: this.sceneStr,
        },
      });

      // 假设qrCodeLoginRes.success表示登录成功，不需要继续轮询
      if (qrCodeLoginRes.data && qrCodeLoginRes.data.token) {
        // 在这里执行登录成功的逻辑
        const wxLoginForm = {
          token: qrCodeLoginRes.data.token,
          isRemember: this.loginForm.isRemember,
        };
        this.$store
          .dispatch("user/UpdateToken", wxLoginForm)
          .then(() => {
            console.log(" 微信登录拿到token，更新token ");
            this.loading = false;

            // window.location.href = "/";
            if (qrCodeLoginRes.data.isNew) {
              console.log("新账号");
              // 如果是新账号则跳转填写个人信息
              this.status = "info";
              return;
            } else
              this.$message({
                message: "登录成功，正在返回...",
                type: "success",
              });
            window.location.href = `/?lan=${this.$i18n.locale}`;
            return;
          })
          .catch(() => {
            this.loading = false;
          });
        // 这里return出去，不发起下一次轮询
        return;
      }
      // 否则等1s再发起下一个请求
      else
        this.pollingTimer = setTimeout(() => {
          this.polling();
        }, 1000);
    },
    stopPolling() {
      this.pollingTimer && clearTimeout(this.pollingTimer);
      this.pollingTimer = null;
    },
    async clickWx() {
      if (!this.checked) {
        return this.$message({
          message: "请勾选同意协议后再进行登录",
          type: "error",
        });
      }
      console.log("点击微信登录");
      this.status = "wxLogin";
      this.$refs.loginForm.resetFields();
      this.codeExpired = false;
      this.qrCodeTimer && clearTimeout(this.qrCodeTimer);
      this.qrCodeTimer = null;
      const getQrCodeRes = await request.get("/webapi/wx/getQrCode", {});
      if (getQrCodeRes.code == 200) {
        this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${getQrCodeRes.data.ticket}`;
        this.qrCodeTimer = setTimeout(() => {
          this.codeExpired = true;
          this.qrCodeTimer = null;
          this.stopPolling();
        }, 1000 * 60 * 30);
        this.sceneStr = getQrCodeRes.data.sceneStr;
        this.polling();
      }
    },
    handleLogin() {
      if (this.loading) {
        return;
      }
      this.$refs.loginForm.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$store
          .dispatch("user/LoginByEmail", {
            email: this.loginForm.email1,
            password: this.loginForm.password1,
          })
          .then(() => {
            console.log(" 登录完成了 ");
            // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            this.loading = false;
            window.location.href = `/?lan=${this.$i18n.locale}`;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    handleRegister() {
      if (this.loading) {
        return;
      }
      this.$refs.registerForm.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$store
          .dispatch("user/Register", {
            code: this.registerForm.code,
            email: this.registerForm.email2,
            password: this.registerForm.password2,
          })
          .then(() => {
            console.log(" 注册成功了 ");
            // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            this.loading = false;
            this.status = "info";
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    onSendSms() {
      this.$refs["registerForm"].validateField("email2", (valid) => {
        if (valid && valid !== "") {
          return false;
        } else {
          this.sending = true;
          this.$store
            .dispatch("user/GetVerifyCode", this.registerForm.email2)
            .then(() => {
              this.$message.success("验证码发送成功，请注意查收");
              this.timer = 60;
            })
            .catch(() => {
              this.sending = false;
            })
            .finally(() => (this.sending = false));
        }
      });
    },
    async submitInfo() {
      if (this.loading) {
        return;
      }
      this.$refs.infoForm.validate(async (valid) => {
        if (!valid) return;
        this.loading = true;
        const submitRes = await request.post("/webapi/user/update", {
          address: this.infoForm.address,
          avatar: this.infoForm.imageUrl,
          birthday: this.infoForm.birthday,
          gender: this.genderEditMap[this.infoForm.genderLabel],
          nickname: this.infoForm.nickname,
        });
        if (submitRes.code == 200) {
          this.loading = false;
          this.$refs["bindConfirmDialog"].contentVisible = true;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log("===: ", res, file);
      this.infoForm.imageUrl = res.data.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!isJPG && !isPNG) {
        this.$message.error("请上传 JPG 或 PNG 格式的图片");
      }
      if (!isLt4M) {
        this.$message.error("上传的图片大小不能超过 4MB 哦");
      }
      return (isJPG || isPNG) && isLt4M;
    },
    toRegister() {
      this.status = "register";
      this.$refs.loginForm.resetFields();
      this.$nextTick(() => {
        this.$refs.registerEmail.focus();
      });
    },
    toLogin() {
      this.status = "login";
      this.$refs.registerForm.resetFields();
      this.$nextTick(() => {
        this.$refs.loginEmail.focus();
      });
    },
  },
};
</script>

<style lang="scss">
.returnLogin {
  text-align: center;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}
.loginPage {
  margin: 140px auto;
  width: 477px;
  font-size: 14px;
  .el-form {
    margin-bottom: 40px;
  }
  .el-form-item {
    margin-bottom: 14px;
    display: flex;
    flex-direction: column;
  }
  .el-form-item__label {
    text-align: left;
    line-height: 36px;
    color: #000;
  }
  .el-input__inner {
    height: 45px;
    line-height: 45px;
    border: 2px solid #666;
  }
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #2eaac1;
  }
  .loginTitle {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 11px;
  }
  .loginTips {
    height: 42px;
    margin-bottom: 20px;
  }
  .loginBtn {
    background: #ffc03e;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  .registerBtn {
    background: #c6c6c6;
  }
  .el-checkbox {
    margin-bottom: 12px;
    font-weight: 400;
    color: #000;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #000;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #000;
    border-color: #000;
  }
  .el-checkbox__inner:hover {
    border-color: #000;
  }
  .codeTips {
    text-align: center;
    color: #999999;
  }
}
.separator {
  position: relative;
  height: 1px;
  background: #666666;
  width: 470px;
  margin: 56px auto 20px auto;
}
.separatorText {
  background: #fff;
  padding: 0 10px;
  top: -8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: #666666;
}
.loginType {
  width: 50px;
  height: 50px;
  margin: 9px auto;
  cursor: pointer;
}
.wxLoginImg {
  width: 200px;
  height: 200px;
  margin: 0 auto 26px auto;
  background: rgb(201, 201, 201);
  position: relative;
}
.codeLoading {
  background: rgb(201, 201, 201);
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 200px;
}
.expired {
  position: absolute;
  background: rgb(201, 201, 201);
  top: 0;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.codeBtn.el-button {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 8px;
}
.codeBtn.el-button:hover,
.el-button:active {
  color: #000;
}
.codeBtn.el-button:focus,
.el-button:hover {
  color: #000;
}
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  color: #000;
  margin-right: 2px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}
</style>

<style lang="scss">
.isMobile {
  .loginPage {
    width: 92%;
    margin: 74px auto 130px auto;
    .loginTips {
      margin-bottom: 8px;
    }
    .loginTitle {
      font-size: 24px;
      margin-bottom: 8px;
    }
    .el-form {
      margin-bottom: 20px;
    }
  }
  .separator {
    width: 92%;
  }
}
</style>