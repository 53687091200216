import {
    loginByEmail,
    logOut,
    getInfo,
    getVerifyCode,
    register,
} from "@/api/login";
import {
    getToken,
    setToken,
    removeToken,
} from "@/utils/auth";

const state = {
    token: getToken(),
    nickname: "",
    avatar: "",
    id: 0,
    name: null,
    phone: null,
    gender: "",
    address: "",
    birthday: "",
    email: "",
    wxUnionid: "",
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_USERINFO: (state, userInfo) => {
        state.nickname = userInfo.nickname;
        state.avatar = userInfo.avatar;
        state.id = userInfo.id;
        state.name = userInfo.name;
        state.phone = userInfo.phone;
        state.gender = userInfo.gender;
        state.address = userInfo.address;
        state.birthday = userInfo.birthday;
        state.email = userInfo.email;
        state.wxUnionid =userInfo.wxUnionid;
        // 在这里加其他用户信息字段
    }
};

const actions = {
    // 邮箱登录
    LoginByEmail({ commit }, userInfo) {
        //const { username, password } = userInfo;
        return new Promise((resolve, reject) => {
            loginByEmail(userInfo)
                .then(response => {
                    const { data } = response;
                    console.log(" 后台返回的token是 ：" + data.token);
                    commit("SET_TOKEN", data.token);
                    setToken(data.token);
                    if (data.userInfo) {
                        commit('SET_USERINFO', data.userInfo)
                    }
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // 注册
    Register({ commit }, userInfo) {
        //const { username, password } = userInfo;
        return new Promise((resolve, reject) => {
            register(userInfo)
                .then(response => {
                    const { data } = response;
                    console.log(" 后台返回的token是 ：" + data.token);
                    commit("SET_TOKEN", data.token);
                    setToken(data.token);
                    if (data.memberInfo) {
                        commit('SET_USERINFO', data.memberInfo)
                    }
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // 获取验证码
    // eslint-disable-next-line no-unused-vars
    GetVerifyCode({ commit }, data) {
        return new Promise((resolve, reject) => {
            getVerifyCode(data)
                .then(response => {
                    const { data } = response;
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo(state.token)
                .then(response => {
                    const { data } = response;
                    if (!data) {
                        reject("验证失败，请重新登录。");
                    }
                    if (response.code == 401) {
                        reject("验证失败，请重新登录。");
                        this.$router.push({
                            path: "/login"
                        });
                    }
                    console.log(data);
                    commit('SET_USERINFO', data)
                    //commit("SET_INTRODUCTION", introduction);
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // 退出登录
    LogOut({ commit, state }) {
        return new Promise((resolve, reject) => {
            logOut(state.token)
                .then(() => {
                    commit("SET_TOKEN", "");
                    removeToken();
                    // reset visited views and cached views
                    // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


    // 前端 登出
    ResetToken({ commit }) {
        return new Promise(resolve => {
            commit("SET_TOKEN", "");
            removeToken();
            resolve();
        });
    },
    // 微信登录
    UpdateToken({ commit }, { token }) {
        commit("SET_TOKEN", token);
        setToken(token);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
