import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import VueI18n from 'vue-i18n'
import {
  Carousel,
  CarouselItem,
  Form,
  FormItem,
  Input,
  Radio,
  RadioGroup,
  Pagination,
  Dialog,
  Button,
  Rate,
  MenuItem,
  Submenu,
  Menu,
  Checkbox,
  Upload,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Message,
} from 'element-ui';

Vue.config.productionTip = false;

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'sim',
  messages: {
    'sim': {
      // 首页
      '你好': '你好',
      '首页': '首页',
      '找房': '找房',
      '找工作': '找工作',
      '个人主页': '个人主页',
      '登录/注册': '登录/注册',
      '长租酒店': '长租酒店',
      '品质公寓': '品质公寓',
      '个人房源': '个人房源',
      '生活助手': '生活助手',
      '最新房源推荐': '最新房源推荐',
      '全部': '全部',
      '酒店': '酒店',
      '公寓': '公寓',
      '最新工作机会': '最新工作机会',
      '家政清洁': '家政清洁',
      '搬家运输': '搬家运输',
      '开锁维修': '开锁维修',
      '使用条款': '使用条款',
      '隐私条款': '隐私条款',
      // 找房页
      '位置区域': '位置区域',
      '高级筛选': '高级筛选',
      '房源类型': '房源类型',
      '租赁类型': '租赁类型',
      '好评优先': '好评优先',
      '低价优先': '低价优先',
      '暂无评分': '暂无评分',
      '查看详情': '查看详情',
      "条住客点评": "条住客点评",
      "加载中...": "加载中...",
      "没有查询到符合条件的房源": "没有查询到符合条件的房源",
      '不限': '不限',
      // 找工作页
      '求职类型': '求职类型',
      '全职': '全职',
      '兼职': '兼职',
      '实习': '实习',
      '职位类型': '职位类型',
      '月薪区间': '月薪区间',
      // 个人主页
      '绑定与关联': '绑定与关联',
      '历史浏览记录': '历史浏览记录',
      '系统消息': '系统消息',
      '联系客服': '联系客服',
      '联系我们': '联系我们',
      '退出登录': '退出登录',
      '注销账号': '注销账号',
      '绑定邮箱': '绑定邮箱',
      '未绑定': '未绑定',
      '绑定微信': '绑定微信',
      '已绑定': '已绑定',
      '绑定': '绑定',
      '编辑': '编辑',
      '暂无收藏': '暂无收藏',
      '暂无历史记录': '暂无历史记录',
      '暂无系统消息': '暂无系统消息',
      '账号信息设置': '账号信息设置',
      // 房源详情
      '写评价': '写评价',
      '客房类型': '客房类型',
      '评价': '评价',
      '立即预订': '立即预订',
      '客房设施': '客房设施',
      '预订须知': '预订须知',
      '预订': '预订',
      '房内基础设施': '房内基础设施',
      '楼宇设施/服务': '楼宇设施/服务',
      '暂无评价': '暂无评价',
      '查看更多图片': '查看更多图片',
      '暂无图片': '暂无图片',
      '暂无': '暂无',
      '该房源已下架，看看其他房源吧': '该房源已下架，看看其他房源吧',
      '当前未登录': '当前未登录',
      // 弹窗组件内
      '清洁': '清洁',
      '沟通': '沟通',
      '描述准确度': '描述准确度',
      '确认要退出登录吗？': '确认要退出登录吗？',
      '确认': '确认',
      '确认要注销账号吗？': '确认要注销账号吗？',
      '注销后不能恢复，账号内所有数据都将被删除': '注销后不能恢复，账号内所有数据都将被删除',
      '用微信扫码完成绑定': '用微信扫码完成绑定',
      '输入邮箱': '输入邮箱',
      '输入验证码': '输入验证码',
      '获取验证码': '获取验证码',
      '输入密码': '输入密码',
      '请再次输入密码': '请再次输入密码',
      '电话': '电话',
      '预订优惠码': '预订优惠码',
      '复制': '复制',
      '确定': '确定',
      '点击此处': '点击此处',
      '跳转至酒店官网进行预定': '跳转至酒店官网进行预定',
      '输入个人信息': '输入个人信息',
      '姓名': '姓名',
      // 登录页
      '登录或创建账号': '登录或创建账号',
      '若你已注册，可以通过邮箱密码的方式登录；若通过微信扫码方式登录，未注册用户将自动注册并登录': '若你已注册，可以通过邮箱密码的方式登录；若通过微信扫码方式登录，未注册用户将自动注册并登录',
      '电子邮箱': '电子邮箱',
      '密码': '密码',
      '登录': '登录',
      '还没账号？点击注册': '还没账号？点击注册',
      '我已阅读并同意使用条款及隐私声明': '我已阅读并同意使用条款及隐私声明',
      '其他登录方式': '其他登录方式',
      '请使用微信扫码登录': '请使用微信扫码登录',
      '已过期，点击重新获取': '已过期，点击重新获取',
      '正在获取二维码': '正在获取二维码',
      '注册账号': '注册账号',
      '请填写你的邮箱地址，并点击获取验证码': '请填写你的邮箱地址，并点击获取验证码',
      '请设置密码': '请设置密码',
      '注册': '注册',
      '返回邮箱登录': '返回邮箱登录',
      '填写个人信息': '填写个人信息',
      '头像': '头像',
      '昵称': '昵称',
      '性别': '性别',
      // 工作详情页
      '职位描述': '职位描述',
      '公司简介': '公司简介',
      '该工作已下架，看看其他工作吧': '该工作已下架，看看其他工作吧',
      // vs页
      '价格': '价格',
      '面积': '面积',
      '地理区域': '地理区域',
      '详细地址': '详细地址',
      'WIFI': 'WIFI',
      '冰箱': '冰箱',
      '冷气': '冷气',
      '暖气': '暖气',
      '洗衣机': '洗衣机',
      '衣柜': '衣柜',
      '书桌': '书桌',
      '沙发': '沙发',
      '电吹风': '电吹风',
      '电视': '电视',
      '智能门锁': '智能门锁',
      '独立卫浴': '独立卫浴',
      '独立厨房': '独立厨房',
      '热水壶': '热水壶',
      '电磁炉': '电磁炉',
      '共享厨房': '共享厨房',
      '共享自习室': '共享自习室',
      '对讲门禁': '对讲门禁',
      '保安': '保安',
      '健身房': '健身房',
      '清洁服务': '清洁服务',
    },
    'tra': {
      // 首页
      '你好': '你好',
      '首页': '首頁',
      '找房': '找房',
      '找工作': '找工作',
      '个人主页': '個人主頁',
      '登录/注册': '登錄/註冊',
      '长租酒店': '長租酒店',
      '品质公寓': '品質公寓',
      '个人房源': '個人房源',
      '生活助手': '生活助手',
      '最新房源推荐': '最新房源推薦',
      '全部': '全部',
      '酒店': '酒店',
      '公寓': '公寓',
      '最新工作机会': '最新工作機會',
      '家政清洁': '家政清潔',
      '搬家运输': '搬家運輸',
      '开锁维修': '開鎖維修',
      '使用条款': '使用條款',
      '隐私條款': '隱私條款',
      // 找房页
      '位置区域': '位置區域',
      '高级筛选': '高級篩選',
      '房源类型': '房源類型',
      '租赁类型': '租賃類型',
      '好评优先': '好評優先',
      '低价优先': '低價優先',
      '暂无评分': '暫無評分',
      '查看详情': '查看詳情',
      "条住客点评": "條住客點評",
      "加载中...": "加載中...",
      "没有查询到符合条件的房源": "沒有查詢到符合條件的房源",
      '隐私条款': '隱私條款',
      '不限': '不限',
      // 找工作页
      '求职类型': '求職類型',
      '全职': '全職',
      '兼职': '兼職',
      '实习': '實習',
      '职位类型': '職位類型',
      '月薪区间': '月薪區間',
      // 个人主页
      '绑定与关联': '綁定與關聯',
      '历史浏览记录': '歷史瀏覽紀錄',
      '系统消息': '系統消息',
      '联系客服': '聯繫客服',
      '联系我们': '聯繫我们',
      '退出登录': '退出登錄',
      '注销账号': '註銷帳號',
      '绑定邮箱': '綁定郵箱',
      '未绑定': '未綁定',
      '绑定微信': '綁定微信',
      '已绑定': '已綁定',
      '绑定': '綁定',
      '编辑': '編輯',
      '暂无收藏': '暫無收藏',
      '暂无历史记录': '暫無歷史紀錄',
      '暂无系统消息': '暫無系統消息',
      '账号信息设置': '帳號信息設置',
      // 房源详情
      '写评价': '寫評價',
      '客房类型': '客房類型',
      '评价': '評價',
      '立即预订': '立即預訂',
      '客房设施': '客房設施',
      '预订须知': '預訂須知',
      '预订': '預訂',
      '房内基础设施': '房內基礎設施',
      '楼宇设施/服务': '樓宇設施/服務',
      '暂无评价': '暫無評價',
      '查看更多图片': '查看更多圖片',
      '暂无图片': '暫無圖片',
      '暂无': '暫無',
      '该房源已下架，看看其他房源吧': '該房源已下架，看看其他房源吧',
      '当前未登录': '當前未登錄',
      // 弹窗组件内
      '清洁': '清潔',
      '沟通': '溝通',
      '描述准确度': '描述準確度',
      '确认要退出登录吗？': '確認要退出登錄嗎？',
      '确认': '確認',
      '确认要注销账号吗？': '確認要註銷帳號嗎？',
      '注销后不能恢复，账号内所有数据都将被删除': '註銷後不能恢復，帳號內所有數據都將被刪除',
      '用微信扫码完成绑定': '用微信掃碼完成綁定',
      '输入邮箱': '輸入郵箱',
      '输入验证码': '輸入驗證碼',
      '获取验证码': '獲取驗證碼',
      '输入密码': '輸入密碼',
      '请再次输入密码': '請再次輸入密碼',
      '电话': '電話',
      '预订优惠码': '預訂優惠碼',
      '复制': '複製',
      '确定': '確定',
      '点击此处': '點擊此處',
      '跳转至酒店官网进行预定': '跳轉至酒店官網進行預訂',
      '输入个人信息': '輸入個人信息',
      '姓名': '姓名',
      // 登录页
      '登录或创建账号': '登錄或創建帳號',
      '若你已注册，可以通过邮箱密码的方式登录；若通过微信扫码方式登录，未注册用户将自动注册并登录': '若你已註冊，可以通過郵箱密碼的方式登錄；若通過微信掃碼方式登錄，未註冊用戶將自動註冊並登錄',
      '电子邮箱': '電子郵箱',
      '密码': '密碼',
      '登录': '登錄',
      '还没账号？点击注册': '還沒帳號？點擊註冊',
      '我已阅读并同意使用条款及隐私声明': '我已閱讀並同意使用條款及隱私聲明',
      '其他登录方式': '其他登錄方式',
      '请使用微信扫码登录': '請使用微信掃碼登錄',
      '已过期，点击重新获取': '已過期，點擊重新獲取',
      '正在获取二维码': '正在獲取二維碼',
      '注册账号': '註冊帳號',
      '请填写你的邮箱地址，并点击获取验证码': '請填寫你的郵箱地址，並點擊獲取驗證碼',
      '请设置密码': '請設置密碼',
      '注册': '註冊',
      '返回邮箱登录': '返回郵箱登錄',
      '填写个人信息': '填寫個人信息',
      '头像': '頭像',
      '昵称': '暱稱',
      '性别': '性别',
      // 工作详情页
      '职位描述': '職位描述',
      '公司简介': '公司簡介',
      '该工作已下架，看看其他工作吧': '該工作已下架，看看其他工作吧',
      // vs页
      '价格': '價格',
      '面积': '面積',
      '地理区域': '地理區域',
      '详细地址': '詳細地址',
      'WIFI': 'WIFI',
      '冰箱': '冰箱',
      '冷气': '冷氣',
      '暖气': '暖氣',
      '洗衣机': '洗衣機',
      '衣柜': '衣櫃',
      '书桌': '書桌',
      '沙发': '沙發',
      '电吹风': '電吹風',
      '电视': '電視',
      '智能门锁': '智能門鎖',
      '独立卫浴': '獨立衛浴',
      '独立厨房': '獨立廚房',
      '热水壶': '熱水壺',
      '电磁炉': '電磁爐',
      '共享厨房': '共享廚房',
      '共享自习室': '共享自習室',
      '对讲门禁': '對講門禁',
      '保安': '保安',
      '健身房': '健身房',
      '清洁服务': '清潔服務',
    }
  }
})

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Rate);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Menu);
Vue.use(Checkbox);
Vue.use(Upload);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.prototype.$message = Message;

Vue.mixin({
  data() {
    return {
      isMobile: /Mobi|Android|iPhone/i.test(navigator.userAgent)
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
