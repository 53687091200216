import request from "@/utils/request";

// 登录方法
export function loginByEmail(data) {
  return request({
    url: "/webapi/user/login",
    method: "post",
    data: data
  });
}

// 注册
export function register(data) {
  return request({
    url: "/webapi/user/register",
    method: "post",
    data: data,
  });
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/webapi/user/info",
    method: "get"
  });
}

// 退出方法
export function logOut() {
  return request({
    url: "/webapi/user/logout",
    method: "get"
  });
}

// 获取邮箱验证码
export function getVerifyCode(data) {
  return request({
    url: "/webapi/common/getVerifyCode",
    method: "get",
    params: {
      email: data
    }
  });
}

