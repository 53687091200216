<template>
  <el-dialog
    class="horseOn confirm"
    title=""
    :visible.sync="contentVisible"
    :close-on-click-modal="false"
    center
  >
    <div class="confirmTitle">确认要退出登录吗？</div>
    <div class="confirmBtnLine">
      <div class="confirmBtn no" @click="contentVisible = false">取消</div>
      <div class="confirmBtn yes" @click="handleQuit">确认</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      contentVisible: false,
    };
  },
  methods: {
    async handleQuit() {
      await this.$store.dispatch("user/LogOut");
      window.location.href = `/?lan=${this.$i18n.locale}`;
    },
  },
};
</script>

<style lang="scss">
.isMobile {
  .confirm {
    .el-dialog {
      width: 331px;
      height: 172px;
    }
    .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 44px 20px;
  }
  }
}
.confirm {
  .el-dialog {
    margin-top: 32vh !important;
    width: 331px;
    height: 172px;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 44px 20px;
    text-align: center;
  }
  .confirmTitle {
    margin-bottom: 24px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.confirmBtnLine {
  display: flex;
  justify-content: center;
  .confirmBtn {
    padding: 6px 0;
    border-radius: 43px;
    box-sizing: border-box;
    margin: 0 10px;
    width: 90px;
    cursor: pointer;
    text-align: center;
  }
  .confirmBtn.no {
    border: 1px solid #000000;
  }
  .confirmBtn.yes {
    background: #ffc03e;
  }
}
</style>