<template>
  <div class="scrollContainer">
    <Header />
    <div
      class="content roomDet"
      v-if="roomInfo && roomInfo.enabled && !roomInfo.deleted"
    >
      <div class="houseHead">
        <div class="houseHeadLeft">
          <div class="titleLine" style="margin-bottom: 6px">
            <!-- <div class="houseType">{{ roomInfo.houseType }}</div> -->
            <div class="houseTitle">
              <span class="houseType">{{ roomInfo.houseType }}</span>
              <span
                style="cursor: pointer; color: #2eaac1"
                @click="openHouses(roomInfo.houseId)"
                >{{ roomInfo.houseTitle }}</span
              >
              丨 {{ roomInfo.title }}
              <span class="likeBtn" @click.stop="clickCollect">
                <img
                  v-if="!roomInfo.isCollected"
                  src="@/assets/like.png"
                  alt=""
                />
                <img v-else src="@/assets/like_yellow.png" alt="" />
              </span>
            </div>
            <!-- <div class="likeBtn" @click.stop="clickCollect">
              <img
                v-if="!roomInfo.isCollected"
                src="@/assets/like.png"
                alt=""
              />
              <img v-else src="@/assets/like_yellow.png" alt="" />
            </div> -->
          </div>
          <div class="desLine" v-if="roomInfo.area && roomInfo.roomNumStr">
            {{ roomInfo.area }} ·
            {{ roomInfo.roomNumStr }}
          </div>
          <div class="labelLine">
            <div
              class="labelItem"
              v-for="(label, idx) in roomInfo.tagList"
              :key="idx"
            >
              {{ label }}
            </div>
          </div>
        </div>
        <div class="houseHeadRight">
          <div class="flex">
            <div class="price">
              HKD <span class="priceNum">{{ roomInfo.price }}</span> /{{
                roomInfo.payTimeUnit
              }}
            </div>
            <div class="checkBtn" @click="clickBook">{{ $t("立即预订") }}</div>
          </div>
        </div>
      </div>
      <div class="housePicBlock" id="housePicBlock">
        <div class="picLeft">
          <div class="picItem">
            <img
              v-if="roomInfo.imageList"
              class="imgCut"
              :src="roomInfo.imageList[0]"
              alt=""
            />
          </div>
        </div>
        <div class="picRight" v-show="!isMobile">
          <div class="picItem" v-for="item in 4" :key="item">
            <img
              v-if="roomInfo.imageList && roomInfo.imageList[item]"
              class="imgCut"
              :src="roomInfo.imageList[item]"
              alt=""
            />
            <div v-else class="nullPic">
              <img src="@/assets/logo.png" alt="" />
            </div>
          </div>
        </div>
        <div
          v-show="false"
          v-if="roomInfo.imageList && roomInfo.imageList.length > 5"
        >
          <img
            v-for="(imgItem, idx) in roomInfo.imageList.slice(
              5,
              roomInfo.imageList.length
            )"
            :key="idx"
            class="imgCut"
            :src="imgItem"
            alt=""
          />
        </div>
        <div
          class="allPicBtn"
          @click="viewImgs"
          v-if="roomInfo.imageList && roomInfo.imageList.length > 5"
        >
          {{ $t("查看更多图片") }}
        </div>
      </div>
      <div class="houseDesBlock">
        <div class="houseDesTitle">客房描述</div>
        <div class="houseDes">
          {{ roomInfo.description ? roomInfo.description : "暂无" }}
        </div>
      </div>
      <div class="houseDesBlock">
        <div class="houseDesTitle">{{ $t("客房设施") }}</div>
        <div class="secondTitle">{{ $t("房内基础设施") }}</div>
        <div class="facilityList">
          <div
            class="facilityItem"
            :class="{
              nullFacility: roomInfo.baseFacility[item.value] == 0,
            }"
            v-for="(item, idx) in sortedBaseFacility"
            :key="idx"
          >
            <img
              v-if="roomInfo.baseFacility[item.value] != 0"
              src="@/assets/checked.png"
              alt=""
            />
            <img v-else src="@/assets/unchecked.png" alt="" />
            {{ $t(item.label) }}
          </div>
        </div>
        <div class="secondTitle">{{ $t("楼宇设施/服务") }}</div>
        <div class="facilityList">
          <div
            class="facilityItem"
            :class="{
              nullFacility: roomInfo.serviceFacility[item.value] == 0,
            }"
            v-for="(item, idx) in sortedServiceFacility"
            :key="idx"
          >
            <img
              v-if="roomInfo.serviceFacility[item.value] != 0"
              src="@/assets/checked.png"
              alt=""
            />
            <img v-else src="@/assets/unchecked.png" alt="" />{{
              $t(item.label)
            }}
          </div>
        </div>
      </div>
      <div class="houseDesBlock">
        <div class="houseDesTitle">其他描述</div>
        <div class="houseDes">
          {{ roomInfo.otherDescription ? roomInfo.otherDescription : "暂无" }}
        </div>
      </div>
      <div class="houseDesBlock">
        <div class="houseDesTitle">{{ $t("预订须知") }}</div>
        <div class="houseDes">
          {{ roomInfo.bookInstruction ? roomInfo.bookInstruction : $t("暂无") }}
        </div>
      </div>
      <BookHotelDialog
        ref="bookHotelDialog"
        :houseTitle="roomInfo.houseTitle"
        :roomTitle="roomInfo.title"
        :houseId="roomInfo.houseId"
        :roomId="roomInfo.id"
        :promoCode="roomInfo.promoCode"
        :bookUrl="roomInfo.bookUrl"
        :promoCodeRule="roomInfo.promoCodeRule"
      />
    </div>
    <div class="null" v-else>
      {{ loading ? $t("加载中...") : $t("该房源已下架，看看其他房源吧") }}
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
import Viewer from "viewerjs";
import BookHotelDialog from "@/components/BookHotelDialog.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCollect: false,
      loading: false,
      keywords: "",
      houseType: 0,
      des: "afsafasf\nsafasfg\n\n     fafsaasf   \n fasfsa",
      baseFacility: [
        {
          label: "WIFI",
          value: "wifi",
        },
        {
          label: "冰箱",
          value: "fridge",
        },
        {
          label: "冷气",
          value: "cooling",
        },
        {
          label: "暖气",
          value: "heating",
        },
        {
          label: "洗衣机",
          value: "washer",
        },
        {
          label: "衣柜",
          value: "wardrobe",
        },
        {
          label: "书桌",
          value: "desk",
        },
        {
          label: "沙发",
          value: "sofa",
        },
        {
          label: "电吹风",
          value: "hairDryer",
        },
        {
          label: "电视",
          value: "television",
        },
        {
          label: "智能门锁",
          value: "intelligentLock",
        },
        {
          label: "独立卫浴",
          value: "bathroom",
        },
        {
          label: "独立厨房",
          value: "kitchen",
        },
        {
          label: "热水壶",
          value: "kettle",
        },
        {
          label: "电磁炉",
          value: "inductionCooker",
        },
      ],
      serviceFacility: [
        {
          label: "共享厨房",
          value: "sharedKitchen",
        },
        {
          label: "共享自习室",
          value: "sharedStudyRoom",
        },
        {
          label: "对讲门禁",
          value: "intercom",
        },
        {
          label: "保安",
          value: "securityGuard",
        },
        {
          label: "健身房",
          value: "gym",
        },
        {
          label: "清洁服务",
          value: "cleanService",
        },
      ],
      roomInfo: {},
    };
  },
  computed: {
    sortedBaseFacility() {
      return this.baseFacility.slice().sort((a, b) => {
        return (
          this.roomInfo.baseFacility[b.value] -
          this.roomInfo.baseFacility[a.value]
        );
      });
    },
    sortedServiceFacility() {
      return this.serviceFacility.slice().sort((a, b) => {
        return (
          this.roomInfo.serviceFacility[b.value] -
          this.roomInfo.serviceFacility[a.value]
        );
      });
    },
    ...mapGetters(["isLogin"]),
  },
  components: {
    Header,
    Footer,
    BookHotelDialog,
  },
  async created() {
    document.title = "客房详情 - 马上住 HorseOnLiving";
    this.getRoomInfo(this.$route.query.id);
  },
  beforeDestroy() {
    if (this.viewer && this.viewer.destroy) {
      this.viewer.destroy();
    }
  },
  methods: {
    async clickCollect() {
      const collectRes = await request.get("/webapi/userCollect/collect", {
        params: {
          ht: 2,
          htdId: this.roomInfo.id,
        },
      });
      if (collectRes.code == 200) {
        this.roomInfo.isCollected = !this.roomInfo.isCollected;
      }
    },
    clickBook() {
      if (!this.isLogin) {
        return this.$message({
          message: this.$t("当前未登录"),
        });
      }
      this.$refs["bookHotelDialog"].contentVisible = true;
    },
    openHouses(id) {
      window.open(`/houses/detail?lan=${this.$i18n.locale}&id=${id}`);
    },
    async getRoomInfo(id) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const roomInfoRes = await request.get("/webapi/roomType/detail", {
        params: {
          roomTypeId: id,
          lang: this.$route.query.lan == "tra" ? 1 : 0,
        },
      });
      this.loading = false;
      if (roomInfoRes.code == 200) {
        this.roomInfo = roomInfoRes.data;
        this.$nextTick(() => {
          this.viewer = new Viewer(document.getElementById("housePicBlock"), {
            toolbar: {
              prev: {
                show: 1,
                size: "large",
              },
              next: {
                show: 1,
                size: "large",
              },
            },
            navbar: true,
            title: false,
            movable: false,
            minZoomRatio: 0.3,
            maxZoomRatio: 3,
            viewed(res) {
              console.log("viewed: ", res);
            },
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.houseHeadLeft {
  .titleLine {
    margin-bottom: 20px;
  }
}
.houseHeadRight {
  flex-direction: column-reverse;
}
.roomDet {
  .houseHead .houseHeadRight .price {
    margin-right: 10px;
  }
  .houseHead {
    margin-bottom: 36px;
    .flex {
      margin: 0;
    }
  }
}
.isMobile {
  .roomDet {
    .checkBtn {
      margin-left: 0;
    }
    .houseHead {
      margin-bottom: 12px;
    }
  }
  .facilityList .facilityItem {
    width: 140px;
  }
}
</style>

<style lang="scss">
.nullPic {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>