const getters = {
    // 有token表示已经登录
    isLogin: state => !!state.user.token && state.user.token !== "undefined" ,
    // isLogin: state => !state.user.token || state.user.token == "undefined" ,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    nickname: state => state.user.nickname,
    id: state => state.user.id,
    address: state => state.user.address,
    gender: state => state.user.gender,
    phone: state => state.user.phone,
    name: state => state.user.name,
    birthday: state => state.user.birthday,
    email: state => state.user.email,
    wxUnionid: state => state.user.wxUnionid,
    // introduction: state => state.user.introduction,
    // roles: state => state.user.roles,
    // permission_routes: state => state.permission.routes,
    // errorLogs: state => state.errorLog.logs
  }
  export default getters
  