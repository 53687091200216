<template>
  <div class="scrollContainer">
    <Header />
    <div class="filterBlock">
      <div class="filter">
        <div class="filterType">{{ $t("位置区域") }}</div>
        <div class="filterItemList">
          <div
            class="labelItem"
            :class="{ selected: location == '' }"
            @click="clickLocation('')"
          >
            不限
          </div>
          <div
            class="labelItem"
            v-for="(labelItem, idx) in locationList"
            :key="idx"
            :class="{ selected: location == labelItem.value }"
            @click="clickLocation(labelItem.value)"
          >
            {{ labelItem.label }}
          </div>
        </div>
      </div>
      <div class="filter">
        <div class="filterType">{{ $t("高级筛选") }}</div>
        <div class="filterItemList">
          <el-menu
            :default-active="houseType.value"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect1"
            :menu-trigger="isMobile ? 'click' : 'hover'"
          >
            <el-submenu index="1">
              <template slot="title">{{ houseType.label }}</template>
              <el-menu-item
                v-for="item in houseTypeList"
                :key="item.value"
                :index="item.value"
                >{{ item.label }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2"
            :menu-trigger="isMobile ? 'click' : 'hover'"
          >
            <el-submenu index="2">
              <template slot="title">{{ period.label }}</template>
              <el-menu-item
                v-for="item in periodList"
                :key="item.value"
                :index="item.value"
                >{{ item.label }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect3"
            :menu-trigger="isMobile ? 'click' : 'hover'"
          >
            <el-submenu index="3">
              <template slot="title">{{ rentType.label }}</template>
              <el-menu-item
                v-for="item in rentTypeList"
                :key="item.value"
                :index="item.value"
                >{{ item.label }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="sort">
        <div
          class="sortType"
          :class="{ selected: sortType == 0 }"
          @click="clickSortType(0)"
        >
          最新房源
        </div>
        <div
          class="sortType"
          :class="{ selected: sortType == 1 }"
          @click="clickSortType(1)"
        >
          {{ $t("好评优先") }}
        </div>
        <div
          class="sortType"
          :class="{ selected: sortType == 2 }"
          @click="clickSortType(2)"
        >
          {{ $t("低价优先") }}
        </div>
      </div>
    </div>
    <template v-if="housesList && housesList.length">
      <div class="itemList houses">
        <div
          class="houseItem"
          @click="openHouses(item.id)"
          v-for="item in housesList"
          :key="item.id"
        >
          <div class="houseCover">
            <div class="roomType">{{ item.type }}</div>
            <img v-if="item.imageList" class="imgCut" :src="item.imageList[0]" alt="" />
            <img v-else-if="item.roomTypeList && item.roomTypeList[0].imageList" class="imgCut" :src="item.roomTypeList[0].imageList[0]" alt="" />
          </div>
          <div class="houseInfo">
            <div class="houseInfoInner">
              <div class="houseInfoInnerLeft">
                <div class="houseTitle">{{ item.title }}</div>
                <div class="desLine">{{item.region}} · {{item.district}}</div>
                <div class="labelLine">
                  <!-- <div class="labelItem" v-if="item.region">
                    {{ item.region }}
                  </div>
                  <div class="labelItem" v-if="item.district">
                    {{ item.district }}
                  </div> -->
                  <div
                    class="labelItem"
                    v-for="location in item.locationList"
                    :key="location"
                  >
                    {{ location }}
                  </div>
                  <div
                    class="labelItem"
                    v-for="(label, idx) in item.tagList"
                    :key="idx"
                  >
                    {{ label }}
                  </div>
                </div>
              </div>
              <div class="houseScore">
                <div class="viewNum">
                  <img src="@/assets/view.png" alt="" />
                  被查看了{{ item.clickNum }}次
                </div>
                <div class="score">
                  <div v-if="item.commentNum">
                    {{ item.commentNum }}{{ $t("条住客点评") }}
                  </div>
                  <div class="scoreNum big">
                    {{ item.score ? item.score : $t("暂无评分") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="price">
              HKD <span class="priceNum">{{ item.price }}</span> 起/{{
                item.payTimeUnit
              }}
            </div>
            <div class="checkBtn">{{ $t("查看详情") }}</div>
          </div>
        </div>
      </div>
      <div class="pageLine">
        <el-pagination
          :page-size="10"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="pageNum"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </template>
    <template v-else>
      <div class="itemList empty houses">
        {{ loading ? $t("加载中...") : $t("没有查询到符合条件的房源") }}
      </div>
    </template>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      activeIndex: "1",
      sortType: 0,
      houseType: {
        label: "房源类型",
        value: "1",
      },
      houseTypeList: [
        {
          label: "不限",
          value: "1",
        },
        {
          label: "酒店",
          value: "2",
        },
        {
          label: "公寓",
          value: "3",
        },
        {
          label: "个人房源",
          value: "4",
        },
      ],
      houseTypeMap: {
        1: "",
        2: "酒店",
        3: "公寓",
        4: "个人房源",
      },
      period: {
        label: "租期",
        value: "1",
      },
      periodList: [
        {
          label: "不限",
          value: "1",
        },
        {
          label: "日租",
          value: "2",
        },
        {
          label: "月租",
          value: "3",
        },
        {
          label: "年租",
          value: "4",
        },
      ],
      periodMap: {
        1: "",
        2: "日租",
        3: "月租",
        4: "年租",
      },
      rentType: {
        label: "租赁类型",
        value: "1",
      },
      rentTypeList: [
        {
          label: "不限",
          value: "1",
        },
        {
          label: "整租",
          value: "2",
        },
        {
          label: "合租",
          value: "3",
        },
      ],
      rentTypeMap: {
        1: "",
        2: "整租",
        3: "合租",
      },
      pageNum: 1,
      housesList: [],
      total: 0,
      locationList: [],
      location: "",
      keyword: "",
      loading: false,
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    document.title = "找房子 - 马上住 HorseOnLiving";
    this.getLocationList();
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.type) {
      this.houseType = this.houseTypeList.find(
        (item) => item.value == this.$route.query.type
      );
    }
    this.getHousesList();
  },
  methods: {
    clickLocation(value) {
      if (this.loading) {
        return;
      }
      this.location = value;
      this.pageNum = 1;
      this.getHousesList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getHousesList();
      console.log(`当前页: ${val}`);
    },
    // 房源列表
    async getHousesList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const housesListRes = await request.get("/webapi/house/list", {
        params: {
          pageNum: this.pageNum,
          pageSize: 10,
          type: this.houseTypeMap[this.houseType.value],
          locations: this.location,
          leaseTerm: this.periodMap[this.period.value],
          leaseType: this.rentTypeMap[this.rentType.value],
          sort: this.sortType,
          title: this.keyword,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        },
      });
      if (housesListRes.code == 200) {
        this.housesList = housesListRes.data.records;
        this.total = housesListRes.data.total;
        this.loading = false;
      } else this.housesList = [];
      this.loading = false;
    },
    async getLocationList() {
      const locationListRes = await request.get(
        "/webapi/house/getSearchParam",
        {
          params: {
            lang: this.$route.query.lan == 'tra' ? 1 : 0,
          }
        }
      );
      if (locationListRes.code == 200) {
        this.locationList = locationListRes.data.regionList.concat(
          locationListRes.data.districtList
        ).concat(locationListRes.data.locationList);
      }
    },
    openHouses(id) {
      window.open(`/houses/detail?lan=${this.$i18n.locale}&id=${id}`);
    },
    handleSelect1(key, keyPath) {
      if (keyPath[0] == "1") {
        if (keyPath[1] != "1") {
          this.houseType = this.houseTypeList.find(
            (item) => item.value == keyPath[1]
          );
        } else
          this.houseType = {
            label: "房源类型",
            value: "1",
          };
      }
      this.pageNum = 1;
      this.getHousesList();
      console.log("key", key, "keyPath", keyPath);
    },
    handleSelect2(key, keyPath) {
      if (keyPath[0] == "2") {
        if (keyPath[1] != "1") {
          this.period = this.periodList.find(
            (item) => item.value == keyPath[1]
          );
        } else
          this.period = {
            label: "租期",
            value: "1",
          };
      }
      this.pageNum = 1;
      this.getHousesList();
      console.log("key", key, "keyPath", keyPath);
    },
    handleSelect3(key, keyPath) {
      if (keyPath[0] == "3") {
        if (keyPath[1] != "1") {
          this.rentType = this.rentTypeList.find(
            (item) => item.value == keyPath[1]
          );
        } else
          this.rentType = {
            label: "租赁类型",
            value: "1",
          };
      }
      this.pageNum = 1;
      this.getHousesList();
      console.log("key", key, "keyPath", keyPath);
    },
    clickSortType(type) {
      this.sortType = type;
      this.pageNum = 1;
      this.getHousesList();
    },
  },
};
</script>

<style lang="scss">
.empty.itemList {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
}
.filterBlock {
  margin: 85px auto 24px auto;
  max-width: 1200px;
  .filter {
    display: flex;
    margin-bottom: 6px;
    color: #333333;
    .filterType {
      background: #f0f2f5;
      font-size: 14px;
      font-weight: 500;
      width: 150px;
      text-align: center;
      padding-top: 20px;
      box-sizing: border-box;
      flex-shrink: 0;
    }
    .filterItemList {
      background: #fcfcfc;
      display: flex;
      width: 100%;
      padding: 20px 22px 12px 22px;
      flex-wrap: wrap;
      .labelItem {
        margin-bottom: 8px;
        box-sizing: border-box;
        border: 1px solid transparent;
      }
      .selected.labelItem {
        background: #e9f8f8;
        border: 1px solid #2eaac1;
        color: #000000;
      }
      .advancedFilter {
        font-size: 14px;
        color: #000;
        margin-right: 24px;
        cursor: pointer;
        width: 76px;
        text-align: center;
        .el-button,
        .el-dropdown {
          color: #000;
        }
      }
    }
  }
  .sort {
    display: flex;
    margin: 20px 0;
    .sortType {
      font-size: 14px;
      margin-right: 30px;
      cursor: pointer;
    }
    .sortType.selected {
      color: #2eaac1;
      font-weight: 500;
    }
  }
}
.labelLine {
  display: flex;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.labelItem {
  background: #f5f7fa;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 13px;
  box-sizing: border-box;
  margin-right: 16px;
  color: #616c88;
  cursor: pointer;
  margin-bottom: 3px;
}
.price {
  margin: 0 0 10px auto;
  font-size: 20px;
  .priceNum {
    font-size: 28px;
    font-weight: 500;
  }
}
.checkBtn {
  margin-left: auto;
  background: #ffc03e;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.checkBtn:hover {
  background: #ffac00;
}

.itemList {
  margin: 0 auto 30px auto;
  max-width: 1200px;
  min-height: calc(100vh - 430px);
  .houseItem:hover {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  }
  .houseItem {
    width: 100%;
    height: 280px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    padding: 24px 30px 24px 40px;
    line-height: 1.5;
    .labelLine {
      margin-bottom: 42px;
      .labelItem {
        margin-right: 6px;
      }
    }
    .houseCover {
      width: 286px;
      height: 234px;
      border-radius: 4px;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 16px;
      position: relative;
      background: #fcedcd;
    }
    .houseInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      .houseInfoInner {
        display: flex;
        .houseScore {
          margin-left: auto;
          height: 34px;
          .score {
            display: flex;
            align-items: center;
          }
          .viewNum {
            padding: 4px 6px;
            border: 1px solid #8592a6;
            border-radius: 2px;
            font-size: 12px;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              margin-right: 2px;
            }
          }
          div {
            margin-right: 10px;
          }
          div:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .houseInfoInnerLeft {
      display: flex;
      margin-bottom: 6px;
      flex-direction: column;
      .houseTitle {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 6px;
      }
    }
    .desLine {
      margin-bottom: 7px;
    }
  }
}
.el-menu--horizontal {
  z-index: 1 !important;
}
.el-menu {
  background: transparent;
}
</style>

<style lang="scss">
.isMobile {
  .checkBtn {
    padding: 8px 16px;
    font-size: 14px;
  }
  .labelItem {
    margin-right: 8px;
    font-size: 12px;
  }
  .filterBlock {
    width: 95%;
    margin: 60px auto 16px auto;
    .sort {
      margin: 12px 0 6px 0;
      .sortType {
        font-size: 13px;
        margin-right: 20px;
      }
    }
    .filter {
      margin-bottom: 4px;
      .filterType {
        width: 72px;
        padding-top: 12px;
        font-size: 13px;
      }
      .filterItemList {
        padding: 12px 10px 6px 10px;
        flex-wrap: nowrap;
        overflow: auto;
        .advancedFilter {
          margin-right: 4px;
        }
        .labelItem {
          margin-bottom: 5px;
          flex-shrink: 0;
        }
      }
    }
    .el-dropdown {
      font-size: 13px;
    }
  }

  .itemList {
    .houseItem {
      width: 95%;
      margin: 0 auto 12px auto;
      height: auto;
      border: 1px solid #e5e5e5;
      padding: 12px 10px;
      line-height: 1;
      .houseCover {
        width: 108px;
        height: 87px;
        margin-right: 8px;
      }
      .houseInfo {
        .houseInfoInner {
          flex-direction: column;
          margin-bottom: 4px;
          .houseScore {
            flex-direction: column-reverse;
            margin-left: 0;
            align-items: flex-start;
            height: auto;
            .score {
              flex-direction: row-reverse;
              margin-bottom: 6px;
              .scoreNum {
                margin-right: 4px;
              }
            }
            .viewNum {
              padding: 2px 4px;
              font-size: 11px;
            }
          }
        }
      }
      .houseInfoInnerLeft {
        margin-bottom: 0;
        .houseTitle {
          font-size: 15px;
          margin-bottom: 6px;
          line-height: 1.2;
        }
      }
      .desLine {
        margin-bottom: 8px;
      }
      .labelLine {
        margin-bottom: 4px;
      }
    }
  }
  .desLine {
    font-size: 12px;
  }
  .roomType {
    padding: 4px;
  }
  .price {
    font-size: 12px;
    margin-bottom: 6px;
    .priceNum {
      font-size: 16px;
    }
  }
  .houses.itemList {
    min-height: calc(100vh - 348px);
  }
}
</style>