<template>
  <div class="likeRoomItem" @click="clickRoom" v-if="item">
    <div :class="{ selected: selected }">
      <div class="roomType">{{ item.houseType }}</div>
      <div class="roomCover">
        <img class="imgCut" :src="item.imageList[0]" alt="" />
      </div>
      <div class="roomDes">
        <div class="roomTitle textOverflow">
          {{ item.houseTitle }}&nbsp; | &nbsp;{{ item.title }}
        </div>
        <div class="roomLabel textOverflow">
          {{ item.area }} · {{ item.roomNumStr }}
        </div>
        <div class="roomPrice">
          HKD <span class="priceNum">{{ item.price }}</span> /{{
            item.payTimeUnit
          }}
        </div>
        <!-- <div class="houseScore">
          <div class="scoreNum">4.8</div>
          <div>10条住客点评</div>
        </div> -->
      </div>
    </div>
    <div class="selectedIcon" v-if="selected">
      <img src="@/assets/selected.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: false,
    };
  },
  methods: {
    clickRoom() {
      if (this.from == "housesVs") {
        if (!this.selected && !this.selectDisabled) {
          this.$emit("selectHandler", this.item.id);
          return (this.selected = true);
        }
        if (this.selected) {
          this.$emit("cancleSelectHandler", this.item.id);
          return (this.selected = false);
        }
      }
      if (this.from == "personal") {
        if (this.item.enabled && !this.item.deleted) {
          if (this.item.houseType == this.$t("个人房源")) {
            window.open(
              `/houses/detail?lan=${this.$i18n.locale}&id=${this.item.houseId}`
            );
          } else
            window.open(
              `/houses/room/detail?lan=${this.$i18n.locale}&id=${this.item.id}`
            );
        }
        else this.$message.error("该房源已下架");
      }
    },
  },
  props: ["item", "from", "selectDisabled"],
};
</script>

<style lang="scss">
.likeRoomItem:nth-child(5n) {
  margin-right: 0;
}
.roomType {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 6px;
  background: #ece1c1;
  border-radius: 4px 0px;
  color: #616c88;
  font-size: 12px;
}
.likeRoomItem {
  width: 192px;
  height: 276px;
  margin: 0 14px 18px 0;
  border-radius: 4px;
  overflow: hidden;
  line-height: 1.5;
  background: #f7f7f7;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  .selected {
    opacity: 0.4;
  }
  .selectedIcon {
    position: absolute;
    width: 16px;
    height: 16px;
    background: #ffc03e;
    top: 7px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 12px;
    }
  }

  .roomCover {
    width: 195px;
    height: 157px;
  }
  .roomDes {
    padding: 10px 10px 8px 10px;
  }
  .roomTitle {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 1px;
    height: 48px;
  }
  .roomTitle.textOverflow {
    -webkit-line-clamp: 2;
  }
  .roomLabel {
    color: #666;
    margin-bottom: 1px;
  }
  .roomPrice {
    .priceNum {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .roomLabel.textOverflow {
    -webkit-line-clamp: 1;
  }
}
</style>

<style lang="scss">
.isMobile {
  .likeList {
    width: 95%;
    margin: 0 auto;
  }
  .likeRoomItem {
    width: 150px;
    height: 220px;
    margin: 0 12px 12px 0;
    line-height: 1.2;
    .roomCover {
      width: 166px;
      height: 134px;
    }
    .roomDes {
      padding: 5px 7px 9px 7px;
    }
    .roomTitle {
      font-size: 15px;
      height: 36px;
      margin-bottom: 4px;
    }
    .roomLabel {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .roomPrice {
      font-size: 12px;
      margin-bottom: 4px;
      .priceNum {
        font-size: 14px;
      }
    }
  }
  .likeRoomItem:nth-child(2n) {
    margin-right: 0;
  }
}
</style>