<template>
  <div v-if="item && !isDelete">
    <div class="historyDate">{{ item.createDate }}</div>
    <div class="historyItem" v-if="item">
      <!-- ht	类型：1=房源，2=房型，3=工作，4=生活助手 -->
      <template >
        <div class="historyType" @click="clickHistory()">{{ htMap[item.ht] }}</div>
        <div class="historyTitle textOverflow" @click="clickHistory()">{{ item.title }}</div>
      </template>
      <div class="deleteBtn" @click="clickDelete()">
        <img src="@/assets/delete.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      htMap: {
        1: "房源",
        2: "房型",
        3: "工作",
        4: "生活助手",
      },
      loading: false,
      isDelete: false,
    };
  },
  methods: {
    clickHistory() {
      if (this.item.ht == 1) {
        window.open(
          `/houses/detail?lan=${this.$i18n.locale}&id=${this.item.htdId}`
        );
        return;
      }
      if (this.item.ht == 2) {
        window.open(
          `/houses/room/detail?lan=${this.$i18n.locale}&id=${this.item.htdId}`
        );
        return;
      }
      if (this.item.ht == 3) {
        window.open(
          `/jobs/detail?lan=${this.$i18n.locale}&id=${this.item.htdId}`
        );
        return;
      }
      if (this.item.ht == 4) {
        window.open(
          `/lifehelper?lan=${this.$i18n.locale}&keyword=${this.item.title}`
        );
        return;
      }
    },
    async clickDelete() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const deleteRes = await request.get("/webapi/userViewLog/delete", {
        params: {
          id: this.item.id,
        },
      });
      this.loading = false;
      if (deleteRes.code == 200) {
        this.isDelete = true;
        this.$emit("deleteHandler", this.isDelete);
        this.$message({
          message: "删除成功",
          type: "success",
        });
      }
    }
  },
  props: ["item"],
};
</script>

<style lang="scss">
.isMobile {
  .historyItem .deleteBtn {
    img {
      width: 20px;
    }
  }
}
</style>