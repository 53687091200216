<template>
  <div class="scrollContainer">
    <Header />
    <div
      class="content"
      v-if="jobInfo && jobInfo.enabled && !jobInfo.deleted"
    >
      <div class="jobHead">
        <div class="jobTitleLine">
          <div class="houseType">{{ jobInfo.workForm }}</div>
          <div class="jobTitle">
            {{ jobInfo.title }}
          </div>
          <div class="likeBtn" @click.stop="clickCollect">
            <img v-if="!isCollect" src="@/assets/like.png" alt="" />
            <img v-else src="@/assets/like_yellow.png" alt="" />
          </div>
        </div>
        <div class="labelLine">
          <div class="labelItem">
            {{ jobInfo.occupation }}
          </div>
          <div class="labelItem">
            {{ jobInfo.position }}
          </div>
        </div>
        <div class="company">
          <div class="companyImg">
            <img
              v-if="jobInfo.companyAvatar"
              class="imgCut"
              :src="jobInfo.companyAvatar"
              alt=""
            />
            <img v-else class="imgCut" src="@/assets/company.png" alt="" />
          </div>
          <div class="companyName">{{ jobInfo.companyName }}</div>
        </div>

        <div class="houseDet">
          <div v-if="jobInfo.address">
            <img src="@/assets/location.png" alt="" />{{ jobInfo.address }}
          </div>
          <div v-if="jobInfo.contactPerson">
            <img src="@/assets/person.png" alt="" />{{ jobInfo.contactPerson }}
          </div>
          <div v-if="jobInfo.phone">
            <img src="@/assets/tele.png" alt="" />{{ jobInfo.phone }}
          </div>
          <div v-if="jobInfo.email">
            <img src="@/assets/email.png" alt="" />{{ jobInfo.email }}
          </div>
          <div v-if="jobInfo.createDate">
            <img src="@/assets/date.png" alt="" />{{ jobInfo.createDate }}
          </div>
        </div>
      </div>
      <div class="jobDetBlock">
        <div class="detTitle">{{ $t("职位描述") }}</div>
        <div class="detText">
          {{ jobInfo.description ? jobInfo.description : $t("暂无") }}
        </div>
      </div>
      <div class="jobDetBlock">
        <div class="detTitle">{{ $t("公司简介") }}</div>
        <div class="detText">
          {{ jobInfo.companyProfile ? jobInfo.companyProfile : $t("暂无") }}
        </div>
      </div>
    </div>
    <div class="null" v-else>
      {{ loading ? $t("加载中...") : $t("该工作已下架，看看其他工作吧") }}
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import request from "@/utils/request";
export default {
  data() {
    return {
      keywords: "",
      houseType: 0,
      jdText:
        "中国领先的独立游戏研发与发行公司，专注于创作新颖、好玩且有灵魂的游戏，服务于新兴的中产阶级，希望成为一家真正意义上可持续发展的创意游戏公司。并致力于推动独立游戏在中国的发展。<br/>代表作：江南百景图，中国式家长，黑暗料理王，超脱力医院，归家异途，决战喵星等。<br/><br/>已覆盖iOS、安卓、Steam，Switch和PS4等平台。作品长期被苹果、Google、任天堂和索尼推荐，是中国唯一的具有全平台发行能力的独立游戏公司。<br/>所获奖项：新周刊2018年度游戏，B站2018最受欢迎的国产游戏，苹果年度精选游戏，Google Play年度独立制作游戏等。<br/><br/>企业愿景：创作具有文化影响力的游戏，扩大游戏受众群，使更多人领略游戏之美。<br/>我们需要你们的加入，一起来做出不一样的游戏，改变世界对游戏的看法。",
      companyText: "这是一家不错的公司",
      isCollect: false,
      loading: false,
      jobInfo: null,
      jobId: null,
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    document.title = "工作详情 - 马上住 HorseOnLiving";
    if (this.$route.query.id) {
      this.jobId = this.$route.query.id;
      this.getJobInfo(this.jobId);
    } else window.location.href = `/?lan=${this.$i18n.locale}`;
  },
  methods: {
    async clickCollect() {
      const collectRes = await request.get("/webapi/userCollect/collect", {
        params: {
          ht: 3,
          htdId: this.jobInfo.id,
        },
      });
      if (collectRes.code == 200) {
        this.jobInfo.isCollected = !this.jobInfo.isCollected;
      }
    },
    async getJobInfo(id) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const infoRes = await request.get("/webapi/job/detail", {
        params: {
          jobId: id,
          lang: this.$route.query.lan == 'tra' ? 1 : 0,
        },
      });
      this.loading = false;
      if (infoRes.code == 200) {
        this.jobInfo = infoRes.data;
      }
    },
  },
};
</script>

<style lang="scss">
.jobHead {
  margin-bottom: 60px;
  .jobTitleLine {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    align-items: center;
    .likeBtn {
      margin-left: 0;
    }
    .jobTitle {
      font-weight: 700;
      font-size: 24px;
      margin-right: 18px;
    }
    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
  .company {
    margin-bottom: 13px;
  }
}
.jobDetBlock {
  margin-bottom: 60px;
  .detTitle {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .detText {
    line-height: 1.6;
    white-space: pre-line;
  }
}
</style>

<style lang="scss">
.isMobile {
  .jobHead {
    margin-bottom: 36px;
    .jobTitleLine {
      margin-bottom: 8px;
      .jobTitle {
        font-size: 18px;
        margin-right: 8px;
      }
    }
    .company {
      margin-bottom: 6px;
    }
  }
  .jobDetBlock {
    margin-bottom: 36px;
    .detTitle {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}
</style>