<template>
  <div class="messageItem">
    <div class="messageTitleLine">
      <div class="redPoint" v-if="!item.isViewed"></div>
      <div class="messageTitle">{{ item.title }}</div>
      <div class="messageTime" v-if="!isMobile">{{ item.createTime }}</div>
      <div class="foldBtn" @click="clickFold">
        <img :class="{ upImg: fold }" src="@/assets/down.png" alt="" />
      </div>
    </div>
    <div class="messageTime" v-if="isMobile">{{ item.createTime }}</div>
    <div class="messageDet" v-show="fold">{{ item.content }}</div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      fold: false,
      isViewed: false,
      loading: false,
    };
  },
  methods: {
    async clickFold() {
      if (this.fold) {
        return (this.fold = false);
      } else this.fold = true;
      if (!this.item.isViewed) {
        const checkRes = await request.get("/webapi/userMessage/detail", {
          params: {
            id: this.item.id,
          },
        });
        if (checkRes.code == 200) {
          this.item.isViewed = true;
        }
      }
    },
  },
  props: ["item"],
};
</script>

<style lang="scss">
.isMobile {
  .messageItem {
    padding: 8px 2px 8px 8px;
    .redPoint {
      left: 0;
    }
    .messageTitleLine {
      .messageTitle {
        width: 54%;
      }
      .foldBtn {
        margin-left: auto;
      }
    }
    .messageTime {
      margin: 0 0 6px 0;
    }
  }
}
.messageItem {
  position: relative;
  padding: 12px 4px 12px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 6px;
  .messageTime {
    margin: 0 88px 0 auto;
    color: #b0b0b0;
    font-size: 13px;
  }
  .redPoint {
    position: absolute;
    background: #df0000;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: 7px;
  }
  .messageTitleLine {
    display: flex;
    margin-bottom: 4px;
    align-items: center;

    .foldBtn {
      width: 16px;
      height: 16px;
      cursor: pointer;
      img {
        width: 16px;
      }
      .upImg {
        transform: rotate(180deg);
      }
    }
  }
  .messageDet {
    color: #999999;
    white-space: pre-line;
  }
}
</style>
