<template>
  <div class="footerBlock">
    <div class="mobileFooter">
      <!-- <div class="term">{{ $t("使用条款") }}</div> -->
      <div class="term footerPrivacy" @click="clickContactCs">
        {{ $t("联系我们") }}
      </div>
      <div class="term" @click="openUrl('/privacy')">{{ $t("隐私条款") }}</div>
    </div>
    <div class="infoLine">
      <div class="copyright infoText">
        Copyright © TEGLIDAN LIMITED. All rights reserved.
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >粤ICP备20067642号-1</a
        >
      </div>
      <div class="infoRight">
        <div class="term footerPrivacy">
          {{ $t("联系我们") }}
          <div class="csQrCodeImg" v-if="!isMobile">
            <img src="@/assets/qrcode.png" alt="" />
          </div>
        </div>
        <div class="term" @click="openUrl('/privacy')">{{ $t("隐私条款") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openUrl(url) {
      window.open(url);
    },
    clickContactCs() {
      if (this.isMobile) {
        window.open("https://work.weixin.qq.com/kfid/kfc74ccd69410ce7eee");
      }
    },
  },
};
</script>

<style lang="scss">
.isMobile {
  .footerBlock {
    min-width: unset;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px 16px;
    box-sizing: border-box;
    height: 98px;
    .mobileFooter {
      display: flex;
      margin-bottom: 8px;
    }
    .infoLine {
      flex-direction: column;
      width: 300px;
      margin: 0;
      .infoRight {
        display: none;
      }
    }
  }
}
.footerBlock {
  position: relative;
  height: 40px;
  background: #ffc03e;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1250px;
  .infoLine {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 1440px;
    margin: 0 auto;
    font-size: 12px;
    a {
      color: #000;
      text-decoration: none;
    }
    .infoRight {
      margin-left: auto;
      display: flex;
    }
  }
  .term {
    margin-right: 24px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    &.footerPrivacy {
      position: relative;
      .csQrCodeImg {
        display: none;
        // display: block;
        position: absolute;
        background: #fff;
        z-index: 1;
        left: -100%;
        bottom: 30px;
        img {
          width: 200px;
        }
      }
      &:hover {
        .csQrCodeImg {
          display: block;
        }
      }
    }
  }
  .mobileFooter {
    display: none;
  }
}
</style>
