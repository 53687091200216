<template>
  <div class="navigator" :style="{ transform: `translateX(-${scrollLeft}px)` }">
    <div class="navInfo">
      <div class="navLeft">
        <img @click="toIndex()" src="@/assets/logo.png" alt="" />
        <div
          class="navItem"
          :class="{ bold: path === 'Home' }"
          @click="toIndex()"
        >
          {{ $t("首页") }}
        </div>
        <div
          class="navItem"
          :class="{ bold: path === 'Houses' }"
          @click="toHouses()"
        >
          {{ $t("找房") }}
        </div>
        <div
          class="navItem"
          :class="{ bold: path === 'Jobs' }"
          @click="toJobs()"
        >
          {{ $t("找工作") }}
        </div>
        <div
          class="navItem"
          :class="{ bold: path === 'LifeHelper' }"
          @click="toHelper()"
        >
          {{ $t("生活助手") }}
        </div>
      </div>
      <div
        class="searchBar"
        v-if="path == 'Houses' || path == 'Jobs' || path == 'LifeHelper'"
      >
        <div class="searchType">
          <el-dropdown
            @command="handleCommand"
            trigger="click"
            :hide-timeout="0"
          >
            <span class="el-dropdown-link">
              {{ searchTypeMap[searchType]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Houses">房源</el-dropdown-item>
              <el-dropdown-item command="Jobs">工作</el-dropdown-item>
              <el-dropdown-item command="LifeHelper">生活助手</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <input
          type="text"
          placeholder="搜索..."
          v-model="keywords"
          @keyup.enter="clickSearch(searchType, keywords)"
          ref="searchBar"
        />
        <img
          @click="resetInput"
          class="searchIcon closeIcon"
          src="@/assets/close.png"
          alt=""
        />
        <img
          @click="clickSearch(searchType, keywords)"
          class="searchIcon"
          src="@/assets/search.png"
          alt=""
        />
      </div>
      <div class="navRight">
        <div class="navItem navRightPrivacy">
          {{ $t('联系我们') }}
          <div class="csQrCodeImg">
            <img src="@/assets/qrcode.png" alt="" />
          </div>
        </div>
        <div class="navItem">
          <span
            @click="switchLan('sim')"
            :class="{ selected: $i18n.locale === 'sim' }"
            >简体</span
          >
          <div class="yLine"></div>
          <span
            @click="switchLan('tra')"
            :class="{ selected: $i18n.locale === 'tra' }"
            >繁体</span
          >
        </div>
        <div
          class="navItem loginBtn"
          :class="{ bold: path === 'Login' }"
          @click="toLogin()"
          v-if="!isLogin"
        >
          {{ $t("登录/注册") }}
        </div>
        <div class="navItem avatar" v-else @click="toPersonal">
          <img v-if="avatar" class="imgCut" :src="avatar" alt="" />
          <img v-else class="imgCut" src="@/assets/company.png" alt="" />
        </div>
        <div class="mobileMoreBtn" @click="clickMore">
          <img v-if="!showMore" src="@/assets/btn_more.png" alt="" />
          <img v-else src="@/assets/btn_close.png" alt="" />
        </div>
      </div>
      <div class="mobileMore" v-if="showMore">
        <div
          class="moreItem"
          :class="{ bold: path === 'Home' }"
          @click="toIndex()"
        >
          {{ $t("首页") }}
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'Houses' }"
          @click="toHouses()"
        >
          {{ $t("找房") }}
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'Jobs' }"
          @click="toJobs()"
        >
          {{ $t("找工作") }}
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'LifeHelper' }"
          @click="toHelper()"
        >
          {{ $t("生活助手") }}
        </div>
        <div class="moreItem" style="display: flex; align-items: center">
          <span
            @click="switchLan('sim')"
            :class="{ bold: $i18n.locale === 'sim' }"
            >简体</span
          >
          <div class="yLine"></div>
          <span
            @click="switchLan('tra')"
            :class="{ bold: $i18n.locale === 'tra' }"
            >繁体</span
          >
        </div>
        <div class="moreItem" @click="toLogin()" v-if="!isLogin">
          {{ $t("登录/注册") }}
        </div>
        <div
          class="moreItem"
          :class="{ bold: path === 'Personal' }"
          @click="toPersonal()"
          v-if="isLogin"
        >
          {{ $t("个人主页") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { throttle } from "@/utils/index";

export default {
  data() {
    return {
      scrollLeft: 0,
      path: "",
      showMore: false,
      searchType: "Houses",
      searchTypeMap: {
        Houses: "房源",
        Jobs: "工作",
        LifeHelper: "生活助手",
      },
      keywords: "",
      query: null,
    };
  },
  async created() {
    if (this.$route.query.lan) {
      this.$i18n.locale = this.$route.query.lan;
    } else {
      const query = { ...this.$route.query, lan: "sim" };
      this.$router.replace({ query });
    }
    this.path = this.$route.name;
    if (
      this.path == "Houses" ||
      this.path == "Jobs" ||
      this.path == "LifeHelper"
    ) {
      this.searchType = this.path;
    }
    if (this.$route.query.keyword) {
      this.keywords = this.$route.query.keyword;
    }
    if (this.isLogin == true && this.id == 0) {
      // 主动拉取用户信息
      this.$store.dispatch("user/GetInfo");
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.scrollContainer = getScrollContainer(this.$el, true);
      this.scrollContainer = window
      console.log('scrollContainer: ', this.scrollContainer)
      this.scrollContainer.addEventListener(
        "scroll",
        this.handleContainerScroll.bind(this)
      );
    });
  },
  beforeDestroy() {
    this.scrollContainer.removeEventListener(
      "scroll",
      this.handleContainerScroll
    );
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val, oldVal) {
        // 从false变成true, 拉一下用户信息
        if (!oldVal && val) {
          if (!this.avatar && this.id == 0) {
            this.$store.dispatch("user/GetInfo");
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters(["isLogin", "avatar", "id"]),
  },
  methods: {
    resetInput() {
      this.keywords = "";
      this.$nextTick(() => {
        this.$refs.searchBar.focus();
      });
    },
    clickSearch(searchType, keywords) {
      if (searchType == "Houses") {
        if (keywords) {
          window.location.href = `/houses?lan=${this.$i18n.locale}&keyword=${keywords}`;
        } else window.location.href = `/houses?lan=${this.$i18n.locale}`;
      }
      if (searchType == "Jobs") {
        if (keywords) {
          window.location.href = `/jobs?lan=${this.$i18n.locale}&keyword=${keywords}`;
        } else window.location.href = `/jobs?lan=${this.$i18n.locale}`;
      }
      if (searchType == "LifeHelper") {
        if (keywords) {
          window.location.href = `/lifehelper?lan=${this.$i18n.locale}&keyword=${keywords}`;
        } else window.location.href = `/lifehelper?lan=${this.$i18n.locale}`;
      }
    },
    handleCommand(command) {
      this.searchType = command;
    },
    clickMore() {
      this.showMore = !this.showMore;
    },
    toHouses() {
      window.location.href = `/houses/?lan=${this.$i18n.locale}`;
    },
    toJobs() {
      window.location.href = `/jobs/?lan=${this.$i18n.locale}`;
    },
    toHelper() {
      window.location.href = `/lifehelper/?lan=${this.$i18n.locale}`;
    },
    toIndex() {
      window.location.href = `/?lan=${this.$i18n.locale}`;
    },
    toLogin() {
      window.location.href = `/login/?lan=${this.$i18n.locale}`;
    },
    toPersonal() {
      if (this.isLogin) {
        window.location.href = `/personal/?lan=${this.$i18n.locale}`;
        return;
      }
      else this.$store.dispatch("user/LogOut");
      window.location.href = `/?lan=${this.$i18n.locale}`;
    },
    openUrl(url) {
      window.open(url);
    },
    switchLan(lan) {
      this.$i18n.locale = lan;
      const query = { ...this.$route.query, lan: lan };
      this.$router.replace({ query });
      console.log(this.$i18n.locale, "this.$i18n.locale");
      if (this.showMore) {
        this.showMore = false;
      }
      location.reload();
    },
    handleContainerScroll: throttle(function () {
      this.scrollLeft = document.documentElement.scrollLeft
    }, 0),
  },
};
</script>


<style lang="scss">
.isMobile {
  .navigator {
    height: 44px;
    min-width: 100%;
    .navInfo {
      width: 100%;
      padding: 0 14px 0 4px;
      box-sizing: border-box;
      overflow: unset;
      .navLeft {
        img {
          width: 30px;
          margin-left: 12px;
        }
      }
      .navItem {
        display: none;
      }
      .navRight {
        .mobileMoreBtn {
          display: block;
        }
      }

      .mobileMore {
        display: block;
        background: #fff;
        top: 40px;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding: 20px 22px;
        box-sizing: border-box;
        .moreItem {
          padding: 14px;
          font-size: 14px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          color: #666666;
        }
        .bold {
          color: #000;
          font-weight: 700;
        }
      }
      .searchBar {
        width: 270px;
        margin-left: 12px;
        padding: 6px;
        .searchIcon {
          top: 2px;
          right: 4px;
        }
        .searchIcon.closeIcon {
          width: 18px;
          right: 32px;
          top: 3px;
        }
        .searchType {
          width: 78px;
          margin-right: 4px;
          .el-dropdown {
            font-size: 13px;
          }
        }
        input {
          font-size: 13px;
          padding: 0 20px 0 4px;
        }
      }
    }
  }
}
.yLine {
  height: 8px;
  width: 1px;
  background: #cccccc;
  margin: 0 9px;
}
.navigator {
  height: 60px;
  width: 100%;
  min-width: 1250px;
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 999;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  .navInfo {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    max-width: 1250px;
    overflow: auto;
    position: relative;
    .navLeft {
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 44px;
        cursor: pointer;
      }
    }
    .searchBar {
      width: 416px;
      transform: unset;
      padding: 8px 0;
      background: #f7f7f7;
      border: 1px solid #e5e5e5;
      box-shadow: none;
      margin-left: 48px;
      left: auto;
      bottom: auto;
      position: relative;
      .searchIcon {
        top: 3px;
        right: 6px;
      }
      .searchIcon.closeIcon {
        right: 44px;
        top: 6px;
        width: 20px;
      }
      .el-dropdown-link {
        width: 100px;
        cursor: pointer;
      }

      .searchType {
        padding: 0px;
        margin-right: 20px;
        flex-shrink: 0;
        width: 100px;
        .el-button,
        .el-dropdown {
          font-size: 15px;
        }
      }
      input {
        padding: 2px 30px 0 6px;
        width: 100%;
        font-size: 15px;
        box-sizing: border-box;
        outline: none;
      }
    }
    .navItem {
      margin-left: 48px;
      font-size: 15px;
      cursor: pointer;
      line-height: 150%;
      padding: 6px 0;
      box-sizing: border-box;
      border-bottom: 3px transparent solid;
      display: flex;
      align-items: center;
      color: #666666;
      span {
        font-size: 13px;
        color: #cccccc;
      }
      span.selected {
        color: #000;
      }
    }
    .navItem.bold {
      color: #000;
      font-weight: 600;
      border-bottom: 3px #ffc03e solid;
    }
    .navItem.avatar {
      width: 40px;
      height: 40px;
      padding: 0;
      box-sizing: border-box;
      border: 2px solid #ffac00;
    }
    .navItem.loginBtn {
      border: 1px solid #2eaac1;
      border-radius: 50px;
      color: #2eaac1;
      padding: 4px 10px;
      box-sizing: border-box;
      font-size: 13px;
      height: 28px;
    }
    .navRight {
      display: flex;
      margin: 0 0 0 auto;
      align-items: center;
      .navRightPrivacy {
        position: relative;
        .csQrCodeImg {
          display: none;
          // display: block;
          position: fixed;
          background: #fff;
          z-index: 1;
          top: 50px;
          img {
            width: 200px;
          }
        }
        &:hover {
          .csQrCodeImg {
            display: block;
          }
        }
      }
      .mobileMoreBtn {
        display: none;
        img {
          width: 28px;
        }
      }
    }
    .mobileMore {
      display: none;
      position: absolute;
    }
  }
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: transparent;
  color: #2eaac1;
}
.el-dropdown-menu {
  transform: translateX(10px);
}
</style>